import { Component, OnInit } from '@angular/core';
import { UserService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';

@Component({
  selector: 'cs-customer-area',
  templateUrl: './customer-area.component.html',
  styleUrls: ['./customer-area.component.css'],
})
export class CustomerAreaComponent implements OnInit {
  constructor(private userService: UserService) {}

  async ngOnInit() {
    if (await this.userService.isCurrentUserAdmin()) {
      document.location.href = '/#/admin/company';
    } else {
      document.location.href = '/#/customer-portal';
    }
  }
}
