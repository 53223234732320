import { Injectable } from '@angular/core';
import {
  CloudComponentService,
  CloudUser,
  CompanyService,
  CompanyUser,
  UserService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';

@Injectable({
  providedIn: 'root',
})
export class UserGuidingService {
  public scriptLoaded: Promise<any>;

  constructor(
    public cloudComponentService: CloudComponentService,
    public companyService: CompanyService,
    public userService: UserService
  ) {}
  public async connectUserGuiding() {
    await new Promise((resolve, reject) => {
      this.loadUserGuidingService(resolve, reject);
    });
  }

  public async loadUserGuidingService(
    resolve: (value: unknown) => void,

    reject: (reason?: any) => void
  ): Promise<void> {
    ((g, u, i, d, e, s) => {
      g[e] = g[e] || [];

      const f = u.getElementsByTagName(i)[0];

      const k = u.createElement(i) as HTMLScriptElement;
      k.async = true;
      k.src =
        'https://static.userguiding.com/media/user-guiding-' +
        s +
        '-embedded.js';
      k.onload = () => {
        resolve('UserGuiding Loaded!');
      };
      k.onerror = () => {
        reject('Error loading UserGuiding script');
        console.error('Error loading UserGuiding script!');
      };
      f.parentNode.insertBefore(k, f);

      if (g[d]) return;
      interface UserGuiding {
        q: any[];
        c?: (n: string) => () => void;
      }

      const ug: UserGuiding = (g[d] = { q: [] });

      ug.c =
        (n: string) =>
        (...args: any[]) => {
          ug.q.push([n, ...args]);
        };

      const m = [
        'previewGuide',

        'finishPreview',

        'track',

        'identify',

        'hideChecklist',

        'launchChecklist',
      ];

      for (const j of m) {
        ug[m[j]] = ug.c(m[j]);
      }
    })(
      window,

      document,

      'script',

      'userGuiding',

      'userGuidingLayer',

      '176761669ID'
    );
  }

  public async identifyUser(
    user: CloudUser,
    companyuser: CompanyUser,
    Userlanguage: string
  ): Promise<void> {
    const currentCompany = await this.companyService.getCurrentCompany();

    const dynamicScript = `
window.onUserGuidingLoad = function() { 
  window.userGuiding.identify(
    '${user.companyUserGuid}', {
       UserID: '${user?.companyUserGuid}',
      CompanyID: '${currentCompany?.companyGuid}',
      UserEmail: '${companyuser?.emailAddress}',
      CustomerID: '${currentCompany?.salesForceAccountId}',
      Userlanguage: '${Userlanguage}',
      AccountSystemOrigin: '${currentCompany?.accountSystemOrigin}',
      CompanyUserSource: '${currentCompany?.companyUserSource}',
       FlexReportedLocation: '${(currentCompany as any)?.location}',
      FlexDbCreationTime: '${
        (currentCompany as any)?.cloudDatabaseCreatedDate
      }',

    }
  );
};
`;

    const l = document.getElementsByTagName('script')[1];
    const p = document.createElement('script') as HTMLScriptElement;
    p.textContent = dynamicScript;
    l.parentNode.insertBefore(p, l);
  }
}
