import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cs-terms-conditions-redirector',
  templateUrl: './terms-conditions-redirector.component.html',
  styleUrls: ['./terms-conditions-redirector.component.css'],
})
export class TermsConditionsRedirectorComponent implements OnInit {
  private lang: string;
  private country: string;

  private termsConditionsURL = 'terms-and-conditions/';
  private compusoftURL = 'www.compusoftgroup.com';
  /* eslint-disable @typescript-eslint/naming-convention*/
  private redirectionMap = {
    it_ch: 'it-ch',
    fr_ch: 'fr-ch',
    de_ch: 'de-ch',
    sv: 'sv',
    fi: 'fi',
    en_za: 'en-za',
    en_us: 'en-na',
    en_au: 'en-au',
    pt_pt: 'pt-pt',
    nb: 'no',
    no: 'no',
    nl: 'nl',
    it: 'it',
    fr: 'fr',
    es: 'es',
    de: 'de',
    da: 'da',
    nl_be: 'nl-be',
    fr_be: 'fr-be',
  };
  /* eslint-enable @typescript-eslint/naming-convention*/
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.lang = this.route.snapshot.queryParamMap.get('lang');
    this.country = this.route.snapshot.queryParamMap.get('country');

    if (this.lang) {
      this.lang = this.lang.toLowerCase();
    }

    if (this.country) {
      this.country = this.country.toLowerCase();
    }

    let urlKey: string = null;

    if (
      Object.keys(this.redirectionMap).includes(this.lang + '_' + this.country)
    ) {
      urlKey = this.redirectionMap[this.lang + '_' + this.country];
    } else if (Object.keys(this.redirectionMap).includes(this.lang)) {
      urlKey = this.redirectionMap[this.lang];
    }

    if (urlKey == null) {
      location.href =
        'https://' + this.compusoftURL + '/' + this.termsConditionsURL;
    } else {
      location.href =
        'https://' +
        this.compusoftURL +
        '/' +
        urlKey +
        '/' +
        this.termsConditionsURL;
    }
  }
}
