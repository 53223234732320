import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataCenter } from '../models/datacenter.model';

@Component({
  selector: 'cs-speedtest',
  templateUrl: './speedtest.component.html',
  styleUrls: ['./speedtest.component.css'],
})
export class SpeedtestComponent {
  public apiBase = 'https://live-flex';
  public domain = '.compusoftgroup.com';
  public apiPath = '/api/exchange';
  public endpoint = '/ping';
  public size = '26214400';

  public dataCenters: DataCenter[] = [
    {
      name: 'Europe',
      url: `${this.apiBase}-eu${this.domain}${this.apiPath}${this.endpoint}?size=${this.size}`,
      time: null,
      status: 'None',
    },
    {
      name: 'United States',
      url: `${this.apiBase}-eastus${this.domain}${this.apiPath}${this.endpoint}?size=${this.size}`,
      time: null,
      status: 'None',
    },
    {
      name: 'South Africa',
      url: `${this.apiBase}-za${this.domain}${this.apiPath}${this.endpoint}?size=${this.size}`,
      time: null,
      status: 'None',
    },
    {
      name: 'Australia',
      url: `${this.apiBase}-au${this.domain}${this.apiPath}${this.endpoint}?size=${this.size}`,
      time: null,
      status: 'None',
    },
  ];

  public isLoading = false;

  constructor(private http: HttpClient) {}

  public getPingTime(url: string): Observable<number> {
    const startTime = Date.now();
    return this.http.get(url, { responseType: 'text' }).pipe(
      map(() => Date.now() - startTime),
      catchError(() => {
        return of(null);
      })
    );
  }

  public async testAllEndpoints(): Promise<void> {
    this.isLoading = true;

    this.dataCenters.forEach((dc) => {
      dc.status = 'Pending';
      dc.isFastest = false;
    });

    try {
      const pingPromises = this.dataCenters.map(async (dc) => {
        try {
          const time = await firstValueFrom(this.getPingTime(dc.url));
          if (time === null) {
            dc.status = 'Error';
            dc.time = null;
          } else {
            dc.time = time;
            dc.status = null;
          }
        } catch (error) {
          dc.status = 'Error';
          dc.time = null;
          console.error('Error during ping test for', dc.url, error);
        }
      });

      await Promise.all(pingPromises);

      const fastestDataCenter = this.dataCenters.reduce(
        (fastest, dc) =>
          dc.time !== null && (fastest === null || dc.time < fastest.time)
            ? dc
            : fastest,
        null as DataCenter
      );

      if (fastestDataCenter) {
        fastestDataCenter.isFastest = true;
      }
    } catch (error) {
      console.error('Error during ping tests:', error);
    } finally {
      this.isLoading = false;
    }
  }
}
