export class DurableProcessReportAllowed {
    private static durableProcessReportAllowedList: string[] = [
        "contactsImport"
    ];

    public static isDurableProcessReportAllowed(durableProcessName: string): boolean {
        return this.durableProcessReportAllowedList.includes(durableProcessName);
    }

    public static getDurableProcessReportAllowedList(): string[] {
        return this.durableProcessReportAllowedList;
    }

}