import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { EnvironmentService } from '../../environments/environment.service';
import { User } from '../models/user.model';
import { UserRegistrationData } from './../models/user-registration-data.class';

@Injectable({ providedIn: 'root' })
export class UserRegistrationService {
  constructor(
    public http: HttpClient,
    private companyService: CompanyService,
    public environmentService: EnvironmentService
  ) {
    this.companyService.identityServerApiBaseUrl =
      environmentService.identityServerApiBaseUrl;
  }

  public async registerNewUser(
    userData: UserRegistrationData,
    signUpToken: string
  ): Promise<any> {
    return this.http
      .post<any>(
        this.environmentService.customerPortalApiBaseUrl +
          '/RegisterNewCsoUser',
        userData,
        { params: { signUpToken } }
      )
      .toPromise();
  }

  public getUser(signUpToken: string): Promise<User> {
    return this.http
      .get<User>(
        this.environmentService.customerPortalApiBaseUrl +
          '/GetCompanyUserBySignupToken',
        { params: { signUpToken } }
      )
      .toPromise();
  }
}
