import { DurableProcessOutput } from 'src/app/durable-process/models/durableProcess.model';
import { DurableProcessesService } from './../../services/durable-processes.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { DurableProcess } from '../../models/durableProcess.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cs-durable-process-status-import-report',
  templateUrl: './durable-process-status-report-import.component.html',
  styleUrls: ['./durable-process-status-report-import.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate(
          '0.25s linear',
          style({ transform: 'translateX(0%)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate(
          '0.25s linear',
          style({ transform: 'translateX(-100%)', opacity: 0 })
        ),
      ]),
    ]),
    trigger('outInAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate(
          '0.25s linear',
          style({ transform: 'translateX(0%)', opacity: 1 })
        ),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate(
          '0.25s linear',
          style({ transform: 'translateX(100%)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class DurableProcessStatusReportImportComponent
  implements OnInit, OnDestroy
{
  public tabActive: 'contacts' | 'errors' = 'contacts';
  public durableProcess: DurableProcess;
  public errorsList: string[];
  public hasImportedContacts = true;
  public amountOfContactsToImport: string = null;

  private subscriptions: Subscription[] = [];

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private durableProcessesService: DurableProcessesService
  ) {}

  ngOnInit(): void {
    if (this.config?.data?.durableProcessStatus) {
      this.subscriptions.push(
        this.config.data.durableProcessStatus.subscribe((durableProcess) => {
          this.initDurableProcessReport(durableProcess);
        })
      );

      this.subscriptions.push(
        this.durableProcessesService.currentDurableProcessItems$.subscribe(
          async (durableProcessItems: any) => {
            if (!this.durableProcess?.output) {
              return;
            }

            let output: DurableProcessOutput = {...this.durableProcess.output};
            output.ImportedContacts = [];
            output.ImportedWithWarningContacts = [];
            output.NotImportedContacts = [];
            output = this.durableProcessesService.formatResultOutput(
              durableProcessItems,
              output
            );

            this.durableProcess = {...this.durableProcess, output}
            this.initDurableProcessReport(this.durableProcess);
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public changeTab(tabActive: 'contacts' | 'errors') {
    this.tabActive = tabActive;
  }

  public onClickClose() {
    this.ref.close();
  }

  // public onClickRetryFailedContacts() {
  //   this.durableProcessesService.launchImportDurableProcess(
  //     this.durableProcessStatus,
  //     { force: true }
  //   );
  //   this.ref.close();
  // }

  private generateErrorArrays() {
    const notImportedContactsErrors =
      this.durableProcess?.output?.NotImportedContacts?.map(
        (contact) => contact.Errors
      );
    this.errorsList = this.durableProcess?.output?.Errors?.concat(
      notImportedContactsErrors
    ).reduce((acc, val) => acc.concat(val), []);
  }

  private initDurableProcessReport(durableProcess: any) {
    this.durableProcess = {...durableProcess};
    this.generateErrorArrays();
    this.checkIfHasImportedReports();
    this.getAmountOfContactsToImport();
  }

  private getAmountOfContactsToImport() {
    if (this.durableProcess?.input?.amountOfContactsToImport) {
      this.amountOfContactsToImport = this.durableProcess.input.amountOfContactsToImport;
    }
  }

  private checkIfHasImportedReports() {
    if (
      this.durableProcess?.output?.ImportedContacts?.length +
        this.durableProcess?.output?.ImportedWithWarningContacts?.length ===
      0
    ) {
      this.hasImportedContacts = false;
      this.changeTab('errors');
    }
  }
}
