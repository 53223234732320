import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CSToastService } from '@compusoftgroup/cs-common-components';
import { TranslateUtilsService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { DocumentsMultipleUploadService } from '@compusoftgroup/ngx-compusoft-cloud-documents-library';

@Component({
  selector: 'cs-documents-multi-upload',
  templateUrl: './documents-multi-upload.component.html',
  styleUrls: ['./documents-multi-upload.component.css'],
})
export class DocumentsMultiUploadComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public documentsMultipleUploadService: DocumentsMultipleUploadService;
  public menuOpen = false;
  public menuHidden = true;
  public dragging = false;

  private DEFAULT_TIME_DIALOG_AUTOCLOSE = 4000;
  private uploadFinishSubscription: any;

  constructor(
    private csToastService: CSToastService,
    private translateUtilsService: TranslateUtilsService
  ) {
    this.documentsMultipleUploadService = (
      window as any
    ).documentMultipleUploadService;

    this.uploadFinishSubscription = (window as any).uploadFinish.subscribe(
      async (files) => {
        this.endUpload(files);
      }
    );
  }

  public ngOnDestroy(): void {
    this.uploadFinishSubscription?.unsubscribe();
  }

  public ngAfterViewInit(): void {
    document.body.addEventListener(
      'dragover',
      (e) => {
        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.dropEffect = 'none';
        e.preventDefault();
        e.stopPropagation();
      },
      false
    );

    document.body.addEventListener(
      'drop',
      (e) => {
        e.preventDefault();
        e.stopPropagation();
      },
      false
    );

    this.initDraggable();
  }

  public ngOnInit() {
    this.documentsMultipleUploadService.documentsMultipleUploadSubject.subscribe(
      () => {
        this.openMenu();
        this.showUploadDetails();
      }
    );
  }

  public get documentsMultipleUpload() {
    return this.documentsMultipleUploadService.documentsMultipleUpload;
  }

  public retryDocument(document) {
    const index =
      this.documentsMultipleUploadService.documentsMultipleUploadFailed.findIndex(
        (d) => d === document
      );

    if (index >= 0) {
      document.file = [document.file];
      this.documentsMultipleUploadService.documentsMultipleUploadSubject.next(
        document
      );
      this.documentsMultipleUploadService.documentsMultipleUploadFailed.splice(
        index,
        1
      );
    }
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  public clearData() {
    this.documentsMultipleUploadService.removeAllFiles();
  }

  public removeSuccessDocument(document) {
    this.documentsMultipleUploadService.removeFileSuccess(document);
  }

  public removeFailedDocument(document) {
    this.documentsMultipleUploadService.removeFileFailed(document);
  }

  private openMenu() {
    this.menuOpen = true;
  }

  private showUploadDetails() {
    this.menuHidden = false;
  }

  private checkIfIsOutside(event: MouseEvent): void {
    const documentMaxWidth = document.body.clientWidth;
    const documentMaxHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const multiuploadComponent = document.getElementById('multiupload');

    if (event.pageX > documentMaxWidth - 200) {
      multiuploadComponent.style.left = `${documentMaxWidth - 312}px`;
    }
    if (event.pageX < 0) {
      multiuploadComponent.style.left = '12px';
    }
    if (event.pageY > documentMaxHeight) {
      multiuploadComponent.style.top = `${documentMaxHeight - 40}px`;
    }
    if (event.pageY < 0) {
      multiuploadComponent.style.top = '0';
    }
  }

  private removeMultiuploadStyles(): void {
    const multiupload = document.getElementById('multiupload');
    multiupload.style.bottom = 'initial';
    multiupload.style.right = 'initial';
  }

  private initDraggable() {
    this.makeDraggable(
      document.querySelector('#multiupload'),
      document.querySelector('.details-uploading')
    );
    const detailsUploading: any = document.querySelector('.details-uploading');
    detailsUploading.style.cursor = 'move';
  }

  private makeDraggable(element, handle) {
    let offsetElement = { x: 0, y: 0 };
    let offset = { x: 0, y: 0 };

    handle.addEventListener('mousedown', (e) => {
      ({ offsetElement, offset } = this.onMouseDown(
        offsetElement,
        e,
        offset,
        handle,
        element
      ));
    });

    document.addEventListener('mousemove', (e) => {
      offset = this.onMouseMove(offset, handle, e, element, offsetElement);
    });

    document.addEventListener('mouseup', (e) => {
      this.onMouseUp(e);
    });
  }

  private onMouseUp(e: MouseEvent) {
    this.dragging = false;
    this.checkIfIsOutside(e);
  }

  private onMouseMove(
    offset: { x: number; y: number },
    handle: any,
    e: MouseEvent,
    element: any,
    offsetElement: { x: number; y: number }
  ) {
    if (this.dragging) {
      offset = this.moveElement(offset, handle, e, element, offsetElement);
    }
    return offset;
  }

  private onMouseDown(
    offsetElement: { x: number; y: number },
    e: any,
    offset: { x: number; y: number },
    handle: any,
    element: any
  ) {
    if (!e.target.classList.contains('action-icons')) {
      this.dragging = true;
      offsetElement = {
        x: e.offsetX,
        y: e.offsetY,
      };

      offset = this.moveElement(offset, handle, e, element, offsetElement);
      this.removeMultiuploadStyles();
    }
    return { offsetElement, offset };
  }

  private moveElement(
    offset: { x: number; y: number },
    handle: any,
    e: any,
    element: any,
    offsetElement: { x: number; y: number }
  ) {
    offset = {
      x: handle.offsetLeft - e.clientX,
      y: handle.offsetTop - e.clientY,
    };

    element.style.top = `${-offset.y - offsetElement.y}px`;
    element.style.left = `${-offset.x - offsetElement.x}px`;
    return offset;
  }

  private async endUpload(uploadedFiles: any = []) {
    if (this.hasFailedUploads()) {
      this.handleFailedUploads(uploadedFiles);
      return;
    }

    this.handleSuccessfulUploads();
  }

  private hasFailedUploads(): boolean {
    return (
      this.documentsMultipleUploadService.documentsMultipleUploadFailed.length >
      0
    );
  }

  private handleFailedUploads(uploadedFiles: any[]): void {
    setTimeout(() => {
      if (uploadedFiles.length > 0) {
        uploadedFiles.forEach((file) => {
          this.documentsMultipleUploadService.removeFileSuccess(file);
        });
      }
    }, this.DEFAULT_TIME_DIALOG_AUTOCLOSE);
  }

  private handleSuccessfulUploads(): void {
    setTimeout(() => {
      this.documentsMultipleUploadService.removeAllFiles();
    }, this.DEFAULT_TIME_DIALOG_AUTOCLOSE);
  }
}
