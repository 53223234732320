import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CloudComponent,
  CloudComponentService,
  CompanyService,
  ShopService,
  UserService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from 'src/environments/environment.service';
import { SearchResultElement } from '../models/search-result-element.class';
import { SearchResultItem } from '../models/search-result-item.class';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searching = false;
  private seeMoreResultText = '';
  private searchDataSubject = new BehaviorSubject<SearchResultItem[]>([]);
  private completedRequestsCount = 0;
  private searchHistoryLocalStorageName = `cs_search_history_${this.userService.getCurrentCompanyUserGuid()}_${this.companyService.getCurrentCompanyGuid()}_${this.shopService.getCurrentShopGuid()}`;

  constructor(
    public http: HttpClient,
    public translateService: TranslateService,
    public userService: UserService,
    public shopService: ShopService,
    public companyService: CompanyService,
    public compusoftCloudComponentService: CloudComponentService,
    public environmentService: EnvironmentService
  ) {
    setTimeout(async () => {
      await this.initTranslations();
    }, 200);
  }

  /**
   * Search in all backends allowed for this shop
   *
   * @param query
   */
  public async searchInAllBackendsAllowed(
    query: string
  ): Promise<SearchResultElement[]> {
    const allComponentsWithSearch = this.compusoftCloudComponentService
      .getComponentsForCurrentUser()
      .filter((cloudComponent) => cloudComponent.searchEndpointUrl);

    const componentsToSearch: CloudComponent[] = [];
    allComponentsWithSearch.forEach((searchComponent) => {
      if (
        searchComponent?.searchEndpointUrl &&
        searchComponent.searchEndpointUrl.length > 0 &&
        searchComponent.searchEndpointUrl[0] === '['
      ) {
        let endpoints = [];
        try {
          endpoints = JSON.parse(searchComponent.searchEndpointUrl);
        } catch (error) {
          console.error(error);
        }

        endpoints.forEach((endpoint) => {
          const cloudComponent: CloudComponent = {
            componentName: endpoint.type,
            label: endpoint.type,
            componentID: endpoint.componentID,
            searchEndpointUrl: endpoint.endpoint,
            requiresShop: true,
          };

          componentsToSearch.push(cloudComponent);
        });
      } else {
        componentsToSearch.push(searchComponent);
      }
    });

    this.completedRequestsCount = 0;
    this.searchDataSubject = new BehaviorSubject<SearchResultItem[]>([]);
    let auxSearchData: SearchResultElement[] = [];
    const ITEM_ORDER = [
      'Projects',
      'customers',
      'vendors',
      'contactPersons',
      'Documents',
    ];

    return new Promise((resolve, reject) => {
      try {
        this.searchDataSubject.subscribe((searchData) => {
          if (componentsToSearch.length === this.completedRequestsCount) {
            auxSearchData = [];
            searchData.forEach((resultItem) => {
              auxSearchData = auxSearchData.concat(
                this.transformArraySearchResultItems(resultItem, query)
              );
            });
            if (auxSearchData.length === 0) {
              auxSearchData.push(new SearchResultElement(null, null));
            } else {
              // this.addItemTotSearchHistory({ query, totalResults });
            }
            auxSearchData.sort(
              (a, b) => ITEM_ORDER.indexOf(a.type) - ITEM_ORDER.indexOf(b.type)
            );

            resolve(auxSearchData);
          }
        });
      } catch (error) {
        reject(error);
      }

      componentsToSearch.forEach(async (allowedComponent) => {
        try {
          const searchResult = await this.getSearchListByComponent(
            query,
            allowedComponent.componentID,
            allowedComponent.searchEndpointUrl
          );
          this.completedRequestsCount++;
          if (searchResult) {
            searchResult.type = allowedComponent.label;
            searchResult.componentID =
              allowedComponent.componentID.toLowerCase();
            this.addData(searchResult);
          }
        } catch (err) {
          console.error('Error searching in one of the backends:', err);
          this.completedRequestsCount++;
          this.addData(null);
        }
      });
    });
  }

  public generateURLFromSearchResult(
    searchResult: SearchResultElement
  ): string {
    if (!searchResult.componentID) {
      return;
    }
    let url = `#/${searchResult.componentID}?`;

    if (searchResult.query && searchResult.query.length) {
      url = url + 'search=' + searchResult.query;
    }

    if (searchResult.guid && searchResult.guid.length && searchResult.type) {
      url = url + '&guid=' + searchResult.guid + '&type=' + searchResult.type;
    } else if (
      searchResult.type &&
      searchResult.type !== searchResult.componentID
    ) {
      url = url + '&type=' + searchResult.type;
    }

    return url;
  }

  public getLoadingResult(): SearchResultElement[] {
    const loadingResult = new SearchResultElement('loading', null);
    loadingResult.type = null;
    return [loadingResult];
  }

  public areThereSearchableComponents() {
    return this.compusoftCloudComponentService
      .getComponentsForCurrentUser()
      .filter((cloudComponent) => cloudComponent.searchEndpointUrl).length
      ? true
      : false;
  }

  public getSearchHistory() {
    const searchResult = JSON.parse(
      localStorage.getItem(this.searchHistoryLocalStorageName)
    );

    if (!searchResult) {
      return [];
    }

    return searchResult.filter((item) => item.guid);
  }

  public addItemTotSearchHistory(search: {
    guid: string;
    name: string;
    type: string;
  }): void {
    if (!search || !search.guid || search.guid.trim().length === 0) {
      return;
    }
    const searchHistory = this.getSearchHistory();

    if (this.containsObject(search, searchHistory)) {
      const pos = searchHistory
        .map((e) => {
          return e.guid;
        })
        .indexOf(search.guid);
      searchHistory.splice(pos, 1);
    } else if (searchHistory.length === 3) {
      searchHistory.pop();
    }

    searchHistory.unshift(search);
    localStorage.setItem(
      this.searchHistoryLocalStorageName,
      JSON.stringify(searchHistory)
    );
  }

  private containsObject(obj, list) {
    return list.map((e) => e.guid).find((e) => e === obj.guid) ? true : false;
  }

  private addData(dataObj) {
    if (dataObj) {
      const currentValue = this.searchDataSubject.value;
      const updatedValue = [...currentValue, dataObj];
      this.searchDataSubject.next(updatedValue);
    } else {
      this.searchDataSubject.next(this.searchDataSubject.value);
    }
  }

  private async initTranslations() {
    this.seeMoreResultText = await this.translate('seeMoreResults');
  }

  private async translate(value: string) {
    return await this.translateService.get(value).toPromise();
  }

  private transformArraySearchResultItems(
    searchResultItems: SearchResultItem,
    query: string
  ): SearchResultElement[] {
    const transformedArray = [];
    if (!searchResultItems) {
      return;
    }
    if (searchResultItems.totalResults > 3) {
      searchResultItems.results[3] = new SearchResultElement(
        `${this.seeMoreResultText} (${searchResultItems.totalResults - 3}) `,
        ``
      );
      searchResultItems.results[3].query = query;
    }
    if (searchResultItems.totalResults > 0) {
      searchResultItems.results.unshift(new SearchResultElement(``, ``));
    }
    searchResultItems.results.forEach((result) => {
      result.type = searchResultItems.type;
      result.componentID = searchResultItems.componentID;
      transformedArray.push(result);
    });
    return transformedArray;
  }

  private async getSearchListByComponent(
    query: string,
    componentId: string,
    searchEndpointUrl: string
  ): Promise<SearchResultItem> {
    return await this.http
      .get<SearchResultItem>(
        this.environmentService.globalApiBaseUrl +
          this.environmentService.apis[componentId] +
          '/' +
          searchEndpointUrl,
        {
          params: {
            search: query,
            companyGuid: this.companyService.getCurrentCompanyGuid(),
            shopGuid: this.shopService.getCurrentShopGuid(),
          },
        }
      )
      .toPromise();
  }
}
