<div id="header" *ngIf="!loadingTheme && currentTheme === 'default'">
  <div id="header-logo">
    <span class="cs-S-CSlogo" style="color: white; font-size: 25px"></span>
  </div>
</div>

<div
  *ngIf="!loadingTheme"
  class="cs-content content cs-shapes"
  [class.full-content]="currentTheme !== 'default'"
  [class.cs-shapes-white]="currentTheme === 'winner-design'"
  [class.winner-background]="currentTheme === 'winner-design'"
  data-test-id="componentLanguageChooser"
>
  <div class="chooser-container" *ngIf="!loading">
    <div class="language-selector">
      <div class="logo">
        <div class="cs-logo-100"></div>
      </div>
      <h1>{{ 'chooseYourLanguage' | translate }}</h1>
      <div class="input-row">
        <div class="input-box" data-test-id="dropdownLanguages">
          <p-dropdown
            tzeDropdownFix
            [options]="languagesMenu"
            [(ngModel)]="selectedLanguage"
            (onChange)="onChangeLanguage($event)"
            [filter]="languagesMenu | showFilterSearch"
          >
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="loading">
    <cs-loader-with-text></cs-loader-with-text>
  </ng-template>
</div>

<div *ngIf="loadingTheme" class="text-center vertical-center">
  <cs-loader-with-text></cs-loader-with-text>
</div>
