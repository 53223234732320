import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
/* eslint-disable @typescript-eslint/naming-convention */

import { DurableProcessName } from './../models/durableProcessName.type';
import { DurableProcessStatus } from './../models/durableProcessStatus.model';
import {
  DurableProcess,
  DurableProcessOutput,
} from './../models/durableProcess.model';
import { DurableProcessRequest } from './../models/durableProcessRequest.model';
import {
  CompanyService,
  ShopService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { CSToastService } from '@compusoftgroup/cs-common-components';

@Injectable({
  providedIn: 'root',
})
export class DurableProcessesService {
  public isDurableFunctionsListShowing = false;
  public totalNumberOfPages = 0;
  public totalNumberOfRecords = 0;
  public currentDurableProcessItems$: BehaviorSubject<DurableProcessOutput[]> =
    new BehaviorSubject<DurableProcessOutput[]>(null);
  public loadingDurableProcessOutput$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private processes$: BehaviorSubject<DurableProcess[]> = new BehaviorSubject<
    DurableProcess[]
  >([]);

  private runningProcessesAmount = 0;
  private poolingInterval: NodeJS.Timer;
  private poolingIntervalTime = 60 * 1000;

  private currentPage$: BehaviorSubject<number> = new BehaviorSubject<number>(
    1
  );
  private pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(17);
  private currentProcessId: string;

  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private shopService: ShopService,
    private csToastService: CSToastService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService
  ) {
    this.currentPage$.subscribe(async () => {
      if (!this.currentProcessId) {
        return;
      }
      this.currentDurableProcessItems = await this.getDurableProcessResults(
        this.currentProcessId
      );
    });

    this.pageSize$.subscribe(async () => {
      if (!this.currentProcessId) {
        return;
      }
      this.currentDurableProcessItems = await this.getDurableProcessResults(
        this.currentProcessId
      );
    });
  }

  public async launchDurableProcess(
    durableProcess: DurableProcessRequest
  ): Promise<void> {
    try {
      await this.http
        .get<DurableProcess>(
          `${this.environmentService.importExportApiBaseUrl}/${durableProcess.endpoint}`,
          { params: durableProcess.params }
        )
        .toPromise();
    } catch (error) {
      this.launchToastEvents(
        `Launch Process: ${durableProcess.endpoint}`,
        'error',
        error.message
      );
    } finally {
      this.startPooling();
    }
  }

  public async initDurableProcessesMonitor() {
    await this.getVisibleDurableProcesses();

    if (this.processes.value.length > 0) {
      this.startPooling();
    }
  }

  public startPooling(force = false): void {
    this.stopPooling();
    this.processPooling(force);
  }

  public stopPooling(): void {
    clearTimeout(this.poolingInterval);
  }

  public async hideDurableProcesses(processIds: string[]): Promise<void> {
    try {
      await this.http
        .put<any>(
          `${this.environmentService.importExportApiBaseUrl}/HideDurableProcesses`,
          {
            processIds,
          },
          { params: this.getGlobalParams() }
        )
        .toPromise();

      processIds.forEach((processId) => {
        this.removeToastEvent(processId);
      });
    } catch (error) {
      this.launchToastEvents(
        this.translateService.instant('hideProcess'),
        'error',
        error.message
      );
    } finally {
      this.getVisibleDurableProcesses();
    }
  }

  public get processes(): BehaviorSubject<DurableProcess[]> {
    return this.processes$;
  }

  public set addProcess(durableProcess: DurableProcess) {
    this.setProcesses = [...this.processes.value, durableProcess];
  }

  public set setProcesses(durableProcesses: DurableProcess[]) {
    this.processes$.next(durableProcesses);
  }

  public get areAllProcessesCompleted(): boolean {
    return this.processes.value.every((process) => process.completed);
  }

  public async checkProcessProgress(
    statusQueryUri: string
  ): Promise<DurableProcessStatus> {
    const durableProcessStatus = await this.http
      .get<DurableProcessStatus>(statusQueryUri)
      .toPromise();

    if (
      durableProcessStatus.runtimeStatus === 'Completed' ||
      durableProcessStatus.runtimeStatus === 'Failed' ||
      durableProcessStatus.runtimeStatus === 'Terminated' ||
      durableProcessStatus.runtimeStatus === 'Suspended'
    ) {
      durableProcessStatus.completed = true;

      let updatedDurableProcess = await this.getDurableProcess(
        durableProcessStatus.instanceId
      );

      if (!updatedDurableProcess) {
        console.error('updatedDurableProcess is null');
        return durableProcessStatus;
      }

      if (!updatedDurableProcess.completed) {
        //special case, process completed but not registered on DurableProcess, we force it
        await this.cancelProcess(updatedDurableProcess, false);
      }

      updatedDurableProcess = await this.getDurableProcess(
        durableProcessStatus.instanceId
      );

      if (!updatedDurableProcess) {
        console.error('updatedDurableProcess is null');
        return durableProcessStatus;
      }

      await this.loadDurableProcessResultsAndErrors(updatedDurableProcess);

      const localProcessIndex = this.processes.value.findIndex(
        (p) => p.durableProcessId === durableProcessStatus.instanceId
      );

      if (localProcessIndex < 0) {
        return durableProcessStatus;
      }

      this.processes.value[localProcessIndex] = JSON.parse(
        JSON.stringify(updatedDurableProcess)
      );

      // const durableProcesses: DurableProcess[] = this.processes.value.map(
      //   (process) => {
      //     if (process.durableProcessId === durableProcessStatus.instanceId) {
      //       return updatedDurableProcess;
      //     }
      //     return process;
      //   }
      // );

      // this.setProcesses = durableProcesses;
    }
    return durableProcessStatus;
  }

  public clearAllCompletedProcesses(): void {
    const completedProcesses = this.processes.value.filter(
      (process) => process.completed
    );

    const completedProcessesIds = completedProcesses.map(
      (p) => p.durableProcessId
    );

    completedProcesses.forEach((p) => this.purgeHistory(p));
    this.hideDurableProcesses(completedProcessesIds);
  }

  public onDurableProcessCompleteActions(
    durableProcessStatus: DurableProcessStatus
  ): void {
    const durableFunctionNameEvent: {
      [key in DurableProcessName]: () => void;
    } = {
      ExtractZipFileAndImportContacts: () => {},
    };

    if (durableProcessStatus.name in durableFunctionNameEvent) {
      durableFunctionNameEvent[durableProcessStatus.name]();
    }
  }

  public launchToastEvents(
    title: string,
    status: 'completed' | 'error',
    message?: string,
    guid?: string
  ): void {
    const messageObject = { summary: title };

    const toastEvent = {
      error: () => {
        this.csToastService.add({
          ...messageObject,
          severity: 'error',
          detail:
            message ||
            this.translateService.instant('anErrorOccurredDuringTheProcess'),
          sticky: true,
          life: 9000,
        });
      },
      completed: () => {
        if (guid && this.isToastEventLaunched(guid)) {
          return;
        }

        if (guid) {
          this.storageToastEventLaunched(guid);
        }

        this.csToastService.add({
          ...messageObject,
          severity: 'success',
          detail:
            message ||
            this.translateService.instant(
              'theProcessHasBeenCompletedSuccessfully'
            ),
        });
      },
    };

    toastEvent[status]();
  }

  public async cancelDurableProcess(processId: string, output?: any) {
    try {
      const params = {
        ...this.getGlobalParams(),
        processId,
      };

      let body = null;

      if (output) {
        body = output;
      }

      await this.http
        .put<any>(
          `${this.environmentService.importExportApiBaseUrl}/CancelDurableProcess`,
          body,
          { params }
        )
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  public async cancelProcess(
    process: DurableProcess,
    startPooling: boolean = true
  ) {
    await this.terminateProcess(process);
    await this.cancelDurableProcess(process.durableProcessId);

    if (startPooling) {
      this.startPooling(true);
    }
  }

  public async terminateProcess(process: DurableProcess) {
    if (
      !process ||
      !process.controlUrls ||
      !process.controlUrls.terminatePostUri
    ) {
      return;
    }

    try {
      const params = {
        companyGuid: this.companyService.getCurrentCompanyGuid(),
        shopGuid: this.shopService.getCurrentShopGuid(),
      };

      await this.http
        .post(process.controlUrls.terminatePostUri, { params })
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  public async purgeHistory(process: DurableProcess) {
    if (
      !process ||
      !process.controlUrls ||
      !process.controlUrls.purgeHistoryDeleteUri
    ) {
      return;
    }

    try {
      const params = {
        companyGuid: this.companyService.getCurrentCompanyGuid(),
        shopGuid: this.shopService.getCurrentShopGuid(),
      };

      await this.http
        .delete(process.controlUrls.purgeHistoryDeleteUri, { params })
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  public showDurableFunctionsList(): void {
    this.isDurableFunctionsListShowing = true;
  }

  public hideDurableFunctionsList(): void {
    this.isDurableFunctionsListShowing = false;
  }

  public toggleDurableFunctionsList(): void {
    this.isDurableFunctionsListShowing = !this.isDurableFunctionsListShowing;
  }

  public async getDurableProcess(processId: string): Promise<DurableProcess> {
    try {
      const params = this.getGlobalParams();
      (params as any).processId = processId;

      return await this.http
        .get<DurableProcess>(
          this.environmentService.importExportApiBaseUrl + '/GetDurableProcess',
          { params }
        )
        .toPromise();
    } catch (error) {
      console.error('Error getting Durable Process', error);
    }
  }

  public async loadDurableProcessResultsAndErrors(
    process: DurableProcess
  ): Promise<void> {
    if (!process) {
      return;
    }

    const output: DurableProcessOutput = {
      Success: true,
      ImportedContacts: [],
      ImportedWithWarningContacts: [],
      NotImportedContacts: [],
      Errors: [],
    };

    const [error, results, resultsErrors] = await Promise.all([
      this.getDurableProcessErrors(process.durableProcessId),
      this.getDurableProcessResults(process.durableProcessId),
      this.getDurableProcessResultsErrors(process.durableProcessId),
    ]);

    output.Errors = error;

    if (resultsErrors?.length) {
      resultsErrors.forEach((r) => {
        if (r?.errors?.length) {
          (r.errors as string[]).forEach((e) => {
            if (e) {
              output.Errors.push(e);
            }
          });
        }
      });
    }

    process.output = this.formatResultOutput(results, output);
  }

  public async getDurableProcessErrors(processId: string): Promise<string[]> {
    try {
      const params = this.getGlobalParams();
      (params as any).processId = processId;

      return await this.http
        .get<string[]>(
          this.environmentService.importExportApiBaseUrl +
            '/GetDurableProcessErrors',
          { params }
        )
        .toPromise();
    } catch (error) {
      console.error('Error getting Durable Process Errors', error);
      return [];
    }
  }

  public async getDurableProcessResultsErrors(
    processId: string
  ): Promise<any[]> {
    try {
      const params = this.getGlobalParams();
      (params as any).processId = processId;

      return await this.http
        .get<any[]>(
          this.environmentService.importExportApiBaseUrl +
            '/GetDurableProcessResultsErrors',
          { params }
        )
        .toPromise();
    } catch (error) {
      console.error('Error getting Durable Process Errors', error);
      return [];
    }
  }

  public async getDurableProcessResults(
    processId: string = this.currentProcessId
  ): Promise<any> {
    try {
      this.loadingDurableProcessOutput = true;
      const params = this.getGlobalParams();
      this.currentProcessId = processId;
      return await this.http
        .post<any[]>(
          this.environmentService.importExportApiBaseUrl +
            '/GetDurableProcessResultsWithPagination',
          {
            pageSize: this.pageSize,
            pageNumber: this.currentPage,
            processId,
          },
          { params }
        )
        .pipe(
          tap((res: any) => {
            this.totalNumberOfPages = res.totalNumberOfPages;
            this.totalNumberOfRecords = res.totalNumberOfRecords;
          }),
          map((res: any) => {
            return res.resultEntry;
          })
        )
        .toPromise();
    } catch (error) {
      console.error('Error getting Durable Process Errors', error);
      return [];
    } finally {
      this.loadingDurableProcessOutput = false;
    }
  }

  public set currentPage(page: number) {
    this.currentPage$.next(page);
  }

  public get currentPage(): number {
    return this.currentPage$.value;
  }

  public set pageSize(pageSize: number) {
    this.pageSize$.next(pageSize);
  }

  public get pageSize(): number {
    return this.pageSize$.value;
  }

  public goToPage(page: number) {
    if (page >= 1 && page <= this.totalNumberOfPages) {
      this.currentPage = page;
    }
  }

  public set loadingDurableProcessOutput(loading: boolean) {
    this.loadingDurableProcessOutput$.next(loading);
  }

  public get loadingDurableProcessOutput(): boolean {
    return this.loadingDurableProcessOutput$.value;
  }

  public set currentDurableProcessItems(items: DurableProcessOutput[]) {
    this.currentDurableProcessItems$.next(items);
  }

  public get currentDurableProcessItems(): DurableProcessOutput[] {
    return this.currentDurableProcessItems$.value;
  }

  public formatResultOutput(results: any, output: DurableProcessOutput) {
    const outputData = { ...output };
    if (results) {
      results.forEach((result) => {
        if (result.Success) {
          if (result.Warnings.length > 0) {
            outputData.ImportedWithWarningContacts.push(result);
          } else {
            outputData.ImportedContacts.push(result);
          }
        } else {
          outputData.NotImportedContacts.push(result);
        }
      });
    }
    return outputData;
  }

  private async processPooling(force = false): Promise<void> {
    await this.getVisibleDurableProcesses(force);

    if (this.runningProcessesAmount > 0) {
      this.poolingInterval = setTimeout(() => {
        this.processPooling(); //recursive call
      }, this.poolingIntervalTime);
    }
  }

  private async getVisibleDurableProcesses(force = false): Promise<void> {
    try {
      const processesFromServer = await this.http
        .get<DurableProcess[]>(
          this.environmentService.importExportApiBaseUrl +
            '/GetDurableProcesses',
          { params: this.getGlobalParams() }
        )
        .toPromise();

      if (!processesFromServer) {
        return;
      }

      const localProcessesAmount = this.processes.value.length;

      let shouldUpdateProcessList = false;
      if (!force) {
        const serverPids = JSON.stringify(
          processesFromServer.map((p) => p.durableProcessId).sort()
        );
        const localPids = JSON.stringify(
          this.processes.value.map((p) => p.durableProcessId).sort()
        );

        shouldUpdateProcessList = serverPids !== localPids;
      } else {
        shouldUpdateProcessList = true;
      }

      if (shouldUpdateProcessList) {
        this.setProcesses = processesFromServer.sort((a, b) => {
          return a.startedAt > b.startedAt ? -1 : 1;
        });

        this.runningProcessesAmount = this.processes.value.filter(
          (p) => !p.completed
        ).length;
      }

      if (processesFromServer.length > localProcessesAmount) {
        this.showDurableFunctionsList();
      }
    } catch (error) {
      console.error(
        `Error getting Durable Processes: (${error.code}) - ${error.message}`
      );
    }
  }

  private getGlobalParams(): { companyGuid: string; shopGuid: string } {
    return {
      companyGuid: this.companyService.getCurrentCompanyGuid(),
      shopGuid: this.shopService.getCurrentShopGuid(),
    };
  }

  private storageToastEventLaunched(guid: string) {
    let toastEvents = [];
    try {
      toastEvents =
        JSON.parse(localStorage.getItem('durableProcessToastEvents')) || [];
    } catch (error) {
      console.error(error);
    }
    toastEvents.push(guid);
    localStorage.setItem(
      'durableProcessToastEvents',
      JSON.stringify(toastEvents)
    );
  }

  private removeToastEvent(guid: string) {
    let toastEvents = [];
    try {
      toastEvents = JSON.parse(
        localStorage.getItem('durableProcessToastEvents')
      );
    } catch (error) {
      console.error(error);
    }

    if (!toastEvents) {
      return;
    }

    localStorage.setItem(
      'durableProcessToastEvents',
      JSON.stringify(
        toastEvents.filter((toastEvent: string) => toastEvent !== guid)
      )
    );
  }

  private isToastEventLaunched(guid: string): boolean {
    const toastEvents = localStorage.getItem('durableProcessToastEvents') || [];
    return toastEvents.includes(guid);
  }
}
