<section class="container">
  <header>
    <button
      pButton
      class="p-button-accented big-button test-region-button"
      (click)="testAllEndpoints()"
      [disabled]="isLoading"
    >
      <span *ngIf="!isLoading"> Test Region Latency </span>
      <cs-loader *ngIf="isLoading"> </cs-loader>
    </button>
  </header>

  <p-table [value]="dataCenters" [tableStyle]="{ 'max-width': '30rem' }">
    <ng-template pTemplate="header">
      <tr>
        <th>Data Center</th>
        <th>Duration (ms)</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-dc>
      <tr [ngClass]="{ fastest: dc.isFastest }">
        <td [ngClass]="{ 'error-text': dc.status === 'Error' }">
          {{ dc.name }}
        </td>
        <td>
          <ng-container *ngIf="dc.status">
            {{ dc.status }}
          </ng-container>
          <ng-container *ngIf="!dc.status">
            {{ dc.time !== null ? dc.time + ' ms' : 'None' }}
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</section>
