<div class="general" data-test-id="componentInAppSupport">
  <cs-incident-message-banner
    *ngIf="productCode && !loading"
    [productCode]="productCode"
    [productName]="productName"
    [language]="translateService.currentLang"
  >
  </cs-incident-message-banner>

  <div *ngIf="!loading" class="header">
    <div class="title">
      <b
        >{{ 'welcome' | translate }} <a *ngIf="user">{{ user }}</a> 👋</b
      >
    </div>
    <p style="margin: 10px 0">
      {{ 'weAreHereToAnswerYourQuestions' | translate }}<br />{{
        'askUsAnything' | translate
      }}
    </p>
  </div>
  <div *ngIf="!loading" class="content-padding">
    <div style="padding-bottom: 10px">
      <b>{{ 'doYouNeedSomeHelp' | translate }}</b
      ><br />{{ 'visitSupportCenter' | translate }}
    </div>

    <div style="margin-bottom: 15px">
      <button
        pButton
        class="p-button-accented"
        (click)="navigateTo('https://winnerdesign.support.compusoftgroup.com/')"
      >
        <cy-icon
          [icon]="'cy_G_S_guide_communicate_guide_training'"
          [size]="'S'"
          [stroke]="'#ffffff'"
        ></cy-icon>
        {{ 'support-centre' | translate }}
      </button>
    </div>

    <cs-zendesk-promoted-articles
      *ngIf="productCode"
      [product]="productCode"
      [language]="translateService.currentLang"
    ></cs-zendesk-promoted-articles>
  </div>

  <div class="cs-loading" *ngIf="loading">
    <cs-progress-circular></cs-progress-circular>
  </div>
</div>
