import { TranslateService } from '@ngx-translate/core';
import { CSToastService } from '@compusoftgroup/cs-common-components';
import { CompusoftAngularUtilsService } from 'compusoft-angular-utils';
import { Component, OnInit } from '@angular/core';
import {
  CompanyService,
  UserService,
  ShopService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { ContainerLanguageService } from '../services/container-language.service';
import { ContainerCustomerPortalService } from '../services/container-customer-portal.service';
import { FeedbackService } from '../services/feedback.service';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'cs-about-flex',
  templateUrl: './about-flex.component.html',
  styleUrls: ['./about-flex.component.css'],
})
export class AboutFlexComponent implements OnInit {
  public data: { [key: string]: { id: string; name?: string } } = {};
  public loading = false;
  public error = false;
  public showTermConditions = false;
  public showPrivacyPolicy = false;
  public company;
  public lang = '';
  public urlTermsConditionsIframe = '';
  public urlPrivacyPolicyIframe = '';
  public feedbackMessage = '';
  public showSentFeedbackMessage = false;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private shopService: ShopService,
    private csToastService: CSToastService,
    private translateService: TranslateService,
    private containerLanguageService: ContainerLanguageService,
    private containerCustomerPortalService: ContainerCustomerPortalService,
    private feedbackService: FeedbackService,
    private clipboard: Clipboard
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadData();
    await this.loadTermsConditionsURL();
    await this.loadPrivacyPolicyURL();
  }

  public async copyToClipboard(textToCopy) {
    if (!textToCopy || textToCopy === '-') {
      return;
    }
    await this.clipboard.copy(textToCopy);
    this.csToastService.success(await this.translate('dataCopiedToClipboard'));
  }

  public showModalTermConditions() {
    //this.showTermConditions = true; // Do not remove this line, it is commented temporally
    window.open(this.urlTermsConditionsIframe, '_blank'); // Temporal solution, while the iframe is not working
  }

  public closeModalTermConditions() {
    this.showTermConditions = false;
  }

  public showModalPrivacyPolicy() {
    //this.showPrivacyPolicy = true; // Do not remove this line, it is commented temporally
    window.open(this.urlPrivacyPolicyIframe, '_blank'); // Temporal solution, while the iframe is not working
  }

  public closeModalPrivacyPolicy() {
    this.showPrivacyPolicy = false;
  }

  public async downloadCopyrightNotices(): Promise<boolean> {
    const product = 'FlexPlatform';
    return await this.containerCustomerPortalService.downloadCopyrightNoticesByProduct(
      product
    );
  }

  public async sendFeedback() {
    if (!this.feedbackMessage) {
      return;
    }

    const body = {
      message: this.feedbackMessage,
      country: this.company.hqCountry.toUpperCase(),
      salesForceId: this.data.salesforce.id,
    };
    const response = await this.feedbackService.sendFeedbackEmail(body);
    if (response) {
      this.csToastService.success(
        await this.translate('feedbackSentSuccessfully')
      );
      this.feedbackMessage = '';
      this.showSentFeedbackMessage = true;
    } else {
      this.csToastService.error(await this.translate('feedbackSentError'));
    }
  }

  private async loadTermsConditionsURL() {
    try {
      this.company = await this.companyService.getCompanyInfo(
        this.companyService.getCurrentCompanyGuid()
      );
      this.urlTermsConditionsIframe =
        'https://flex.compusoftgroup.com/tc?lang=' +
        this.lang +
        '&country=' +
        this.company.hqCountry;
    } catch (error) {
      console.error(error);
    }
  }

  private async loadPrivacyPolicyURL() {
    try {
      const privacyPolicyPerLanguage = {
        fr: 'fr/politique-de-confidentialite/',
        de: 'de/datenschutzerklaerung/',
        es: 'es/politica-de-privacidad/',
        it: 'it/politica-sulla-privacy/',
        nl: 'nl/privacybeleid/',
        da: 'da/privacy-policy/',
        sv: 'sv/integritetspolicy/',
        nb: 'no/privacy-policy-2/',
        fi: 'fi/yksityisyyskaytanto/',
        pt: 'pt-pt/politica-de-privacidade/',
        en: 'privacy-policy/',
      };
      let subdomain = privacyPolicyPerLanguage[this.lang];
      if (!subdomain) {
        subdomain = privacyPolicyPerLanguage.en;
      }
      this.urlPrivacyPolicyIframe =
        'https://www.compusoftgroup.com/' + subdomain;
    } catch (error) {
      console.error(error);
    }
  }

  private async loadData() {
    this.loading = true;
    const user = await this.userService.getCurrentUser();
    const company = await this.companyService.getCurrentCompany();
    if (!user || !company) {
      this.loadingError();
      return;
    }
    const companyData = await this.companyService.getCompany(
      company.companyGuid
    );
    if (!companyData) {
      this.loadingError();
      return;
    }

    this.data.user = {
      id: user.companyUserGuid || '-',
      name:
        CompusoftAngularUtilsService.capitalize(user.firstName) +
          ' ' +
          CompusoftAngularUtilsService.capitalize(user.lastName) || '-',
    };

    this.data.company = {
      id: company.companyGuid || '-',
      name: company.companyName || '-',
    };

    this.data.shop = {
      id: (await this.shopService.getCurrentShopGuid()) || '-',
      name: (await this.shopService.getCurrentShopName()) || '-',
    };

    this.data.salesforce = {
      id: (companyData as any).salesForceAccountId || '-', //It might be a good idea to store the SaleforceId in the localstorage to avoid calling the server every time this component is called up
    };

    this.lang = this.containerLanguageService.getlanguage();

    this.loading = false;
  }

  private loadingError() {
    this.loading = false;
    this.error = true;
  }

  private async translate(value: string) {
    return await this.translateService.get(value).toPromise();
  }
}
