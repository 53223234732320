import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cs-durable-process-status-report-error',
  templateUrl: './durable-process-status-report-error.component.html',
  styleUrls: ['./durable-process-status-report-error.component.css'],
})
export class DurableProcessStatusReportErrorComponent implements OnInit {
  @Input() public errors: string[];

  constructor(private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config?.data?.errors) {
      this.errors = this.config.data.errors;
    }
  }
}
