import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  public totalTimes = {};
  public keysWithResults = [];
  private data = {};
  private startTime = null;

  constructor() {}

  public addTimer(key: string): void {
    if (Object.keys(this.data).includes(key)) {
      return;
    }

    this.data[key] = {
      startTime: this.startTime,
      endTime: null,
    };
  }

  public startTimer(): void {
    this.startTime = new Date().getTime();

    Object.keys(this.data).forEach((key) => {
      this.data[key].startTime = this.startTime;
    });
  }

  public stopTimer(key: string): void {
    if (!Object.keys(this.data).includes(key) || !this.data[key].startTime) {
      return;
    }

    this.data[key].endTime = new Date().getTime();
    this.totalTimes[key] = this.data[key].endTime - this.data[key].startTime;
    this.keysWithResults.push(key);
  }
}
