import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CompusoftThemesService } from '@compusoftgroup/compusoft-themes';
import { TranslateService } from '@ngx-translate/core';
import {
  CompanyService,
  ExternalService,
  UserService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EnvironmentService } from '../../environments/environment.service';
import { ContainerLanguageService } from '../services/container-language.service';

@Component({
  selector: 'cs-language-chooser',
  templateUrl: './language-chooser.component.html',
  styleUrls: ['./language-chooser.component.css'],
})
export class LanguageChooserComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public companyGuid: string;
  public loading = true;
  public selectedLanguage = '';
  public languagesMenu: SelectItem[] = [];
  public currentTheme = 'default';
  public loadingTheme = true;

  private themeSubscription: Subscription = null;

  constructor(
    public route: ActivatedRoute,
    public translateService: TranslateService,
    public languageService: ContainerLanguageService,
    public externalService: ExternalService,
    private themesService: CompusoftThemesService,
    public userService: UserService,
    private cd: ChangeDetectorRef,
    public http: HttpClient,
    public environmentService: EnvironmentService,
    public companyService: CompanyService
  ) {}

  async ngOnInit() {
    this.initThemeChangeListener();
    this.route.queryParamMap.subscribe(async (params: ParamMap) => {
      this.companyGuid = params.get('companyGuid');
      const company = await this.companyService.getCompany(this.companyGuid);
      this.companyService.setCurrentCompany(company);

      const currentUser = await this.userService.getCurrentUser();

      if (currentUser) {
        let userLanguage = (window as any).languages?.find(
          (l) => l.key === this.companyService.getDefaultLanguage()
        );

        if (currentUser?.language) {
          userLanguage = (window as any).languages?.find(
            (l) => l.guid.toUpperCase() === currentUser.language.toUpperCase()
          );
        }

        const currentLanguage = (window as any).languages?.find(
          (l) => l.key === this.userService.getlanguage()
        );

        if (
          userLanguage &&
          userLanguage.guid.toUpperCase() !== currentLanguage.guid.toUpperCase()
        ) {
          await this.languageService.setLanguage(userLanguage.key);
        }

        this.loadLanguagesMenu(userLanguage.key);
      }
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.currentTheme = this.themesService.getActiveThemeName();
  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }

  public onChangeLanguage(event) {
    if (event.value) {
      this.languageService.setLanguage(event.value, true);
    }
  }

  private initThemeChangeListener() {
    const activeTheme = this.themesService.getActiveThemeName();

    if (activeTheme) {
      this.loadingTheme = false;
    }

    this.themeSubscription = this.themesService
      .getThemeChangeObservable()
      .subscribe(() => {
        this.currentTheme = this.themesService.getActiveThemeName();
        this.cd.detectChanges();
        this.loadingTheme = false;
      });

    setTimeout(() => {
      if (!this.loadingTheme) {
        return;
      }

      this.themesService.setTheme('winner-design');
      this.currentTheme = this.themesService.getActiveThemeName();
      this.cd.detectChanges();
      this.loadingTheme = false;
    }, 3000);
  }

  private loadLanguagesMenu(selectedLanguage) {
    this.languagesMenu = [];
    const languages = this.languageService.translateService.getLangs();

    for (const lang of languages) {
      if (this.environmentService.languagesBlacklist.includes(lang)) {
        continue;
      }

      this.languagesMenu.push({
        label: this.languageService.languagesNames[lang.toLowerCase()],
        value: lang,
      });
    }
    this.selectedLanguage = selectedLanguage;
  }
}
