import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CompanyService,
  CompusoftCloudTranslateService,
  ExternalService,
  LocalUserPreferencesService,
  UserService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from 'src/environments/environment.service';

@Injectable({
  providedIn: 'root',
})
export class ContainerLanguageService {
  public isUsingUrlLanguage = false;
  public languagesNames = {
    '0': 'English',
    cs: 'English DEV',
    da: 'Dansk',
    de: 'Deutsch',
    en: 'English (UK)',
    'en-us': 'English (US)',
    es: 'Español',
    fi: 'Suomi',
    fr: 'Français',
    it: 'Italiano',
    nl: 'Nederlands',
    nb: 'Norsk',
    pt: 'Português',
    sv: 'Svenska',
  };
  public currentLanguageObservable;

  private defaultLanguage = 'en';
  private currentLanguageSubject = new BehaviorSubject<string>(
    this.defaultLanguage
  );
  private languages = [];

  constructor(
    public translateService: TranslateService,
    public localUserPreferencesService: LocalUserPreferencesService,
    private location: Location,
    public userService: UserService,
    public http: HttpClient,
    public environmentService: EnvironmentService,
    public companyService: CompanyService,
    public externalService: ExternalService
  ) {
    this.currentLanguageObservable = this.currentLanguageSubject.asObservable();
  }

  public async initLanguages() {
    try {
      this.languages = (window as any).languages?.map((l) => l.key);
      this.addLanguages(this.languages);
      this.setDefaultLanguage(this.defaultLanguage);

      let urlLang = this.getUrlLanguage();
      urlLang = this.validateLanguageCode(urlLang);

      if (urlLang) {
        await this.setLanguage(urlLang);
        this.isUsingUrlLanguage = true;
        return;
      }

      const userPreferredLang =
        this.localUserPreferencesService.getPreferenceValue('lang');

      if (userPreferredLang && this.languages.includes(userPreferredLang)) {
        await this.setLanguage(userPreferredLang);
        return;
      }

      let browserLang = this.translateService.getBrowserLang();
      browserLang = this.validateLanguageCode(browserLang);

      if (browserLang) {
        await this.setLanguage(browserLang);
        return;
      }

      await this.setLanguage(this.defaultLanguage);
    } catch (error) {
      console.error('Error on initLanguages', error);
    }
  }

  public async setLanguage(
    language: string,
    saveAsUserLanguage = false,
    saveInLocalPreferences = true
  ) {
    this.userService.setLanguage(language);
    await this.translateService.use(language).toPromise();
    this.currentLanguageSubject.next(language);
    if (saveInLocalPreferences) {
      this.localUserPreferencesService.setPreferenceValue('lang', language);
    }
    if (saveAsUserLanguage && this.companyService.isCloudPlatformEnabled()) {
      await this.updateUserLanguageOnServer(language);
    }
    try {
      await this.externalService.changeLanguage(language);
    } catch (error) {
      console.error('Error in setLanguage', error);
    }

    if (localStorage.getItem('showTranslationKeys') === '1') {
      CompusoftCloudTranslateService.showTranslationKeys(this.translateService);
    }
  }

  public toggleShowTranslationKeys() {
    if (localStorage.getItem('showTranslationKeys') === '1') {
      localStorage.removeItem('showTranslationKeys');
    } else {
      localStorage.setItem('showTranslationKeys', '1');
    }
    window.location.reload();
  }

  public async useBrowserLanguage(
    saveAsUserLanguage = false,
    saveInLocalPreferences = false
  ) {
    const browserLang = this.translateService.getBrowserLang();
    const languages = (window as any).languages?.map((l) => l.key);

    if (languages?.includes(browserLang)) {
      await this.setLanguage(
        browserLang,
        saveAsUserLanguage,
        saveInLocalPreferences
      );
    }
  }

  public getlanguage() {
    return this.userService.getlanguage();
  }

  public get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  public removeUrlLanguageParamAndReload() {
    const newUrl = this.removeParamFromUrl('lang', window.location.href);
    window.location.href = newUrl;
    document.location.reload();
  }

  private setDefaultLanguage(language: string) {
    this.translateService.setDefaultLang(language);
  }

  private getUrlLanguage(): string {
    try {
      const currentPath = this.location.path(true);

      let param: string = null;

      if (currentPath.includes('&lang=') || currentPath.includes('?lang=')) {
        let index = currentPath.indexOf('&lang=');

        if (index < 0) {
          index = currentPath.indexOf('?lang=');
        }

        param = currentPath.substring(index + 6);

        if (param.length >= 5 && param.substring(2, 3) === '-') {
          param = param.substring(0, 5);
        } else {
          param = param.substring(0, 2);
        }
      }

      return param;
    } catch (error) {
      console.error('Error in getUrlLanguage: ', error);
    }
    return null;
  }

  private addLanguages(languages: string[]) {
    this.translateService.addLangs(languages);
  }

  private async updateUserLanguageOnServer(languageCode: string) {
    try {
      const languageGuid = (window as any).languages?.find(
        (l) => l.key === languageCode
      ).guid;

      if (!languageGuid) {
        return;
      }

      await this.http
        .put<any>(
          this.environmentService.shopsApiBaseUrl + '/UpdateUserLanguage',
          null,
          {
            params: {
              companyGuid: this.companyService.getCurrentCompanyGuid(),
              companyUserGuid: this.userService.getCurrentCompanyUserGuid(),
              languageCodeGuid: languageGuid,
            },
          }
        )
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  private removeParamFromUrl(key, sourceURL) {
    try {
      let rtn = sourceURL.split('?')[0];
      let param;
      let paramsArray = [];
      const queryString =
        sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';

      if (queryString !== '') {
        paramsArray = queryString.split('&');
        for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
          param = paramsArray[i].split('=')[0];
          if (param === key) {
            paramsArray.splice(i, 1);
          }
        }
        if (paramsArray.length) rtn = rtn + '?' + paramsArray.join('&');
      }
      return rtn;
    } catch (error) {
      console.error('Error cleaning URL', error);
      return sourceURL;
    }
  }

  private validateLanguageCode(languageCode: string): string {
    if (!languageCode) {
      return null;
    }

    if (this.languages.includes(languageCode)) {
      return languageCode;
    }

    if (languageCode.length > 2) {
      languageCode = languageCode.substring(0, 2);
    }

    languageCode = languageCode.toLowerCase();

    if (languageCode === 'no') {
      languageCode = 'nb';
    } else if (languageCode === 'se') {
      languageCode = 'sv';
    }

    if (this.languages.includes(languageCode)) {
      return languageCode;
    }

    return null;
  }
}
