import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { ZendeskUtilitiesService } from '@compusoftgroup/ngx-compusoft-cloud-zendesk-utilities';
import { CsCloudAuthenticationService } from '@compusoftgroup/ngx-compusoft-cloud-authentication';
import { EnvironmentService } from 'src/environments/environment.service';
import { ContainerLanguageService } from '../services/container-language.service';

declare let zE;

@Component({
  selector: 'cs-in-app-support',
  templateUrl: './in-app-support.component.html',
  styleUrls: ['./in-app-support.component.css'],
})
export class InAppSupportComponent implements OnInit {
  public user: string = null;
  public productCode: string = null;
  public productName: string = null;
  public productUrl: string = null;
  public defaultProductCode = 'WinnerDesign';
  public defaultProductName = 'Winner Design';
  public loading = true;
  private openChatAutomatically = false;

  private messagingProductKeys = {
    winnerDesign: '3c995a03-85f1-4bab-9f03-798ffc891ea5',
  };

  private productsData = [
    {
      code: 'WinnerDesign',
      name: 'Winner Design',
      url: 'https://winnerdesign.support.compusoftgroup.com/',
    },
    // {
    //   code: 'WinnerBizz',
    //   name: 'WinnerBizz',
    //   url: 'https://WinnerBizz.support.compusoftgroup.com/'
    // }
  ];

  constructor(
    public userService: UserService,
    public translateService: TranslateService,
    private authenticationService: CsCloudAuthenticationService,
    private environmentService: EnvironmentService,
    private zendeskUtilitiesService: ZendeskUtilitiesService,
    private route: ActivatedRoute,
    private containerLanguageService: ContainerLanguageService
  ) {
    this.userService.setEnvironment(this.environmentService.environmentURLS);
    this.zendeskUtilitiesService.setEnvironmentService(this.environmentService);
  }

  async ngOnInit() {
    if (this.authenticationService.isAuthenticated()) {
      this.user = (await this.userService.getCurrentUser()).firstName;
    }

    this.loadCurrentProductInfo();
    this.loadChatParameter();
    await this.containerLanguageService.useBrowserLanguage(false, false);
    this.loading = false;
    this.loadChatWidget();
  }

  public navigateTo(url: string) {
    window.open(url, '_blank');
  }

  private loadChatParameter() {
    const openChat = this.route.snapshot.queryParamMap.get('openChat');

    if (openChat) {
      this.openChatAutomatically = true;
    }
  }

  private loadCurrentProductInfo() {
    const productCodeParam =
      this.route.snapshot.queryParamMap.get('productCode');
    const productNameParam =
      this.route.snapshot.queryParamMap.get('productName');

    if (productCodeParam) {
      this.productCode = productCodeParam;
    } else {
      this.productCode = this.defaultProductCode;
    }

    if (productNameParam) {
      this.productName = productNameParam;
    } else {
      this.productName = this.defaultProductName;
    }

    this.productUrl = this.productsData.find(
      (p) => p.code === this.productCode
    )?.url;
  }

  private loadChatWidget(): void {
    const path =
      'https://static.zdassets.com/ekr/snippet.js?key=' +
      this.messagingProductKeys.winnerDesign;

    const script = document.createElement('script');

    script.onload = () => {
      try {
        if (this.openChatAutomatically) {
          zE('messenger', 'open');
        }
      } catch (error) {
        console.error(error);
      }
    };

    script.onerror = (error) => {
      console.error('Error loading chat script', error);
    };
    script.id = 'ze-snippet';
    script.async = true;
    script.type = 'text/javascript';
    script.src = path;
    document.body.appendChild(script);
  }
}
