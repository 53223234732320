import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ConnectionStatusService } from '../services/connection-status.service';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cs-offline-dialog',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, TranslateModule],
  templateUrl: './offline-dialog.component.html',
  styleUrls: ['./offline-dialog.component.css'],
})
export class OfflineDialogComponent {
  public connectionStatusService = inject(ConnectionStatusService);
}
