<header>
  <a
    class="language-button"
    data-test-id="buttonShowLanguageSelector"
    (click)="showLanguageSelector()"
  >
    <cy-icon
      [icon]="'cy_C_S_global_2_technology_global_global'"
      [size]="'S'"
    ></cy-icon>
    <span>{{ selectedLanguageName }}</span></a
  >
</header>

<div *ngIf="!loadingTheme && changePasswordTextLib" class="content">
  <div
    class="password-recovery-container"
    data-test-id="componentPasswordRecovery"
  >
    <img src="assets/logos/logo.svg" alt="Company Logo" class="company-logo" />
    <cs-password-recovery-steps
      [textLibrary]="changePasswordTextLib"
      [defaultRedirectUrl]="defaultRedirectUrl"
      [defaultEmail]="defaultEmail"
    ></cs-password-recovery-steps>
  </div>
  <cs-footer></cs-footer>
</div>

<div
  *ngIf="loadingTheme || !changePasswordTextLib"
  class="text-center vertical-center"
>
  <cs-loader-with-text></cs-loader-with-text>
</div>

<p-dialog
  header="{{ 'chooseYourLanguage' | translate }}"
  [(visible)]="languageSelectorVisible"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '350px', minWidth: '200px' }"
  [contentStyle]="{ overflow: 'visible' }"
  data-test-id="dialogLanguageSelector"
>
  <p-dropdown
    tzeDropdownFix
    [options]="languagesMenu"
    [(ngModel)]="selectedLanguage"
    (onChange)="onChangeLanguage($event)"
    [filter]="languagesMenu | showFilterSearch"
  >
  </p-dropdown>
</p-dialog>
