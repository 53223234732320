<div
  *ngIf="!(connectionStatusService.internetStatusSubject | async)"
  class="offline"
>
  <p-dialog
    header="{{ 'noInternet' | translate }}"
    [visible]="true"
    [modal]="true"
    [responsive]="true"
    [style]="{ width: '400px' }"
    [closeOnEscape]="false"
    [dissmissibleMask]="false"
    [closable]="false"
    [draggable]="false"
    [resizable]="false"
  >
    <div>
      <p>{{ 'offlineLostMessage' | translate }}</p>
      <p>{{ 'try' | translate }}:</p>
      <ul>
        <li>{{ 'offlineSuggestion1' | translate }}</li>
        <li>{{ 'offlineSuggestion2' | translate }}</li>
      </ul>
    </div>
    <p class="padding-top-16">*{{ 'noInternetInfo' | translate }}</p>
  </p-dialog>
</div>
