import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GetCompanyServicesResponse } from '../models/get-company-services-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceKeyService {
  private getCompanyServicesEndpoint = 'GetCompanyServices';
  private createServiceApiKeyEndpoint = 'CreateServiceAPIKey';

  constructor(private http: HttpClient) {}

  public getCompanyServices(
    companyGuid: string
  ): Promise<GetCompanyServicesResponse[]> {
    if (!companyGuid) {
      throw new Error('companyGuid is required');
    }

    const url = new URL(
      `${environment.shopsApiBaseUrl}/${this.getCompanyServicesEndpoint}`
    );
    url.searchParams.append('companyGuid', companyGuid);

    return firstValueFrom(
      this.http.get<GetCompanyServicesResponse[]>(url.toString())
    );
  }

  public async createServiceAPIKey(params: {
    companyGuid: string;
    serviceGuid: string;
    description: string;
  }) {
    if (!params?.companyGuid || !params?.serviceGuid || !params?.description) {
      throw new Error('companyGuid, serviceGuid and description are required');
    }

    const url = new URL(
      `${environment.shopsApiBaseUrl}/${this.createServiceApiKeyEndpoint}`
    );
    url.searchParams.append('companyGuid', params.companyGuid);
    url.searchParams.append('serviceGuid', params.serviceGuid);
    url.searchParams.append('description', params.description);

    return firstValueFrom(
      this.http.post(url.toString(), null, { responseType: 'text' })
    );
  }
}
