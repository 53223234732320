export class UserRegistrationData {
  constructor(
    public email: string,
    public firstName: string,
    public lastName: string,
    public password: string,
    public hashedCustomerID: string,
    public licenseNumber = '0'
  ) {}
}
