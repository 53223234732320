import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CSToastService } from '@compusoftgroup/cs-common-components';
import { MenuMultiLineItem } from '@compusoftgroup/cs-common-components/lib/components/cs-multi-line-menu/cs-multi-line-menu';
import { CsCloudAuthenticationService } from '@compusoftgroup/ngx-compusoft-cloud-authentication';
import {
  ACCOUNT_SYSTEM_ORIGIN_2020_SALESFORCE,
  CloudComponentService,
  CloudUser,
  Company,
  CompanyService,
  CompanyUser,
  DocumentTitleService,
  ExternalService,
  GuidUtilsService,
  ShopService,
  UserService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import {
  Document,
  DocumentService,
} from '@compusoftgroup/ngx-compusoft-cloud-documents-library';
import { cy_C_S_users_people_users_user } from '@cyncly/ngx-cy-icons';
import { TranslateService } from '@ngx-translate/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { firstValueFrom, Subject } from 'rxjs';
import { EnvironmentService } from 'src/environments/environment.service';
import { environment } from '../../environments/environment';
import * as frontendsTitle from '../../frontends-title.json';
import { AboutFlexComponent } from '../about-flex/about-flex.component';
import { IMPORT_EXPORT_COMPONENT_GUID } from '../constants/components.constants';
import { SearchResultElement } from '../models/search-result-element.class';
import { ContainerLanguageService } from '../services/container-language.service';
import { UserGuidingService } from '../services/userguiding.service';
import { GlobalParametersService } from '../services/global-parameters.service';
import { NavigationMenuService } from '../services/navigation-menu.service';
import { SearchService } from '../services/search.service';
import { TimerService } from '../services/timer.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css'],
})
export class ContainerComponent implements OnInit {
  @ViewChild('container', { read: ElementRef })
  public readonly containerElement;
  @ViewChild('autocompleteSearch')
  public autocompleteSearch;

  public loading = true;
  public searchResultItems: SearchResultElement[];
  public searchText: SearchResultElement;
  public sidebarIsVisible;
  public showAdminMenu = false;
  public showMainMenu = true;
  public showUserSettingsMenu = false;
  public showCustomerPortalMenu = false;
  public languagesMenu: SelectItem[] = [];
  public languageSelectorVisible = false;
  public userMenuItems: MenuMultiLineItem[] = [];
  public selectedLanguage = '';
  public collapse = false;
  public showSearch = false;
  public labelName = '';
  public profileImageUrl = '';
  public isHostedEnvironment = false;
  public ffFEA47215NewCustomerPortalMenu = false;
  public ffFEA115490 = false;
  public headerVisibility = false;
  public importExportComponentIsActive = false;
  public globalTitle = '';
  public userHasAccessToCurrentShop = false;
  public icon = {
    user: { cy_C_S_users_people_users_user },
  };
  public launchDarklyFrontendTestFlag = false;

  private readonly DESIGN_LIVE_SUPPORT_URL =
    'https://design.support.cyncly.com/';
  private readonly CUSTOMER_PORTAL_PATH = '/#/customer-portal';
  private shopName = '';
  private currentUser: CloudUser;
  private currentCompanyUser: CompanyUser;
  private redirectionCounter = 0;

  constructor(
    public authenticationService: CsCloudAuthenticationService,
    public searchService: SearchService,
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    public el: ElementRef,
    public cloudComponentService: CloudComponentService,
    public navigationMenuService: NavigationMenuService,
    public messageService: MessageService,
    public languageService: ContainerLanguageService,
    public translateService: TranslateService,
    public companyService: CompanyService,
    public userService: UserService,
    private externalService: ExternalService,
    private shopService: ShopService,
    public confirmationService: ConfirmationService,
    public globalParametersService: GlobalParametersService,
    public http: HttpClient,
    public dialogService: DialogService,
    public csToastService: CSToastService,
    public environmentService: EnvironmentService,
    public documentService: DocumentService,
    public cd: ChangeDetectorRef,
    public timerService: TimerService,
    private userGuidingService: UserGuidingService,
    private documentTitleService: DocumentTitleService,
    private guidUtilsService: GuidUtilsService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.setAPIBaseURLsInServices();

    (window as any).userMenuNavData = (updatedData) => {
      this.setUserMenuData(updatedData);
    };
    (window as any).openDocument = new Subject();
    (window as any).openDocument.subscribe(async (doc) => {
      this.openDocument(await this.documentService.getDocumentData(doc?.guid));
    });
    (window as any).timerService = timerService;
  }

  async ngOnInit() {
    (window as any).shopService = this.shopService;
    this.timerService.startTimer();
    this.timerService.addTimer('dashboard');
    this.timerService.addTimer('documents');
    this.timerService.addTimer('reports');
    this.currentUser = await this.userService.getCurrentUser(true);
    await this.init();

    // try {
    //   this.initialiseLaunchDarklyClient();
    // } catch (error) {
    //   console.error('Error initializing LaunchDarkly client:', error);
    // }

    this.loadLanguagesMenu();
    await this.initUserMenu();
    this.documentTitleService.updateDocumentTitle(
      this.companyService.getCurrentCompanyName(),
      this.shopService.getCurrentShopName()
    );
    if (!document.title || document.title === 'undefined') {
      document.title = 'Cyncly Flex';
    }
    this.showSearch = this.searchService.areThereSearchableComponents();
    this.importExportComponentIsActive =
      this.cloudComponentService.isComponentEnabled(
        IMPORT_EXPORT_COMPONENT_GUID
      );
  }

  public async changeLanguage() {
    const currentLanguage = this.userService.getlanguage();

    if (this.selectedLanguage && this.selectedLanguage !== currentLanguage) {
      await this.languageService.setLanguage(this.selectedLanguage, true);
      this.hideLanguageSelector();
      this.languageService.removeUrlLanguageParamAndReload();
    } else {
      this.hideLanguageSelector();
    }
  }

  public back() {
    this.location.back();
  }

  public async search(event) {
    const query = event.query;

    if (!query || query.length < 1 || (isNaN(query) && query.length < 2)) {
      return;
    }

    this.searchService.searching = true;

    try {
      setTimeout(async () => {
        this.autocompleteSearch.show();
        this.searchResultItems = [];
        this.searchResultItems = this.searchService.getLoadingResult();

        this.searchResultItems =
          await this.searchService.searchInAllBackendsAllowed(query);

        this.searchService.searching = false;
      }, 300);
    } catch (err) {
      console.error(err);
      this.searchService.searching = false;
    }
  }

  public onSelectSearchResult(event) {
    if (
      this.searchService.searching ||
      !event.name ||
      event.name === 'loading'
    ) {
      return;
    }

    this.searchText = new SearchResultElement(
      event.name,
      '',
      event.componentID
    );

    this.searchService.addItemTotSearchHistory(event);

    const url = this.searchService.generateURLFromSearchResult(event);
    if (url) {
      this.sidebarIsVisible = false;
      window.location.href = url;
      this.resetInputData();
    } else if (event.query) {
      this.search({ query: event.query });
    }
  }

  public sidebarMenuNavigate(menu) {
    if (!menu) {
      return;
    }
    if (menu.url) {
      window.location.href = menu.url;
    } else if (menu.command) {
      menu.command();
    }
    this.sidebarIsVisible = false;
  }

  public navigateToSimple(rute: string) {
    window.location.href = '/' + rute;
    this.sidebarIsVisible = false;
  }

  public navigateTo(rute: string) {
    window.location.href = '#/' + rute;
    this.sidebarIsVisible = false;
    this.setupActiveRouteHandler();
  }

  public toggleLeftMenu() {
    this.collapse = !this.collapse;
    if (this.collapse) {
      this.document.documentElement.style.setProperty(
        '--admin-menu-width',
        45 + 'px'
      );
    } else {
      this.document.documentElement.style.setProperty(
        '--admin-menu-width',
        270 + 'px'
      );
    }
  }

  public onHelpButtonClick(): void {
    if (this.shouldOpenDesignLiveSupport()) {
      this.openDesignLiveSupportPage();
      return;
    }

    if (this.isHostedEnvironment) {
      this.externalService.openHelpCentre();
      return;
    }

    this.openCustomerPortalOrWinnerDesignSupportPage();
  }

  public closeLightbox(event) {
    if (event) {
      this.documentService.isPreviewOpen = false;
      this.cd.detectChanges();
    }
  }

  public getSearchHistory() {
    if (!this.searchText) {
      const history = this.searchService.getSearchHistory();
      this.searchResultItems = history.map((e) => {
        const historyItem = new SearchResultElement(e.name, '');
        historyItem.name = e.name;
        historyItem.type = e.type;
        historyItem.guid = e.guid;
        historyItem.componentID = e.componentID;
        return historyItem;
      });

      if (this.searchResultItems.length > 0) {
        this.autocompleteSearch.show();
      }
    }
  }

  public stopPropagation(e) {
    e.stopPropagation();
  }

  public hideLanguageSelector() {
    this.languageSelectorVisible = false;
    this.selectedLanguage = this.languageService.translateService.currentLang;
  }

  private setAPIBaseURLsInServices() {
    this.shopService.shopsApiBaseUrl = this.environmentService.shopsApiBaseUrl;
    this.companyService.identityServerApiBaseUrl =
      this.environmentService.identityServerApiBaseUrl;
    this.companyService.shopsApiBaseUrl =
      this.environmentService.shopsApiBaseUrl;
  }

  private initialiseLaunchDarklyClient() {
    const ldClientSideID = environment.launchDarklyClientSideID;

    const context = {
      kind: 'multi',
      user: {
        key: this.currentUser.companyUserGuid,
        userGUID: this.currentUser.companyUserGuid,
        userEmail: this.currentUser.emailAddress,
        userLanguage: this.currentUser.language, // TODO parse languageGUID to human readable language
        userCustomerID:
          this.companyService.getCurrentCompany().salesForceAccountId,
        userName: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
      },
      company: {
        key: this.companyService.getCurrentCompanyGuid(),
        companyGUID: this.companyService.getCurrentCompanyGuid(),
        companyName: this.companyService.getCurrentCompany().companyName,
        companyCreatedDate:
          this.companyService.getCurrentCompany().cloudPlatformDatabaseCreated,
      },
    };

    const launchDarklyClient = LDClient.initialize(ldClientSideID, context);

    launchDarklyClient.on('ready', () => {
      this.launchDarklyFrontendTestFlag = launchDarklyClient.variation(
        'enable-frontend-test-flag-1',
        false
      );
    });
  }

  private async init() {
    await this.checkIfIsHostedEnvironment();

    if (!(await this.companyService.checkIfCompanyExists())) {
      console.warn('Company doesn not exist!');
      this.router.navigate(['company-chooser']);
      return;
    }

    this.checkHideHeaderQueryParameter();

    if (!this.currentUser) {
      console.error(
        'Redirecting to company chooser because user is not available!'
      );
      this.companyService.cleanCurrentCompany();
      this.shopService.cleanCurrentShop();
      this.router.navigate(['company-chooser']);
      return;
    }

    let currentUserShop = null;

    let currentShopGuid = this.shopService.getCurrentShopGuid();

    if (
      !currentShopGuid &&
      this.companyService.isCloudPlatformEnabled() &&
      !(await this.userService.isCurrentUserAdmin())
    ) {
      console.error(
        'CloudPlatform is enabled, but there is no selected shop!!!'
      );
      this.router.navigate(['company-chooser']);
      return;
    }

    if (currentShopGuid) {
      currentShopGuid = currentShopGuid.toUpperCase();
    }

    if (this.currentUser) {
      let userLanguage = (window as any).languages?.find(
        (l) => l.key === this.companyService.getDefaultLanguage()
      );

      const userDefinedLanguage = (window as any).languages?.find(
        (l) => l.key === this.languageService.currentLanguage
      );
      if (userDefinedLanguage) {
        userLanguage = userDefinedLanguage;
      }

      if (this.currentUser.language) {
        userLanguage = (window as any).languages?.find(
          (l) =>
            l.guid.toUpperCase() === this.currentUser.language.toUpperCase()
        );
      }

      const currentLanguage = (window as any).languages?.find(
        (l) => l.key === this.userService.getlanguage()
      );

      if (
        userLanguage &&
        userLanguage.guid.toUpperCase() !==
          currentLanguage.guid.toUpperCase() &&
        !this.languageService.isUsingUrlLanguage
      ) {
        await this.languageService.setLanguage(userLanguage.key);
        document.location.reload();
      }

      this.userHasAccessToCurrentShop =
        await this.isCurrentShopAccessibleByCurrentUser(currentShopGuid);

      if (this.userHasAccessToCurrentShop) {
        currentUserShop = this.currentUser.shops.find(
          (s) => s.shopGuid.toUpperCase() === currentShopGuid
        );
      }

      this.setUserMenuData({
        name: this.currentUser.firstName + ' ' + this.currentUser.lastName,
        imageUrl: this.userService.getUserAvatar(),
      });
    }

    this.cloudComponentService.setEnvironment(this.environmentService);

    await this.loadFeatureFlags();

    if (this.companyService.isCloudPlatformEnabled()) {
      await this.cloudComponentService.getCloudComponentsInfoFromServer(
        currentUserShop,
        await this.userService.isCurrentUserAdmin(),
        true
      );

      if (!currentUserShop && (await this.userService.isCurrentUserAdmin())) {
        window.location.href = '#/admin/company';
      } else if (this.companyService.getCurrentCompany().wizardActivated) {
        window.location.href = '#/admin/company/welcome';
      }

      if (this.cloudComponentService.checkFeature('FEA-97489')) {
        try {
          await this.userGuidingService.connectUserGuiding();
          if (this.currentUser) {
            this.userGuidingService.identifyUser(
              this.currentUser,
              this.currentCompanyUser,
              this.languageService.currentLanguage
            );
          }
        } catch (error) {
          console.error('error loading user guiding code', error);
        }
      }
    }

    await this.navigationMenuService.loadMenus(this.userHasAccessToCurrentShop);

    setTimeout(() => {
      this.setupActiveRouteHandler();
    }, 100);
  }

  private async isCurrentShopAccessibleByCurrentUser(currentShopGuid: string) {
    if (!this.currentUser || !currentShopGuid) {
      console.warn(
        'isCurrentShopAccessibleByCurrentUser: no current user or not current shop guid'
      );
      return false;
    }

    this.currentCompanyUser = await this.userService.getCompanyUser(
      this.currentUser.companyUserGuid,
      this.companyService.getCurrentCompanyGuid(),
      true
    );

    if (!this.currentCompanyUser) {
      console.warn('isCurrentShopAccessibleByCurrentUser: no company user');
      return false;
    }

    const currentShop = this.currentCompanyUser.shops.find((shop) =>
      this.guidUtilsService.equalGUIDs(shop?.shopGuid, currentShopGuid)
    );

    if (!currentShop) {
      console.warn('isCurrentShopAccessibleByCurrentUser: FALSE');
      return false;
    }

    console.warn('isCurrentShopAccessibleByCurrentUser: TRUE');
    return true;
  }

  private async checkIfIsHostedEnvironment() {
    if (this.globalParametersService.getParameter('hosted')) {
      this.isHostedEnvironment = true;
      return;
    }

    this.isHostedEnvironment = await this.externalService.isHostedEnvironment();
  }

  private async setUserMenuData(updatedData?: { name; imageUrl }) {
    if (!updatedData) {
      const user = await this.userService.getUserByGuid(
        this.userService.getCurrentCompanyUserGuid()
      );
      const name = `${user.firstName} ${user.lastName}`;
      this.profileImageUrl = this.userService.getUserAvatar();
      this.labelName = name;
    } else {
      this.profileImageUrl = updatedData.imageUrl;
      this.labelName = updatedData.name;
    }
  }

  private loadLanguagesMenu() {
    this.languagesMenu = [];

    const languages = this.languageService.translateService.getLangs();

    for (const lang of languages) {
      if (this.environmentService.languagesBlacklist.includes(lang)) {
        continue;
      }

      this.languagesMenu.push({
        label: this.languageService.languagesNames[lang.toLowerCase()],
        value: lang,
      });
    }

    this.selectedLanguage = this.languageService.translateService.currentLang;
  }

  private async initUserMenu() {
    const userMenu = [];
    const isAccountCompany =
      this.companyService.getCurrentCompany()?.companyUserSource === 'ACCOUNT';

    let iconHidePrices = 'cs-S-CheckBoxOff';

    let languagePreview = this.languageService.currentLanguage.toUpperCase();
    if (languagePreview === '0') {
      languagePreview = 'EN';
    }

    if (isAccountCompany) {
      userMenu.push({
        id: 'myAccount',
        label: 'cynclyAccount',
        icon: 'cs-S-UserSettings',
        sublabel: await this.translate('manageusers'),
        command: async () => {
          window.open('https://account.cyncly.com', '_blank');
        },
      });
    }

    if (this.companyService.isCloudPlatformEnabled()) {
      this.shopName = this.shopService.getCurrentShopName();

      try {
        const userCompanies: Company[] = await firstValueFrom(
          this.companyService.getCompanies()
        );
        const userCurrentCompanyShops: any[] = this.currentCompanyUser?.shops;

        if (!this.shopName) {
          this.shopName = '';
        }

        if (userCompanies?.length > 1 || userCurrentCompanyShops?.length > 1) {
          userMenu.push({
            id: 'changeShop',
            label: 'changeShop',
            icon: 'cs-S-Company',
            sublabel: `${this.shopName}`,
            command: async () => {
              await this.externalService.changeShop();
              location.href = '/company-chooser';
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      let companies = await this.companyService.getCompanies(true).toPromise();
      companies = companies.filter(
        (company) => company.validCompanyLogin === true
      );
      this.companyService.setCompaniesInCache(companies);

      if (companies && companies.length > 1) {
        userMenu.push({
          label: 'changeCompany',
          icon: 'cs-S-Company',
          command: async () => {
            await this.externalService.changeShop();
            location.href = '/company-chooser';
          },
        });
      }
    }
    if (localStorage.getItem('user_setting_hide_prices')) {
      if (JSON.parse(localStorage.getItem('user_setting_hide_prices'))) {
        iconHidePrices = 'cs-S-CheckBoxOn';
      } else {
        iconHidePrices = 'cs-S-CheckBoxOff';
      }
    }

    userMenu.push({
      label: 'changeLanguage',
      sublabel: `${languagePreview}`,
      icon: 'cs-S-Globe',
      command: () => {
        this.showLanguageSelector();
      },
    });
    if (this.companyService.isCloudPlatformEnabled()) {
      userMenu.push({
        label: 'hidePrices',
        id: 'toggleHidePrices',
        sublabel: await this.translate('showPricesInfo'),
        icon: iconHidePrices,
        command: () => {
          this.toogleHidePrices();
        },
      });
    }

    if (this.companyService.isCloudPlatformEnabled()) {
      userMenu.push({
        label: 'aboutFlex',
        icon: 'cs-S-Info',
        command: () => {
          this.openAboutFlexModal();
        },
      });
    }

    this.addSignOutToUserMenu(userMenu);

    for (const item of userMenu) {
      item.label = await this.translate(item.label);
    }

    this.userMenuItems = userMenu;

    this.subscribeToCurrentShopNameChanges();
  }

  private subscribeToCurrentShopNameChanges() {
    this.shopService.shopNameSubject.subscribe((shopName) => {
      this.updateCurrentShopNameInUserMenu(shopName);
    });
  }

  private updateCurrentShopNameInUserMenu(shopName: string): void {
    const changeShopItem = this.userMenuItems?.find(
      (items) => items.id === 'changeShop'
    );

    if (!changeShopItem) {
      return;
    }

    changeShopItem.sublabel = shopName;
  }

  private async toogleHidePrices() {
    let pricingInformationStatus = false;

    if (localStorage.getItem('user_setting_hide_prices')) {
      pricingInformationStatus = JSON.parse(
        localStorage.getItem('user_setting_hide_prices')
      );
    }

    const toggleHidePrices = this.userMenuItems.find(
      (row) => row.id === 'toggleHidePrices'
    );
    if (!toggleHidePrices) {
      return;
    }

    try {
      localStorage.setItem(
        'user_setting_hide_prices',
        JSON.stringify(!pricingInformationStatus)
      );
      if (pricingInformationStatus) {
        toggleHidePrices.icon = 'cs-S-CheckBoxOff';
        this.csToastService.success(await this.translate('showPricesSuccess'));
      } else {
        toggleHidePrices.icon = 'cs-S-CheckBoxOn';
        this.csToastService.success(await this.translate('hidePricesSuccess'));
      }
    } catch (error) {
      this.csToastService.error(await this.translate('togglePricesError'));
    }
  }

  private showLanguageSelector() {
    this.languageSelectorVisible = true;
  }

  private setLoader(value: boolean) {
    this.loading = value;
  }

  private getFirstMainMenuItemUrl() {
    return this.navigationMenuService.mainMenu?.[0]?.url;
  }

  private async changeFrontendTitle(fragment: string) {
    const segments = fragment?.split('/') || [''];
    let title = 'Flex';
    let currentPath = '';
    segments.forEach((segment) => {
      if (
        Object.prototype.hasOwnProperty.call(
          frontendsTitle,
          currentPath + segment
        )
      ) {
        title = frontendsTitle[currentPath + segment];
      }
      currentPath = currentPath + segment + '/';
    });

    this.globalTitle = await this.translate(title);
  }

  private async setupActiveRouteHandler() {
    this.route.fragment.subscribe(async (fragment: string) => {
      const fragmentWithoutFirstSlash = fragment?.split('?')[0]?.slice(1);
      this.changeFrontendTitle(fragmentWithoutFirstSlash);
      fragment = '#' + (fragment || '/');
      this.messageService.clear();

      if (fragment === '#/') {
        const firstMenuUrl = this.getFirstMainMenuItemUrl();
        if (
          firstMenuUrl &&
          firstMenuUrl !== '#/' &&
          this.redirectionCounter < 1
        ) {
          location.href = firstMenuUrl;
          this.redirectionCounter++;
          return;
        }
      }

      const currentComponentID =
        this.cloudComponentService.getComponentIDForCurrentRoute(
          this.route.snapshot,
          await this.userService.isCurrentUserAdmin()
        );

      if (currentComponentID != null) {
        if (currentComponentID === 'shop-manager' && fragment === '#/') {
          window.location.href =
            this.environmentService.uiBaseUrl +
            '?t=' +
            new Date().getTime() +
            '#/admin/company';
        } else {
          this.cloudComponentService.loadComponentByIdIfNotLoaded(
            currentComponentID,
            (value: boolean) => {
              this.setLoader(value);
            }
          );
        }
      } else {
        this.messageService.add({
          severity: 'error',
          key: 'global',
          summary: 'Page not found!',
          detail: "This page doesn't exist.",
          sticky: true,
        });
        window.location.replace('/404');
        return;
      }

      let activeFound = false;

      //check right buttons
      let elements: HTMLElement[] = Array.from(
        this.el.nativeElement.querySelectorAll('.more-actions > a')
      );

      elements.forEach((element) => {
        element.classList.remove('active');
        const routebase: any =
          element.getAttribute('data-routebase')?.split(',') || [];
        routebase.forEach((route) => {
          if (fragment.startsWith(route) && !activeFound) {
            element.classList.add('active');
            activeFound = true;
          }
        });
      });

      elements = Array.from(
        this.el.nativeElement.querySelectorAll('#main-menu > a')
      );
      elements = elements.sort((element1, element2) => {
        return element1.getAttribute('href').length <
          element2.getAttribute('href').length
          ? 1
          : -1;
      });

      elements.forEach((element) => {
        element.classList.remove('active');
        if (activeFound) {
          return;
        }

        const href = element.getAttribute('href');

        if (
          fragment === href ||
          (href !== '#/' &&
            (fragment.startsWith(href + '/') ||
              fragment.startsWith(href + '?')))
        ) {
          element.classList.add('active');
          activeFound = true;
        }
      });

      if (this.companyService.isCloudPlatformEnabled()) {
        this.showCustomerPortalMenu = false;

        if (fragment.startsWith('#/admin/company/welcome')) {
          this.showAdminMenu = false;
          this.showMainMenu = false;
          this.showSearch = false;
          this.navigationMenuService.showLeftMenu = false;
        } else if (
          fragment.startsWith('#/admin') ||
          fragment.startsWith('#/customer-portal')
        ) {
          this.navigationMenuService.showLeftMenu = true;
          this.showAdminMenu = true;
          this.navigationMenuService.activateMenuItem(
            currentComponentID,
            fragment
          );
        } else {
          this.navigationMenuService.showLeftMenu = false;
          this.showAdminMenu = false;
          this.showMainMenu = true;
          this.showSearch = true;
        }

        if (fragment.startsWith('#/user-settings')) {
          this.showUserSettingsMenu = true;
          this.navigationMenuService.showLeftMenu = true;
          this.navigationMenuService.activateMenuItem(
            currentComponentID,
            fragment
          );
        } else {
          this.showUserSettingsMenu = false;
        }
      } else {
        this.showCustomerPortalMenu = true;
        this.showAdminMenu = false;
        this.showUserSettingsMenu = false;
        this.navigationMenuService.showLeftMenu = true;

        if (fragment === '#/') {
          location.href = '#/customer-portal';
        }

        this.navigationMenuService.activateCustomerPortalMenuItem(
          currentComponentID,
          fragment
        );
      }
    });
  }

  private async translate(value: string) {
    return this.translateService.get(value).toPromise();
  }

  private async trySignout() {
    this.confirmationService.confirm({
      header: await this.translate('signOut'),
      message: await this.translate('doYouReallyWantToSignOut'),
      accept: async () => {
        await this.signout();
      },
      reject: () => {},
      key: 'signoutConfirmDialog',
    });
  }

  private async openAboutFlexModal() {
    this.dialogService.open(AboutFlexComponent, {
      header: await this.translate('aboutFlex'),
      data: {},
      styleClass: 'responsive-small-dialog',
      dismissableMask: false,
      closable: true,
    });
  }

  private openDocument(document: Document): void {
    this.documentService.isPreviewOpen = true;
    this.documentService.documentForShow = document;
    this.cd.detectChanges();
  }

  private isAdminArea(fragment) {
    if (fragment.startsWith('#/admin')) {
      return true;
    }

    return false;
  }

  private getInitialUrlFragment() {
    let fragment = '/';

    if (this.route.snapshot && this.route.snapshot.fragment) {
      fragment = this.route.snapshot.fragment;
    }

    fragment = '#' + (fragment || '/');

    return fragment;
  }

  private async signout() {
    await this.externalService.signOut();
    this.companyService.cleanCurrentCompany();
    this.shopService.cleanCurrentShop();
    this.authenticationService.logout(
      this.languageService.translateService.currentLang
    );
  }

  private resetInputData() {
    const input: HTMLInputElement = document.querySelector(
      '#autocompleteSearch input'
    );
    if (input) {
      input.value = null;
    }
    this.searchText = null;
    setTimeout(() => {
      this.searchText = null;
      (document.activeElement as any).blur();
    });
  }

  private getQueryParams(): any {
    const paramList = {};

    try {
      if (this.router.url.split('?').length > 1) {
        this.router.url
          .split('?')[1]
          .split('&')
          .forEach(
            (param) => (paramList[param.split('=')[0]] = param.split('=')[1])
          );
      }
    } catch (e) {
      console.error(e);
    }

    return paramList;
  }

  private checkHideHeaderQueryParameter(): void {
    const queryParams = this.getQueryParams();
    const disablemenuQueryParam = queryParams.disablemenu;

    if (!disablemenuQueryParam) {
      this.showHeader();
      return;
    }

    const hideHeader = JSON.parse(disablemenuQueryParam);

    if (hideHeader) {
      this.hideHeader();
    } else {
      this.showHeader();
    }
  }

  private showHeader() {
    this.headerVisibility = true;
    document.documentElement.style.setProperty('--display-header', 'block');
    document.documentElement.style.setProperty('--header-height', '120px');
  }

  private hideHeader() {
    this.headerVisibility = false;
    document.documentElement.style.setProperty('--display-header', 'none');
    document.documentElement.style.setProperty('--header-height', '0px');
  }

  private async loadFeatureFlags() {
    await this.cloudComponentService.loadFeatureFlags(
      this.translateService.currentLang
    );

    // FEA-47215 to show new customer portal menu
    this.ffFEA47215NewCustomerPortalMenu =
      this.cloudComponentService.checkFeature('FEA-47215');

    // FEA-115490 to hide sign out button¡
    this.ffFEA115490 = this.cloudComponentService.checkFeature('FEA-115490');
  }

  private addSignOutToUserMenu(userMenu: MenuMultiLineItem[]) {
    const currentCompanyAccountSystemOrigin =
      this.companyService.getCurrentCompany()?.accountSystemOrigin;

    if (
      this.ffFEA115490 &&
      currentCompanyAccountSystemOrigin ===
        ACCOUNT_SYSTEM_ORIGIN_2020_SALESFORCE &&
      this.isHostedEnvironment
    ) {
      return;
    }

    userMenu.push({
      label: 'signOut',
      icon: 'cs-S-Logout',
      command: async () => {
        await this.trySignout();
      },
    });
  }

  private shouldOpenDesignLiveSupport(): boolean {
    const currentCompany = this.companyService.getCurrentCompany();
    return (
      currentCompany.accountSystemOrigin ===
      ACCOUNT_SYSTEM_ORIGIN_2020_SALESFORCE
    );
  }

  private openDesignLiveSupportPage(): void {
    window.open(this.DESIGN_LIVE_SUPPORT_URL, '_blank');
  }

  private openWinnerDesignSupportPage() {
    let lang = this.translateService.currentLang.toLowerCase(); //toLowerCase because the help center URL needs lowercase, for en-us
    if (lang === 'en') {
      lang += '-gb';
    } else if (lang === 'nb') {
      lang = 'no';
    }

    window.open(
      `https://winnerdesign.support.compusoftgroup.com/hc/${lang}`,
      '_blank'
    );
  }

  private openCustomerPortalOrWinnerDesignSupportPage(): void {
    if (this.ffFEA47215NewCustomerPortalMenu) {
      window.location.href = this.CUSTOMER_PORTAL_PATH;
    } else {
      this.openWinnerDesignSupportPage();
    }
  }
}
