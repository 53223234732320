import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cs-back-arrow',
  templateUrl: './back-arrow.component.html',
  styleUrls: ['./back-arrow.component.css'],
})
export class BackArrowComponent implements OnInit, OnDestroy {
  public showArrow = false;
  public routerLink = '';
  private allowedPaths = {
    '/#/projects/design/*': '#/projects/(projectGuid)',
    '/#/projects/quote/*': '#/projects/(projectGuid)?tab=quotes',
    '/#/projects/*': '#/projects',
    '/#/contacts/*': '#/contacts',
  };
  private routeChangesSubscription: Subscription;

  constructor(private router: Router) {
    this.subscribeToRouteChanges();
  }

  public ngOnInit(): void {
    this.toggleShowArrow();
  }

  public ngOnDestroy(): void {
    this.routeChangesSubscription?.unsubscribe();
  }

  public navigateTo(path: string) {
    location.href = path;
  }

  private subscribeToRouteChanges() {
    this.routeChangesSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.toggleShowArrow();
      }
    });
  }

  private toggleShowArrow() {
    this.showArrow = this.checkPath(this.router.url);
  }

  private checkPath(path: string): boolean {
    return Object.keys(this.allowedPaths).some((allowedPath) => {
      const regex = new RegExp(
        '^' + allowedPath.replace(/\*/g, '.*').replace(/\//g, '\\/') + '$'
      );
      const test = regex.test(path);
      if (test) {
        this.setRouterLink(this.allowedPaths[allowedPath]);
        return test;
      }
    });
  }

  private setRouterLink(route: string) {
    if (!route) {
      return;
    }

    if (route.includes('projects/(projectGuid)')) {
      route = this.customBackPathForProjectURL(route);
    }
    this.routerLink = route;
  }

  private customBackPathForProjectURL(route: string) {
    let projectGuid = location.href
      ?.split('/#/projects/design/')?.[1]
      ?.split('/')?.[0];

    if (!projectGuid) {
      projectGuid = location.href
        ?.split('/#/projects/quote/')?.[1]
        ?.split('/')?.[0];
    }

    if (!projectGuid) {
      return route;
    }

    route = route.replace('(projectGuid)', projectGuid);
    return route;
  }
}
