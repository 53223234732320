import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { CSToastService } from '@compusoftgroup/cs-common-components';
import { CompanyService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { EnvironmentService } from 'src/environments/environment.service';

@Injectable({
  providedIn: 'root',
})
export class ContainerCustomerPortalService {
  constructor(
    public translateService: TranslateService,
    public http: HttpClient,
    public environmentService: EnvironmentService,
    public companyService: CompanyService,
    public csToastService: CSToastService,
    public fileSaverService: FileSaverService
  ) {}

  public async downloadCopyrightNoticesByProduct(
    product: string
  ): Promise<boolean> {
    const message = await this.translateService
      .get('downloadIsStarting')
      .toPromise();
    this.csToastService.success(message);

    await this.http
      .get(
        this.environmentService.customerPortalApiBaseUrl +
          '/Get_DownloadCopyrightNoticesByProduct',
        {
          params: {
            product,
            companyGuid: this.companyService.getCurrentCompanyGuid(),
          },
          responseType: 'blob',
        }
      )
      .subscribe((resp: any) => {
        this.fileSaverService.save(resp, 'copyright-notices.zip');
      });

    return false;
  }
}
