import { SERVICE_KEY_ERROR_CODES } from './../models/service-key-response-error-codes.constants';
import { ServiceKeyService } from './../services/service-key.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceKeyResponse } from '../models/service-key-response';
import {
  CloudComponentService,
  CompanyService,
  GuidUtilsService,
  ShopService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import {
  CompanyServiceApiKey,
  GetCompanyServicesResponse,
} from '../models/get-company-services-response.interface';

@Component({
  selector: 'cs-service-key',
  templateUrl: './service-key.component.html',
  styleUrls: ['./service-key.component.css'],
})
export class ServiceKeyComponent {
  private opener: Window;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serviceKeyService: ServiceKeyService,
    private cloudCompanyService: CloudComponentService,
    private companyService: CompanyService,
    private guidUtilsService: GuidUtilsService,
    private shopService: ShopService
  ) {
    this.opener = window.opener;
    this.route.queryParams.subscribe(async (params) => {
      const serviceGuid = params?.serviceGuid;
      const keyDescription = params?.keyDescription;

      if (!serviceGuid || !keyDescription) {
        this.postErrorMessage(
          SERVICE_KEY_ERROR_CODES.INVALID_REQUEST,
          'Invalid request: serviceGuid and keyDescription params are required'
        );
        return;
      }

      const companyGuid = await this.companyService.getCurrentCompanyGuid();
      const shopGuid = await this.shopService.getCurrentShopGuid();

      if (!companyGuid) {
        this.router.navigate(['/company-chooser']);
        return;
      }

      if (
        !this.checkIfHasComponentPermission(
          this.cloudCompanyService.getComponentsForCurrentCompany()
        )
      ) {
        this.postErrorMessage(
          SERVICE_KEY_ERROR_CODES.UNAUTHORIZED,
          'Component not allowed',
          { companyGuid, shopGuid }
        );
        return;
      }
      let services: GetCompanyServicesResponse[];
      try {
        services = await this.serviceKeyService.getCompanyServices(companyGuid);
      } catch (error) {
        this.postErrorMessage(
          SERVICE_KEY_ERROR_CODES.SERVICE_NOT_FOUND,
          'Service not found',
          { companyGuid, shopGuid }
        );
        return;
      }
      const servicesKey = services?.find(
        (service: GetCompanyServicesResponse) =>
          this.guidUtilsService.equalGUIDs(service.serviceGuid, serviceGuid)
      );

      if (!servicesKey) {
        this.postErrorMessage(
          SERVICE_KEY_ERROR_CODES.SERVICE_NOT_FOUND,
          'Service not found',
          { companyGuid, shopGuid }
        );
        return;
      }

      const apiKey = await this.obtainKeyFromService(
        companyGuid,
        servicesKey,
        keyDescription
      );

      if (!apiKey) {
        this.postErrorMessage(
          SERVICE_KEY_ERROR_CODES.KEY_NOT_FOUND,
          'Key has not been found for the service, also it was not possible to create a new one. Please contact support.',
          { companyGuid, shopGuid }
        );
        return;
      }

      this.postMessageToOriginWindow({
        companyGuid,
        shopGuid,
        apiKey,
      });
    });
  }

  private postErrorMessage(
    errorCode: string,
    errorMessage: string,
    guid?: { companyGuid: string; shopGuid: string }
  ) {
    this.postMessageToOriginWindow({
      companyGuid: guid.companyGuid,
      shopGuid: guid.shopGuid,
      apiKey: '',
      error: {
        code: errorCode,
        message: errorMessage,
      },
    });
  }

  private postMessageToOriginWindow(response: ServiceKeyResponse) {
    this.opener.postMessage(response, '*');
    window.close();
  }

  private async obtainKeyFromService(
    companyGuid: string,
    companyService: GetCompanyServicesResponse,
    keyDescription: string
  ): Promise<string> {
    if (!companyService?.companyServiceApiKey || !keyDescription) {
      return;
    }

    const key = this.checkIfServiceKeyExist(
      companyService.companyServiceApiKey,
      keyDescription
    );
    if (key) {
      return key;
    }

    return await this.createKeyForService({
      companyGuid,
      serviceGuid: companyService.serviceGuid,
      description: keyDescription,
    });
  }

  private async createKeyForService(params: {
    companyGuid: string;
    serviceGuid: string;
    description: string;
  }): Promise<any> {
    try {
      return await this.serviceKeyService.createServiceAPIKey(params);
    } catch (error) {
      console.error(error);
      return;
    }
  }

  private checkIfServiceKeyExist(
    companyServiceApiKey: CompanyServiceApiKey[],
    keyDescription: string
  ): string {
    return companyServiceApiKey.find(
      (key) => key.description === keyDescription
    )?.apiKey;
  }

  private checkIfHasComponentPermission(componentList: any): boolean {
    const COMPONENT_GUID = '225ff8a5-42d3-4eda-b22d-9dece77f93da';
    const SUBMENU_URL = 'admin/company/services';

    if (!componentList) {
      return false;
    }
    return Boolean(
      componentList
        .find((c) => c.componentGuid === COMPONENT_GUID)
        ?.submenus?.find((s) => s.url === SUBMENU_URL)
    );
  }
}
