<div
  *ngIf="launchDarklyFrontendTestFlag"
  class="launch-darkly-message-container"
>
  LaunchDarkly POV - New banner Feature has been enabled for you! :)
</div>
<div class="wrapper-container">
  <nav
    id="header"
    *ngIf="headerVisibility"
    [class.hosted]="isHostedEnvironment"
    [class.hidden]="navigationMenuService.isLoadingHeader"
    data-test-id="header"
  >
    <div class="nav-header">
      <div id="header-logo">
        <img src="/assets/logos/flex.svg" alt="" />
      </div>
      <div id="main-menu" *ngIf="showMainMenu" data-test-id="mainMenu">
        <a
          *ngFor="let menu of navigationMenuService.mainMenu"
          href="{{ menu.url }}"
          class="menu-item"
          [attr.data-test-id]="menu.label"
        >
          <cy-icon [icon]="menu.icon" [size]="24"></cy-icon>
          <span>{{ menu.label }}</span>
        </a>
      </div>
    </div>
    <div class="nav-footer">
      <div id="header-settings">
        <div class="more-actions">
          <a
            *ngIf="companyService.isCloudPlatformEnabled()"
            data-test-id="buttonHelpCentre"
            class="menu-item"
            [title]="'helpCentre_contactSupport' | translate"
            [attr.data-routebase]="['#/customer-portal']"
            (click)="onHelpButtonClick()"
          >
            <cy-icon
              [icon]="'cy_C_L_guide_communicate_guide_help'"
              [size]="24"
            ></cy-icon>
            <span> {{ 'help' | translate }}</span>
          </a>
          <a
            *ngIf="
              ffFEA47215NewCustomerPortalMenu &&
              showMainMenu &&
              navigationMenuService.menuSections.adminArea.length > 0
            "
            data-test-id="buttonAdmin"
            class="menu-item"
            [attr.data-routebase]="['#/admin/', '#/user-settings']"
            (click)="navigationMenuService.navigateToAdmin()"
          >
            <cy-icon
              [icon]="'cy_C_L_core_commands_tools_core_commands_settings'"
              [size]="24"
            ></cy-icon>
            <span> {{ 'settings' | translate }}</span>
          </a>
          <a
            *ngIf="
              !ffFEA47215NewCustomerPortalMenu &&
              showMainMenu &&
              navigationMenuService.adminMenu.length > 0
            "
            data-test-id="buttonAdmin"
            class="menu-item"
            [attr.data-routebase]="['#/admin', '#/user-settings']"
            (click)="navigationMenuService.adminMenu[0].command()"
          >
            <cy-icon
              [icon]="'cy_C_L_core_commands_tools_core_commands_settings'"
              [size]="24"
            ></cy-icon>
            <span> {{ 'settings' | translate }}</span>
          </a>
        </div>
      </div>
      <div id="header-menu">
        <cy-icon
          [icon]="'cy_C_S_menu_controls_menu_menu_file'"
          [size]="'S'"
          (click)="sidebarIsVisible = !sidebarIsVisible"
          data-test-id="buttonMenu"
        ></cy-icon>
      </div>
    </div>
  </nav>
  <main>
    <input
      *ngFor="let key of timerService.keysWithResults"
      id="timer_{{ key }}"
      name="timer_{{ key }}"
      type="hidden"
      value="{{ timerService.totalTimes[key] }}"
      [attr.data-test-id]="'timer_' + key"
    />
    <cs-lightbox
      *ngIf="documentService.isPreviewOpen"
      [document]="documentService.documentForShow"
      (OnCloseLightbox)="closeLightbox($event)"
    >
    </cs-lightbox>
    <div
      class="frontend-wrapper"
      [class.disabled-header]="!headerVisibility"
      [class.withAdminMenu]="
        showAdminMenu || showUserSettingsMenu || showCustomerPortalMenu
      "
    >
      <div *ngIf="!ffFEA47215NewCustomerPortalMenu">
        <div id="admin-menu" class="vertical-menu" *ngIf="showAdminMenu">
          <div class="vertical-menu-header">
            <span [class]="collapse ? 'collapseText' : ''">{{
              'adminArea' | translate
            }}</span>
            <div
              (click)="toggleLeftMenu()"
              class="pointer"
              [class.cs-S-DoubleArrowL]="!collapse"
              [class.cs-S-DoubleArrowR]="collapse"
              data-test-id="buttonToggleLeftMenu"
            ></div>
          </div>

          <cs-panelMenu
            [model]="navigationMenuService.adminMenu"
            [multiple]="false"
            [style.width]="'100%'"
            [styleClass]="
              collapse ? 'collapseText no-arrows collapseicons' : 'no-arrows'
            "
            data-test-id="panelMenuAdminMenu"
          ></cs-panelMenu>
        </div>

        <div
          id="user-settings-menu"
          class="vertical-menu"
          *ngIf="showUserSettingsMenu"
        >
          <div class="vertical-menu-header">
            <span [class]="collapse ? 'collapseText' : ''">{{
              'userSettings' | translate
            }}</span>
            <div
              (click)="toggleLeftMenu()"
              class="pointer"
              [class.cs-S-DoubleArrowL]="!collapse"
              [class.cs-S-DoubleArrowR]="collapse"
              data-test-id="buttonToggleLeftMenu"
            ></div>
          </div>

          <cs-panelMenu
            [model]="navigationMenuService.userSettingsMenu"
            [multiple]="false"
            [style.width]="'100%'"
            [styleClass]="
              collapse ? 'collapseText no-arrows collapseicons' : 'no-arrows'
            "
            data-test-id="panelMenuUserSettingsMenu"
          >
          </cs-panelMenu>
        </div>

        <div
          id="customer-portal-menu"
          class="vertical-menu"
          *ngIf="showCustomerPortalMenu"
        >
          <div class="vertical-menu-header">
            <span [class]="collapse ? 'collapseText' : ''">{{
              'customerPortal' | translate
            }}</span>
            <div
              (click)="toggleLeftMenu()"
              class="pointer"
              [class.cs-S-DoubleArrowL]="!collapse"
              [class.cs-S-DoubleArrowR]="collapse"
              data-test-id="buttonToggleLeftMenu"
            ></div>
          </div>

          <cs-panelMenu
            [model]="navigationMenuService.customerPortalMenu"
            [multiple]="false"
            [style.width]="'100%'"
            [styleClass]="
              collapse ? 'collapseText no-arrows collapseicons' : 'no-arrows'
            "
            data-test-id="panelMenuCustomerPortalMenu"
          >
          </cs-panelMenu>
        </div>
      </div>
      <div
        id="new-admin-vertical-menu"
        class="vertical-menu"
        *ngIf="
          ffFEA47215NewCustomerPortalMenu && navigationMenuService.showLeftMenu
        "
      >
        <div class="vertical-menu-grid">
          <div *ngIf="navigationMenuService.menuSections.customerPortal.length">
            <div class="vertical-menu-header">
              <span> {{ 'customerPortal' | translate }}</span>
            </div>
            <cs-panelMenu
              [model]="navigationMenuService.menuSections.customerPortal"
              [multiple]="false"
              [style.width]="'100%'"
              data-test-id="panelMenuCustomerPortal"
            >
            </cs-panelMenu>
          </div>
          <div
            class="optionMenu"
            *ngIf="navigationMenuService.menuSections.userSettings.length"
          >
            <div class="vertical-menu-header">
              <span> {{ 'userSettings' | translate }}</span>
            </div>
            <cs-panelMenu
              [model]="navigationMenuService.menuSections.userSettings"
              [multiple]="false"
              [style.width]="'100%'"
              data-test-id="panelMenuUserSettings"
            >
            </cs-panelMenu>
          </div>
          <div
            class="optionMenu"
            *ngIf="navigationMenuService.menuSections.adminArea.length"
          >
            <div class="vertical-menu-header">
              <span> {{ 'adminArea' | translate }}</span>
            </div>
            <cs-panelMenu
              [model]="navigationMenuService.menuSections.adminArea"
              [multiple]="false"
              [style.width]="'100%'"
              data-test-id="panelMenuAdminArea"
            >
            </cs-panelMenu>
          </div>
        </div>
      </div>
      <section
        class="header-with-content"
        [ngClass]="{ 'without-navbar': !headerVisibility }"
      >
        <header *ngIf="headerVisibility">
          <section class="user-menu-container">
            <cs-durable-process-menu
              *ngIf="
                importExportComponentIsActive && userHasAccessToCurrentShop
              "
              id="durable-process-menu"
            >
            </cs-durable-process-menu>
            <cs-multi-line-menu
              id="profileButton"
              [textColor]="'dark'"
              [styleButton]="{ 'background-color': 'transparent' }"
              [styleMenu]="{ 'min-width': '250px' }"
              class="line-height-18"
              [positionRight]="true"
              [model]="userMenuItems"
              [label]="labelName"
              data-test-id="multiLineMenuProfileButton"
              [icon]="icon.user"
              [image]="profileImageUrl"
            >
            </cs-multi-line-menu>
          </section>
          <section class="global-header">
            <section class="global-title">
              <cs-back-arrow></cs-back-arrow>
              <span>{{ globalTitle }}</span>
            </section>
            <section class="global-search">
              <label class="input-element">
                {{ 'quickSearch' | translate }}
              </label>
              <p-autoComplete
                id="autocompleteSearch"
                #autocompleteSearch
                [suggestions]="searchResultItems"
                (completeMethod)="search($event)"
                (onSelect)="onSelectSearchResult($event)"
                panelStyleClass="search-box-dropdown"
                field="name"
                (onFocus)="getSearchHistory()"
                placeholder="{{ 'Search' | translate }}..."
                [emptyMessage]="'noResults' | translate"
                (keydown.enter)="autocompleteSearch.overlayVisible = true"
                [(ngModel)]="searchText"
                [style]="{ border: '0' }"
                appendTo="body"
              >
                <ng-template let-item pTemplate="item">
                  <div
                    *ngIf="item.type"
                    class="cs-search-item cs-search-item-size"
                  >
                    <div class="cs-search-title">{{ item.name }}</div>
                    <div
                      class="cs-search-subtitle"
                      *ngIf="item.type.length > 0"
                    >
                      <span *ngIf="item.name?.length > 0">{{
                        'in' | translate
                      }}</span>
                      <span
                        [class.cs-search-item-category]="!item.name?.length"
                      >
                        {{ item.type | titlecase | translate }}</span
                      >
                    </div>
                  </div>

                  <div
                    *ngIf="item.type == null && item.name == null"
                    class="cs-search-item"
                    (click)="stopPropagation($event)"
                    data-test-id="buttonGlobalSearch"
                  >
                    <div class="cs-search-title">
                      {{ 'noResults' | translate }}
                    </div>
                  </div>

                  <div
                    *ngIf="item.type == null && item.name === 'loading'"
                    class="cs-search-item"
                    (click)="stopPropagation($event)"
                    data-test-id="buttonGlobalSearch"
                  >
                    <div class="cs-search-title">
                      {{ 'loading' | translate }}
                    </div>
                  </div>

                  <div *ngIf="item.totalResults" class="cs-search-item">
                    <div
                      class="cs-search-title"
                      style="display: flex; justify-content: space-between"
                    >
                      <span style="white-space: nowrap">{{ item.name }}</span
                      ><span
                        data-test-id="labelGlobalSearchResult"
                        style="margin-left: 15px"
                        >{{ item.totalResults }}
                        {{ 'results' | translate }}</span
                      >
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </section>
          </section>
        </header>
        <div id="content"></div>
      </section>
    </div>
    <p-dialog
      header="{{ 'chooseYourLanguage' | translate }}"
      [(visible)]="languageSelectorVisible"
      [modal]="true"
      [responsive]="true"
      [style]="{ width: '350px', minWidth: '200px' }"
      [contentStyle]="{ overflow: 'visible' }"
      [closable]="false"
      data-test-id="dialogLanguageSelector"
    >
      <p-dropdown
        *ngIf="selectedLanguage"
        tzeDropdownFix
        [options]="languagesMenu"
        [(ngModel)]="selectedLanguage"
        [filter]="languagesMenu | showFilterSearch"
        data-test-id="dropdownLanguageSelector"
      >
      </p-dropdown>
      <p-footer>
        <div class="dialog-buttons modal-footer-buttons">
          <p-button
            [label]="'cancel' | translate"
            (click)="hideLanguageSelector()"
            data-test-id="buttonCancelLanguageSelector"
          ></p-button>
          <p-button
            styleClass="p-button-accented"
            [label]="'ok' | translate"
            (click)="changeLanguage()"
            data-test-id="buttonAcceptLanguageSelector"
          ></p-button>
        </div>
      </p-footer>
    </p-dialog>
    <p-confirmDialog
      #signoutConfirmDialog
      key="signoutConfirmDialog"
      [style]="{ width: '50vw', 'min-width': '250px' }"
    >
      <p-footer>
        <div class="dialog-buttons modal-footer-buttons">
          <p-button
            [label]="'cancel' | translate"
            (click)="signoutConfirmDialog.reject()"
            data-test-id="buttonCancelSignout"
          ></p-button>
          <p-button
            styleClass="p-button-accented"
            [label]="'signOut' | translate"
            (click)="signoutConfirmDialog.accept()"
            data-test-id="buttonAcceptSignout"
          ></p-button>
        </div>
      </p-footer>
    </p-confirmDialog>
    <p-confirmDialog
      #confirmDialogCancelProcess
      key="confirmDialogCancelProcess"
      [style]="{ width: '50vw' }"
    >
      <p-footer>
        <p-button
          [label]="'no' | translate"
          (click)="confirmDialogCancelProcess.reject()"
          data-test-id="buttonNoCancelProcess"
        ></p-button>
        <p-button
          styleClass="p-button-accented"
          [label]="'yes' | translate"
          (click)="confirmDialogCancelProcess.accept()"
          data-test-id="buttonYesCancelProcess"
        ></p-button>
      </p-footer>
    </p-confirmDialog>
    <cs-documents-multi-upload></cs-documents-multi-upload>
    <div class="cs-import-file-widget">
      <compusoft-cloud-import-file-widget></compusoft-cloud-import-file-widget>
    </div>
    <cs-global-loader [visible]="loading"></cs-global-loader>
  </main>
</div>
