import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-durable-processes-loader',
  templateUrl: './cs-durable-processes-loader.component.html',
  styleUrls: ['./cs-durable-processes-loader.component.css']
})
export class CsDurableProcessesLoaderComponent {
  @Input() public processesCompleted = true;

  constructor() { }

}
