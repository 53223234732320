import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CsCloudAuthenticationGuard } from '@compusoftgroup/ngx-compusoft-cloud-authentication';
import { CompanyChooserComponent } from './company-chooser/company-chooser.component';
import { ContainerComponent } from './container/container.component';
import { CustomerAreaComponent } from './customer-area/customer-area.component';
import { LanguageChooserComponent } from './language-chooser/language-chooser.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PublicContainerComponent } from './public-container/public-container.component';
import { SelfRegistrationComponent } from './self-registration/self-registration.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { TermsConditionsRedirectorComponent } from './terms-conditions-redirector/terms-conditions-redirector.component';
import { InAppSupportComponent } from './in-app-support/in-app-support.component';
import { IdpLoginComponent } from './idp-login/idp-login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthRedirectorComponent } from './auth-redirector/auth-redirector.component';
import { ServiceKeyComponent } from './service-key/service-key.component';
import { SpeedtestComponent } from './speedtest/speedtest.component';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    canActivate: [CsCloudAuthenticationGuard],
  },
  {
    path: 'company-chooser',
    component: CompanyChooserComponent,
    canActivate: [CsCloudAuthenticationGuard],
  },
  {
    path: 'company-chooser/:companyGuid',
    component: CompanyChooserComponent,
    canActivate: [CsCloudAuthenticationGuard],
  },
  {
    path: 'company-chooser/:companyGuid/:shopGuid',
    component: CompanyChooserComponent,
    canActivate: [CsCloudAuthenticationGuard],
  },
  {
    path: 'customer',
    component: CustomerAreaComponent,
    canActivate: [CsCloudAuthenticationGuard],
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent,
  },
  {
    path: 'self-registration',
    component: SelfRegistrationComponent,
  },
  {
    path: 'public/:componentName',
    component: PublicContainerComponent,
  },
  {
    path: 'signout',
    component: SignOutComponent,
  },
  // {
  //   path: 'backoffice',
  //   component: BackofficeRedirectorComponent
  // },
  {
    path: 'language-chooser',
    component: LanguageChooserComponent,
    canActivate: [CsCloudAuthenticationGuard],
  },
  {
    path: 'tc',
    component: TermsConditionsRedirectorComponent,
  },
  {
    path: 'in-app-support',
    component: InAppSupportComponent,
  },
  {
    path: 'idp-login',
    component: IdpLoginComponent,
  },
  {
    path: 'auth-redirector',
    component: AuthRedirectorComponent,
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },

  {
    path: 'get-service-key',
    component: ServiceKeyComponent,
    canActivate: [CsCloudAuthenticationGuard],
  },
  {
    path: 'speedtest',
    component: SpeedtestComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
