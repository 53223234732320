import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompusoftThemesService } from '@compusoftgroup/compusoft-themes';
import {
  ClientIdService,
  CsCloudAuthenticationService,
} from '@compusoftgroup/ngx-compusoft-cloud-authentication';
import {
  CloudComponentService,
  ValidatorService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { EnvironmentService } from '../../environments/environment.service';
import { ContainerLanguageService } from '../services/container-language.service';

@Component({
  selector: 'cs-idp-login',
  templateUrl: './idp-login.component.html',
  styleUrls: ['./idp-login.component.css'],
})
export class IdpLoginComponent implements OnInit, OnDestroy {
  @ViewChild('suggestions') private suggestions: any;
  @ViewChild('emailInput') private emailInput: any;

  public loginForm!: UntypedFormGroup;
  public languageHint = '';
  public errorMessage = '';
  public actionUrl = '';
  public loadingTheme = true;
  public currentTheme = 'default';
  public rememberedUsers = [];
  public loading = true;
  public redirecting = false;
  public autoSubmit = true;
  public suggestionsVisibility = true;

  private authorizeEndpoint = '/connect/authorize';
  private duendeGetIDPsForUsrEndpoint =
    '/api/loginproviderduende/getidentityprovideridsbyuser';
  private idpUsersUrl = '';
  private errCode = '';
  private nonce = '';
  private state = '';
  private redirUri = '';
  private responseType = '';
  private scope = '';
  private clientId = '';
  private debug = false;
  private themeSubscription: Subscription = null;
  private loginHint = '';
  private domainHint = '';
  private idpList = '';
  private ff83100CodeFlow = false;
  private ff96575GetClientFlow = false;

  // Old Clients with Implicit Flow
  private clientIdsWithImplicitFlow = [
    'CompusoftGateway',
    'CompusoftGatewayShortLived',
    'CompusoftContainer',
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private translator: TranslateService,
    private themesService: CompusoftThemesService,
    private cd: ChangeDetectorRef,
    private environmentService: EnvironmentService,
    private http: HttpClient,
    private authenticationService: CsCloudAuthenticationService,
    private languageService: ContainerLanguageService,
    public validator: ValidatorService,
    private cloudComponentService: CloudComponentService,
    private clientIdService: ClientIdService
  ) {
    this.clientIdService.apiBaseUrl =
      this.environmentService.authenticationBaseUrl;
  }

  @HostListener('document:click', ['$event'])
  public clickout(event) {
    if (!this.suggestions.nativeElement.contains(event.target)) {
      this.hideSuggestions();
    }
  }

  @HostListener('window:pageshow', ['$event'])
  public onPopState(event) {
    if (event.persisted) {
      this.redirecting = false;
    }
  }

  async ngOnInit() {
    document.title = 'Cyncly Login';
    this.setCurrentTheme();
    this.initThemeChangeListener();
    await this.loadLoginFeatureFlags();
    this.setRememberedUsers();

    this.actionUrl =
      this.environmentService.identityServerApiBaseUrl + this.authorizeEndpoint;
    this.idpUsersUrl =
      this.environmentService.authenticationBaseUrl +
      this.duendeGetIDPsForUsrEndpoint;

    // FOR LOCAL DEVELOPMENT ONLY:
    //this.actionUrl = 'https://localhost:5001/connect/authorize';

    if (!this.environmentService.production) {
      this.debug = true;
    }

    this.processQueryParameters();
  }

  ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }

  public checkShowSuggestions(): void {
    if (this.loginForm.get('login_hint').value.length > 0) {
      return;
    }

    this.showSuggestions();
  }

  public showSuggestions(): void {
    this.suggestionsVisibility = true;
  }

  public hideSuggestions() {
    this.suggestionsVisibility = false;
  }

  public async onSubmit(event: any) {
    if (this.loginForm.valid) {
      this.redirecting = true;

      try {
        if (this.idpList === '') {
          const email = event.target[0].value;

          this.idpList = await this.getIdpListFromServer(email);
        }

        this.updateFormHiddenFields();

        if (this.debug) {
          console.warn('----------- Form Values -----------');
          console.warn('event:', event);
          console.warn('event.target[0].value', event.target[0].value);
          console.warn('event.target[1].value', event.target[1].value);
          console.warn('event.target[2].value', event.target[2].value);
          console.warn('event.target[3].value', event.target[3].value);
          console.warn('event.target[4].value', event.target[4].value);
          console.warn('event.target[5].value', event.target[5].value);
          console.warn('event.target[6].value', event.target[6].value);
          console.warn('event.target[7].value', event.target[7].value);
          console.warn('event.target[8].value', event.target[8].value);
          console.warn('event.target[9].value', event.target[9].value);
          console.warn('event.target[10].value', event.target[10].value);
          console.warn('----------- ----------- -----------');
        }

        // Submit form:
        event.target.submit();
      } catch (error) {
        this.redirecting = false;
        console.error('Failed to submit form with error:', error);
      }
    }
  }

  public useRememberedUserClicked(email) {
    this.loginForm.get('login_hint').patchValue(email);
    this.hideSuggestions();
  }

  public removeRememberedUserClicked(email) {
    this.authenticationService.removeRememberedUser(email);
    this.rememberedUsers = this.authenticationService.getRememberedUsers();
  }

  public async onHoverUser(email) {
    if (!email) {
      this.emailInput.nativeElement.placeholder = await firstValueFrom(
        this.translator.get('enterYourEmail')
      );
      return;
    }
    this.emailInput.nativeElement.placeholder = email;
  }

  private async getIdpListFromServer(email) {
    let idpList = '';

    try {
      const providerList = await this.http
        .get<Array<number>>(this.idpUsersUrl, {
          params: email ? new HttpParams().set('email', email) : {},
        })
        .toPromise();

      idpList = providerList.join(';');
    } catch (error) {
      console.warn('Failed to get list of login providers with error:', error);
      this.errorMessage = await this.translator
        .get('err.noLoginProviders')
        .toPromise();
    }

    return idpList;
  }

  private updateFormHiddenFields() {
    this.loginForm.get('nonce').patchValue(this.nonce);
    this.loginForm.get('state').patchValue(this.state);
    this.loginForm.get('scope').patchValue(this.scope);
    this.loginForm.get('response_type').patchValue(this.responseType);
    this.loginForm.get('redirect_uri').patchValue(this.redirUri);
    this.loginForm.get('client_id').patchValue(this.clientId);
    this.loginForm.get('lang').patchValue(this.languageHint);
    this.loginForm.get('domain_hint').patchValue(this.domainHint);
    this.loginForm.get('idp_list')?.patchValue(this.idpList);
    this.loginForm.get('ui_theme').patchValue(this.currentTheme);

    if (this.debug) {
      console.warn('nonce:', this.loginForm.get('nonce').value);
      console.warn('state:', this.loginForm.get('state').value);
      console.warn('scope:', this.loginForm.get('scope').value);
      console.warn('response_type:', this.loginForm.get('response_type').value);
      console.warn('redirect_uri:', this.loginForm.get('redirect_uri').value);
      console.warn('client_id:', this.loginForm.get('client_id').value);
      console.warn('lang:', this.loginForm.get('lang').value);
      console.warn('domain_hint:', this.loginForm.get('domain_hint').value);
      console.warn('idp_list:', this.loginForm.get('idp_list')?.value);
      console.warn('ui_theme:', this.loginForm.get('ui_theme').value);
    }
  }

  private async getParamsFromAuthSvc() {
    if (this.debug) console.warn('Params fetched via getSignInUrl');

    // This will create 'state' and 'nonce' variables in localstorage - both required upon redirect back to flex
    await this.authenticationService.getSignInUrl().then((url) => {
      const parts = url.split('?');
      const params = parts[1].split('&');

      // Find and extract parameters
      params.forEach((param) => {
        if (param.includes('nonce')) {
          this.nonce = param.substring(param.indexOf('=') + 1);
        } else if (param.includes('state')) {
          this.state = param.substring(param.indexOf('=') + 1);
        } else if (param.includes('scope')) {
          this.scope = param.substring(param.indexOf('=') + 1);
        } else if (param.includes('response_type')) {
          this.responseType = param.substring(param.indexOf('=') + 1);
        } else if (param.includes('redirect_uri')) {
          this.redirUri = param.substring(param.indexOf('=') + 1);
        } else if (param.includes('client_id')) {
          this.clientId = param.substring(param.indexOf('=') + 1);
        } else if (param.includes('domain_hint')) {
          this.domainHint = param.substring(param.indexOf('=') + 1);
        } else if (param.includes('idp_list')) {
          this.idpList = param.substring(param.indexOf('=') + 1);
        }
      });
    });
  }

  private setRememberedUsers() {
    this.rememberedUsers = this.authenticationService.getRememberedUsers();
  }

  private setLanguage(): void {
    this.languageHint = this.languageService.getlanguage();

    if (this.languageHint) {
      this.translator.use(this.languageHint);
    }
  }

  private async setErrors(): Promise<void> {
    // Was an error passed as a param?
    // If so, display error message
    if (this.errCode) {
      // err01 = username not found
      // err02 = server error
      this.errorMessage = await this.translator.get(this.errCode).toPromise();
    }
  }

  private clearErrorMessage() {
    this.errorMessage = '';
  }

  private setCurrentTheme() {
    this.currentTheme = this.themesService.getActiveThemeName();
  }

  // Listen for changes to theme
  private initThemeChangeListener() {
    const activeTheme = this.themesService.getActiveThemeName();

    if (activeTheme) {
      this.loadingTheme = false;
    }
    this.themeSubscription = this.themesService
      .getThemeChangeObservable()
      .subscribe(() => {
        this.currentTheme = this.themesService.getActiveThemeName();
        this.cd.detectChanges();
        this.loadingTheme = false;
      });

    setTimeout(() => {
      if (!this.loadingTheme) {
        return;
      }

      this.themesService.setTheme('winner-design');
      this.currentTheme = this.themesService.getActiveThemeName();
      this.cd.detectChanges();
      this.loadingTheme = false;
    }, 3000);
  }

  private async loadLoginFeatureFlags() {
    const featureFlags = await this.cloudComponentService.fetchFeatures();

    if (!featureFlags) {
      return;
    }

    const ff83100 = featureFlags.find((ff) => ff.identifier === 'FEA-83100');

    if (ff83100?.enabled) {
      this.ff83100CodeFlow = true;
    }

    const ff96575 = featureFlags.find((ff) => ff.identifier === 'FEA-96575');

    if (ff96575?.enabled) {
      this.ff96575GetClientFlow = true;
    }

    console.warn('CODE FLOW', this.ff83100CodeFlow);
    console.warn('GET CLIENT FLOW', this.ff96575GetClientFlow);
  }

  private async decideAuthenticationFlow() {
    let clientUsesCodeFlow = true;

    if (this.ff96575GetClientFlow) {
      try {
        clientUsesCodeFlow = await this.clientIdService.clientSupportsCodeFlow(
          this.clientId
        );
      } catch (error) {
        console.error('Unable to check if clientSupportsCodeFlow.' + error);
      }
    }

    // double check, to ensure that old clients are not broken
    if (this.clientIdsWithImplicitFlow.includes(this.clientId)) {
      clientUsesCodeFlow = false;
    }

    console.warn('Client supports code flow: ' + clientUsesCodeFlow);

    if (clientUsesCodeFlow && this.ff83100CodeFlow) {
      this.responseType = 'code';
      if (!this.scope?.includes('offline_access')) {
        this.scope += ' offline_access';
      }
    } else {
      this.responseType = 'id_token token';
      if (this.scope?.includes('offline_access')) {
        this.scope = this.scope.replace('offline_access', '');
      }
    }
  }

  private processQueryParameters() {
    // Extract parameters from query
    this.route.queryParamMap.subscribe(async (params) => {
      this.errCode = params.get('err') ?? '';
      this.languageHint = params.get('lang') ?? '';

      // Once Flex redirects to this login page, these will be passed:
      this.nonce = params.get('nonce') ?? '';
      this.state = params.get('state') ?? '';
      this.redirUri = params.get('redirect_uri') ?? '';
      this.responseType = params.get('response_type') ?? '';
      this.scope = params.get('scope') ?? '';
      this.clientId = params.get('client_id') ?? '';
      this.loginHint = params.get('login_hint') ?? '';
      this.domainHint = params.get('domain_hint') ?? '';
      this.idpList = params.get('idp_list') ?? '';

      this.setLanguage();
      this.setErrors();

      // Once the auth guard redirects here, these values will be pulled from the GET request (above)
      // However, this portion of code is still important in case the user navigates directly to this page
      if (!this.nonce && !this.state) {
        await this.getParamsFromAuthSvc();
      }

      // Check cache for domainHint and idpList if empty
      if (!this.domainHint && !this.idpList) {
        if (localStorage) {
          this.idpList = localStorage.getItem('idp_list') ?? '';
          this.domainHint = localStorage.getItem('domain_hint') ?? '';

          if (this.debug) {
            console.warn('Fetched cached idp list:', this.idpList);
            console.warn('Fetched cached domain hint:', this.domainHint);
          }
        }
      } else {
        // Cache domainHint / idpList
        if (localStorage) {
          if (this.domainHint) {
            localStorage.setItem('domain_hint', this.domainHint);

            if (this.debug) {
              console.warn(
                'Cached domain hint:',
                localStorage.getItem('domain_hint') ?? ''
              );
            }
          }
          if (this.idpList) {
            localStorage.setItem('idp_list', this.idpList);

            if (this.debug) {
              console.warn(
                'Cached idp list:',
                localStorage.getItem('idp_list') ?? ''
              );
            }
          }
        }
      }

      if (!this.clientId) {
        this.clientId = await this.authenticationService.getCurrentClientId();
      } else if (localStorage) {
        localStorage.setItem('client_id', this.clientId);
      }

      this.authenticationService.forgetToken();
      await this.decideAuthenticationFlow();

      // If either of these parameters were passed in, we should auto submit - effectively skipping the initial login page
      if (this.domainHint || this.idpList) {
        let autoSubmitUrl = this.actionUrl + '?';

        /*eslint-disable @typescript-eslint/naming-convention*/
        const queryParams: { [key: string]: string } = {
          nonce: this.nonce,
          state: this.state,
          redirect_uri: this.redirUri,
          // FOR LOCAL DEVELOPMENT ONLY: redirect_uri: 'https://dev-flex.compusoftgroup.com/authenticate',
          response_type: this.responseType,
          scope: this.scope,
          client_id: this.clientId,
          login_hint: this.loginHint,
          domain_hint: this.domainHint,
          idp_list: this.idpList,
        };
        /*eslint-enable @typescript-eslint/naming-convention*/

        const query = Object.entries(queryParams)
          .filter(([, value]) => value) // filter out any empty values
          .map(([key, value]) => `${key}=${value}`)
          .join('&');

        autoSubmitUrl += query;

        if (this.debug) {
          console.warn('Auto Submit - autoSubmitUrl:', autoSubmitUrl);
        }

        window.location.href = autoSubmitUrl;
      } else {
        this.autoSubmit = false;
      }

      if (this.loginHint?.length > 0) {
        this.hideSuggestions();
      }

      // Init the form
      // Only login_hint should be filled out by user, rest should be empty when submitted (enforced):
      /*eslint-disable @typescript-eslint/naming-convention*/
      this.loginForm = this.fb.group({
        login_hint: [
          this.loginHint,
          [Validators.required, this.validator.emailValidator()],
        ],
        client_id: [''],
        redirect_uri: [''],
        response_type: [''],
        scope: [''],
        nonce: [''],
        state: [''],
        idp_list: [''],
        lang: [''],
        ui_theme: [''],
        domain_hint: [''],
        response_mode: ['fragment'],
      });
      /*eslint-enable @typescript-eslint/naming-convention*/

      this.loginForm.get('login_hint')?.valueChanges.subscribe(() => {
        this.clearErrorMessage();
      });

      this.loading = false;
    });
  }
}
