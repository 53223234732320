import { DurableProcessesService } from './../../services/durable-processes.service';
import {
  Component,
  HostListener,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'cs-durable-process-menu',
  templateUrl: './durable-process-menu.component.html',
  styleUrls: ['./durable-process-menu.component.css'],
})
export class DurableProcessMenuComponent implements OnInit {
  @ViewChild('durableProcessMenuContainer')
  public durableProcessMenuContainer: ElementRef;
  @ViewChild('durableProcessMenuButton')
  public durableProcessMenuButton: ElementRef;

  constructor(public durableProcessesService: DurableProcessesService) {
    (window as any).durableProcessesService = durableProcessesService;
  }

  @HostListener('document:click', ['$event'])
  public clickout(event): void {
    try {
      if (
        !this.durableProcessMenuContainer.nativeElement.contains(
          event.target
        ) &&
        !this.durableProcessMenuButton.nativeElement.contains(event.target)
      ) {
        this.durableProcessesService.hideDurableFunctionsList();
      }
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit(): void {
    this.durableProcessesService.processes.subscribe((data) => {
      if (!data) {
        return;
      }
      if (!data.length) {
        this.durableProcessesService.hideDurableFunctionsList();
      }
    });
  }



  public get areAllProcessesCompleted() {
    return this.durableProcessesService.areAllProcessesCompleted;
  }

  public get totalDurableProcesses() {
    return this.durableProcessesService.processes.value.length;
  }
}
