<div
  class="about-flex-container"
  *ngIf="!loading && !error"
  data-test-id="componentAboutFlex"
>
  <div class="row sales-force">
    <div class="title">{{ 'customerID' | translate }}</div>
    <div class="info">
      <div class="id">
        {{ data['salesforce'].id }}
        <cy-icon
          [icon]="'cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy'"
          [size]="'S'"
          title="{{ 'copyToClipboard' | translate }}"
          class="copy-data"
          (click)="copyToClipboard(data['salesforce'].id)"
          data-test-id="buttonCopySalesForceIdToClipboard"
        ></cy-icon>
      </div>
    </div>
  </div>
  <div class="row user">
    <div class="title">{{ 'userInfo' | translate }}</div>
    <div class="info">
      <div class="name">
        {{ data['user'].name }}
        <cy-icon
          [icon]="'cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy'"
          [size]="'S'"
          title="{{ 'copyToClipboard' | translate }}"
          class="copy-data"
          (click)="copyToClipboard(data['user'].name)"
          data-test-id="buttonCopyUserNameToClipboard"
        >
        </cy-icon>
      </div>
      <div class="id">
        {{ data['user'].id }}
        <cy-icon
          [icon]="'cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy'"
          [size]="'S'"
          title="{{ 'copyToClipboard' | translate }}"
          class="copy-data"
          (click)="copyToClipboard(data['user'].id)"
          data-test-id="buttonCopyUserIdToClipboard"
        ></cy-icon>
      </div>
    </div>
  </div>
  <div class="row company">
    <div class="title">{{ 'companyInformation' | translate }}</div>
    <div class="info">
      <div class="name">
        {{ data['company'].name }}
        <cy-icon
          [icon]="'cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy'"
          [size]="'S'"
          title="{{ 'copyToClipboard' | translate }}"
          class="copy-data"
          (click)="copyToClipboard(data['company'].name)"
          data-test-id="buttonCopyCompanyNameToClipboard"
        ></cy-icon>
      </div>
      <div class="id">
        {{ data['company'].id }}
        <cy-icon
          [icon]="'cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy'"
          [size]="'S'"
          title="{{ 'copyToClipboard' | translate }}"
          class="copy-data"
          (click)="copyToClipboard(data['company'].id)"
          data-test-id="buttonCopyCompanyIdToClipboard"
        ></cy-icon>
      </div>
    </div>
  </div>
  <div class="row shop">
    <div class="title">{{ 'shop' | translate }}</div>
    <div class="info">
      <div class="name">
        {{ data['shop'].name }}
        <cy-icon
          [icon]="'cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy'"
          [size]="'S'"
          title="{{ 'copyToClipboard' | translate }}"
          class="copy-data"
          (click)="copyToClipboard(data['shop'].name)"
          data-test-id="buttonCopyShopNameToClipboard"
        ></cy-icon>
      </div>
      <div class="id">
        {{ data['shop'].id }}
        <cy-icon
          [icon]="'cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy'"
          [size]="'S'"
          title="{{ 'copyToClipboard' | translate }}"
          class="copy-data"
          (click)="copyToClipboard(data['shop'].id)"
          data-test-id="buttonCopyShopIdToClipboard"
        ></cy-icon>
      </div>
    </div>
  </div>

  <p-accordion [styleClass]="'odd-accordion'">
    <p-accordionTab header="{{ 'legalInformation' | translate }}">
      <div class="licenseAgreementTitle">
        {{ 'licenseAgreement' | translate }}
      </div>
      <div>
        {{ 'licenseAgreementInfo' | translate }}
      </div>
      <br />
      <span
        class="element-link"
        (click)="showModalTermConditions()"
        data-test-id="buttonShowModalTermConditions"
      >
        {{ 'termsAndConditions' | translate }}
      </span>
      <div class="legalInformationTitle">
        {{ 'privacyPolicy' | translate }}
      </div>
      <div>
        {{ 'privacyPolicyInfo' | translate }}
        <br />
        <br />
        <span
          class="element-link"
          (click)="showModalPrivacyPolicy()"
          data-test-id="buttonShowModalPrivacyPolicy"
        >
          {{ 'privacyPolicy' | translate }}
        </span>
      </div>
      <div class="legalInformationTitle">
        {{ 'includedThirdPartySoftware' | translate }}
      </div>
      <div>
        {{ 'includedThirdPartySoftwareInfo' | translate }}
        <br />
        <br />
        <span
          class="element-link"
          (click)="downloadCopyrightNotices()"
          data-test-id="buttonDownloadCopyrightNotices"
        >
          {{ 'copyrightNotices' | translate }}
        </span>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Send Feedback">
      <section *ngIf="!showSentFeedbackMessage; else feedbackSentSuccessfully">
        <p>
          {{ 'feedbackinfo' | translate }}
        </p>
        <div class="feedback-container">
          <textarea
            pInputTextarea
            class="feedback-textarea"
            [(ngModel)]="feedbackMessage"
            data-test-id="textareaFeedback"
          ></textarea>

          <button
            pButton
            [disabled]="!feedbackMessage"
            class="feedback-button"
            (click)="sendFeedback()"
            data-test-id="buttonSendFeedback"
            [label]="'send' | translate"
          ></button>
        </div>
      </section>
    </p-accordionTab>
  </p-accordion>
</div>

<div class="progress-circular" *ngIf="loading">
  <cs-progress-circular></cs-progress-circular>
</div>

<div *ngIf="error">
  <cs-not-available-content
    [message]="'contentNotAvailableAtThisMoment' | translate"
  >
  </cs-not-available-content>
</div>

<p-dialog
  [(visible)]="showTermConditions"
  [modal]="true"
  [showHeader]="true"
  [responsive]="true"
  [draggable]="false"
  [closable]="true"
  [style]="{
    width: 'min(1200px, 100vw)',
    height: '750px',
    overflow: 'hidden',
    position: 'absolute'
  }"
  (onHide)="closeModalTermConditions()"
  class="paddingTermsConditions"
>
  <p-header>{{ 'compusoftGroupTermsAndConditions' | translate }}</p-header>
  <div>
    <iframe
      width="100%"
      height="750px"
      [src]="urlTermsConditionsIframe | safe: 'resourceUrl'"
    >
    </iframe>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="showPrivacyPolicy"
  [modal]="true"
  [showHeader]="true"
  [responsive]="true"
  [draggable]="false"
  [closable]="true"
  [style]="{
    width: 'min(1200px, 100vw)',
    height: '750px',
    overflow: 'hidden',
    position: 'absolute'
  }"
  (onHide)="closeModalPrivacyPolicy()"
  class="paddingTermsConditions"
>
  <p-header>{{ 'compusoftGroupPrivacyPolicy' | translate }}</p-header>
  <div>
    <iframe
      width="100%"
      height="750px"
      [src]="urlPrivacyPolicyIframe | safe: 'resourceUrl'"
    >
    </iframe>
  </div>
</p-dialog>

<ng-template #feedbackSentSuccessfully>
  <p class="bold">{{ 'feedbackDialogTitle' | translate }}</p>
  <p>
    {{ 'feedbackDialogContent' | translate }}
  </p>
</ng-template>
