import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CompusoftThemesService } from '@compusoftgroup/compusoft-themes';
import { MenuMultiLineItem } from '@compusoftgroup/cs-common-components/lib/components/cs-multi-line-menu/cs-multi-line-menu';
import { CsCloudAuthenticationService } from '@compusoftgroup/ngx-compusoft-cloud-authentication';
import {
  ACCOUNT_SYSTEM_ORIGIN_2020_SALESFORCE,
  CloudComponentService,
  CompanyService,
  ExternalService,
  LocalUserPreferencesService,
  ShopService,
  UserService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { cy_C_S_users_people_users_user } from '@cyncly/ngx-cy-icons';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EnvironmentService } from '../../environments/environment.service';
import { ContainerLanguageService } from '../services/container-language.service';
import { GlobalParametersService } from '../services/global-parameters.service';

@Component({
  selector: 'cs-company-chooser',
  templateUrl: './company-chooser.component.html',
  styleUrls: ['./company-chooser.component.css'],
  providers: [ConfirmationService],
})
export class CompanyChooserComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public companyGuid: string;
  public shopGuid: string;
  public ignoreMissingCompany = false;
  public loading = true;
  public userMenuItems: MenuMultiLineItem[] = [];
  public languageSelectorVisible = false;
  public selectedLanguage = '';
  public languagesMenu: SelectItem[] = [];
  public currentTheme = 'default';
  public loadingTheme = true;
  public companyChooserFeatureFlags: { [key: string]: boolean } = null;
  public labelName = '';
  public ffFEA92653 = false;

  public icon = {
    user: { cy_C_S_users_people_users_user },
  };

  private themeSubscription: Subscription = null;
  private isHostedEnvironment = false;
  private ffFEA115490 = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public companyService: CompanyService,
    public translateService: TranslateService,
    public externalService: ExternalService,
    public languageService: ContainerLanguageService,
    public localUserPreferencesService: LocalUserPreferencesService,
    public authenticationService: CsCloudAuthenticationService,
    public confirmationService: ConfirmationService,
    public environmentService: EnvironmentService,
    private themesService: CompusoftThemesService,
    private cd: ChangeDetectorRef,
    private shopService: ShopService,
    private cloudComponentService: CloudComponentService,
    private userService: UserService,
    private globalParametersService: GlobalParametersService
  ) {
    this.companyService.identityServerApiBaseUrl =
      environmentService.identityServerApiBaseUrl;
    this.cloudComponentService.setEnvironment(this.environmentService);
  }

  async ngOnInit() {
    this.externalService.hideCompanyChooser();
    await this.checkIfIsHostedEnvironment();
    this.initThemeChangeListener();
    this.loadLanguagesMenu();
    await this.loadFeatureFlags();
    this.readQueryParams();
    await this.initUserMenu();
  }

  ngAfterViewInit(): void {
    this.currentTheme = this.themesService.getActiveThemeName();
  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }

  public async onChangeLanguage(event) {
    if (event.value) {
      this.languageService.setLanguage(event.value, true);
      await this.initUserMenu();
    }
    setTimeout(() => {
      this.hideLanguageSelector();
    }, 300);
  }

  public onCompanyChanged() {
    this.initUserMenu();
  }

  private initThemeChangeListener() {
    const activeTheme = this.themesService.getActiveThemeName();

    if (activeTheme) {
      this.loadingTheme = false;
    }

    this.themeSubscription = this.themesService
      .getThemeChangeObservable()
      .subscribe(() => {
        this.currentTheme = this.themesService.getActiveThemeName();
        this.cd.detectChanges();
        this.loadingTheme = false;
      });

    setTimeout(() => {
      if (!this.loadingTheme) {
        return;
      }

      this.themesService.setTheme('winner-design');
      this.currentTheme = this.themesService.getActiveThemeName();
      this.cd.detectChanges();
      this.loadingTheme = false;
    }, 3000);
  }

  private async initUserMenu() {
    let languagePreview = this.languageService?.currentLanguage?.toUpperCase();
    const user = await this.userService.getCurrentUser(true);

    this.labelName = await this.translate('user');
    if (user) {
      this.labelName = `${user.firstName} ${user.lastName}`;
    } else {
      this.labelName = this.authenticationService.getTokenEmail();
    }

    if (!languagePreview || languagePreview === '0') {
      languagePreview = 'EN';
    }

    const userMenu = [];
    userMenu.push({
      id: 'languageMenu',
      label: 'Change language',
      sublabel: `${languagePreview}`,
      icon: 'cs-S-Globe',
      command: () => {
        this.showLanguageSelector();
      },
    });

    this.addSignOutToUserMenu(userMenu);

    for (const item of userMenu) {
      item.label = await this.translate(item.label);
    }

    this.userMenuItems = userMenu;
  }

  private loadLanguagesMenu() {
    this.languagesMenu = [];

    const languages = this.languageService.translateService.getLangs();

    for (const lang of languages) {
      this.languagesMenu.push({
        label: this.languageService.languagesNames[lang.toLowerCase()],
        value: lang,
      });
    }

    this.selectedLanguage = this.languageService.translateService.currentLang;
  }

  private async translate(value: string) {
    return this.translateService.get(value).toPromise();
  }

  private showLanguageSelector() {
    this.languageSelectorVisible = true;
  }

  private hideLanguageSelector() {
    this.languageSelectorVisible = false;
  }

  private async trySignout() {
    this.confirmationService.confirm({
      header: await this.translate('signOut'),
      message: await this.translate('doYouReallyWantToSignOut'),
      accept: async () => {
        await this.signout();
      },
      reject: () => {},
      key: 'signoutConfirmDialog',
    });
  }

  private async signout() {
    await this.externalService.signOut();
    this.companyService.cleanCurrentCompany();
    this.shopService.cleanCurrentShop();
    this.authenticationService.logout(
      this.languageService.translateService.currentLang
    );
  }

  private readQueryParams() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.companyGuid = params.get('companyGuid');
      this.shopGuid = params.get('shopGuid');

      if (
        !this.companyGuid &&
        this.globalParametersService.getParameter('companyGuid')
      ) {
        this.companyGuid =
          this.globalParametersService.getParameter('companyGuid');
      }

      if (
        params.get('ignoreMissingCompany') &&
        params.get('ignoreMissingCompany').toLowerCase() !== 'false' &&
        params.get('ignoreMissingCompany') !== '0'
      ) {
        this.ignoreMissingCompany = true;
      }
      this.loading = false;
    });
  }

  private async loadFeatureFlags() {
    await this.cloudComponentService.loadFeatureFlags(
      this.translateService.currentLang,
      true
    );

    this.companyChooserFeatureFlags = {};

    this.companyChooserFeatureFlags['FEA-87739'] =
      this.cloudComponentService.checkFeature('FEA-87739');

    // FEA-92653 to show only available shops for Admins
    this.ffFEA92653 = this.cloudComponentService.checkFeature('FEA-92653');

    // FEA-115490 to hide sign out button
    this.ffFEA115490 = this.cloudComponentService.checkFeature('FEA-115490');
  }

  private async checkIfIsHostedEnvironment() {
    if (this.globalParametersService.getParameter('hosted')) {
      this.isHostedEnvironment = true;
      return;
    }

    this.isHostedEnvironment = await this.externalService.isHostedEnvironment();
  }

  private addSignOutToUserMenu(userMenu: MenuMultiLineItem[]) {
    const currentCompany = this.companyService.getCurrentCompany();

    if (
      this.ffFEA115490 &&
      this.isHostedEnvironment &&
      currentCompany?.accountSystemOrigin ===
        ACCOUNT_SYSTEM_ORIGIN_2020_SALESFORCE
    ) {
      return;
    }

    userMenu.push({
      label: 'signOut',
      icon: 'cs-S-Logout',
      command: async () => {
        await this.trySignout();
      },
    });
  }
}
