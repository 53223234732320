<div *ngIf="!loading && !autoSubmit" class="content">
  <div class="centered-content">
    <div class="login-container">
      <div class="logo">
        <img src="../../assets/images/logo.svg" class="logo" />
      </div>
      <h5>{{ 'loginToYourAccount' | translate }}</h5>
      <div class="global-err-msg" *ngIf="errorMessage !== ''">
        {{ errorMessage }}
      </div>
      <form
        class="login-form"
        [action]="actionUrl"
        method="GET"
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit($event)"
      >
        <div #suggestions class="ui-input-form">
          <cy-input-suggestions
            *ngIf="suggestionsVisibility && rememberedUsers?.length > 0"
            [suggestions]="rememberedUsers"
            (preselectedSuggestion)="onHoverUser($event)"
            (selectedSuggestion)="useRememberedUserClicked($event)"
            (removedSuggestion)="removeRememberedUserClicked($event)"
          ></cy-input-suggestions>
          <label class="input-element">{{ 'email' | translate }}</label>
          <input
            pInputText
            autofocus
            #emailInput
            autocomplete="off"
            name="login_hint"
            id="login_hint"
            formControlName="login_hint"
            type="text"
            placeholder="{{ 'enterYourEmail' | translate }}"
            [ngClass]="{
              'input-err':
                loginForm.get('login_hint')?.invalid &&
                loginForm.get('login_hint')?.touched
            }"
            (focus)="checkShowSuggestions()"
            (keydown)="hideSuggestions()"
          />
          <p-message
            severity="error"
            [text]="'emailFieldRequired' | translate"
            *ngIf="
              loginForm.get('login_hint')?.invalid &&
              loginForm.get('login_hint')?.touched &&
              loginForm.get('login_hint')?.errors?.required
            "
          >
          </p-message>
          <p-message
            severity="error"
            [text]="'usernameMustBeEmail' | translate"
            *ngIf="
              loginForm.get('login_hint')?.invalid &&
              loginForm.get('login_hint')?.touched &&
              loginForm.get('login_hint')?.errors?.email
            "
          >
          </p-message>
        </div>
        <button
          pButton
          type="submit"
          class="p-button-accented big-button"
          [label]="'next' | translate"
          data-test-id="loginButton"
          *ngIf="!redirecting"
        ></button>
        <div *ngIf="redirecting" class="button-loader">
          <cs-loader></cs-loader>
        </div>
        <input
          type="hidden"
          name="client_id"
          formControlName="client_id"
          value=""
        />
        <input
          type="hidden"
          name="redirect_uri"
          formControlName="redirect_uri"
          value=""
        />
        <input
          type="hidden"
          name="response_type"
          formControlName="response_type"
          value=""
        />
        <input type="hidden" name="scope" formControlName="scope" value="" />
        <input type="hidden" name="nonce" formControlName="nonce" value="" />
        <input
          type="hidden"
          name="idp_list"
          formControlName="idp_list"
          value=""
        />
        <input type="hidden" name="state" formControlName="state" value="" />
        <input type="hidden" name="lang" formControlName="lang" value="" />
        <input
          type="hidden"
          name="ui_theme"
          formControlName="ui_theme"
          value=""
        />
        <input
          type="hidden"
          name="domain_hint"
          formControlName="domain_hint"
          value=""
        />
        <input
          type="hidden"
          name="response_mode"
          formControlName="response_mode"
          value="fragment"
        />
      </form>
    </div>
  </div>
  <ng-template [ngIf]="loading">
    <cs-loader></cs-loader>
  </ng-template>

  <cs-footer></cs-footer>
</div>
<div *ngIf="loading && !autoSubmit" class="text-center vertical-center">
  <cs-loader></cs-loader>
</div>
