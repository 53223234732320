import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CsCloudAuthenticationService } from '@compusoftgroup/ngx-compusoft-cloud-authentication';

@Component({
  selector: 'cs-auth-redirector',
  templateUrl: './auth-redirector.component.html',
  styleUrls: ['./auth-redirector.component.css'],
})
export class AuthRedirectorComponent implements OnInit {
  constructor(
    private authenticationService: CsCloudAuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.navigateToRedirectUrl();
  }

  private navigateToRedirectUrl() {
    const url = this.authenticationService.getRedirectUrl();

    if (url.indexOf('#') > -1) {
      const route = url.slice(0, url.indexOf('#'));
      const fragment = url.slice(url.indexOf('#') + 1);

      this.router.navigate([route], { fragment });
    } else if (url.indexOf('?') > -1) {
      const route = url.slice(0, url.indexOf('?'));
      const paramsString = url.slice(url.indexOf('?') + 1);
      const params: any = {};
      paramsString.split('&').forEach((parts: string) => {
        if (parts && parts.length > 0) {
          const param = parts.split('=');
          if (param && param.length === 2) {
            params[decodeURIComponent(param[0])] = decodeURIComponent(param[1]);
          }
        }
      });

      this.router.navigate([route], { queryParams: params });
    } else {
      if (url.startsWith('/')) {
        this.router.navigate([url]);
      } else {
        location.href = url;
      }
    }
  }
}
