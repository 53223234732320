import { ContainerLanguageService } from './../services/container-language.service';
import { Component, OnInit } from '@angular/core';
import { CsCloudAuthenticationService } from '@compusoftgroup/ngx-compusoft-cloud-authentication';

@Component({
  template: '',
})
export class SignOutComponent implements OnInit {
  constructor(
    public authenticationService: CsCloudAuthenticationService,
    public languageService: ContainerLanguageService
  ) {}

  async ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirectUrl');
    this.authenticationService.logout(
      this.languageService.translateService.currentLang,
      redirectUrl
    );
  }
}
