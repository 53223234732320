import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { DurableProcessStatusReportImportComponent } from './../durable-process-status-report-import/durable-process-status-report-import.component';
import { DurableProcessesService } from '../../services/durable-processes.service';
import { DurableProcessStatus } from '../../models/durableProcessStatus.model';
import { DurableProcess } from '../../models/durableProcess.model';
import { Component, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DurableProcessReportAllowed } from '../../models/durableProcessReportsAllowed.model';
import { TranslateUtilsService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { CompusoftAngularUtilsService } from 'compusoft-angular-utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cs-durable-process-status-item',
  templateUrl: './durable-process-status-item.component.html',
  styleUrls: ['./durable-process-status-item.component.css'],
})
export class DurableProcessStatusItemComponent implements OnDestroy {
  @Input() public set durableProcess(durableProcess: DurableProcess) {
    this.durableProcessObject = durableProcess;
    this.checkIfProcessCompleted();
    this.allowedSucessReportButton =
      DurableProcessReportAllowed.isDurableProcessReportAllowed(
        this.durableProcessObject?.name
      );
  }

  public durableProcessStatusObject: DurableProcessStatus;
  public allowedSucessReportButton = true;
  public hidingProcess = false;
  public cancelingProcess = false;
  public durableProcessObject: DurableProcess;
  public loadingReport = false;

  private poolingInterval: NodeJS.Timer;
  private poolingIntervalTime = 10 * 1000;

  constructor(
    private durableProcessesService: DurableProcessesService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private translateUtilsService: TranslateUtilsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.stopCheckProcessStatusPooling();
  }

  public async hideDurableProcess(): Promise<void> {
    this.hidingProcess = true;
    await Promise.all([
      this.durableProcessesService.hideDurableProcesses([
        this.durableProcessObject.durableProcessId,
      ]),
      this.durableProcessesService.purgeHistory(this.durableProcessObject),
    ]);
  }

  public async tryHideDurableProcess() {
    this.confirmationService.confirm({
      header: await this.translateUtilsService.translate('attention'),
      message: await this.translateUtilsService.translate(
        'doYouReallyWantToHideThisProcess'
      ),
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDialogCancelProcess',
      accept: async () => {
        this.hideDurableProcess();
      },
      reject: () => {},
    });
  }

  public async viewReport(): Promise<void> {
    const contactImportReports = await this.translate('contactImportReports');
    const durableProcess$ = new BehaviorSubject<DurableProcess>(null);
    this.dialogService.open(DurableProcessStatusReportImportComponent, {
      header: contactImportReports,
      width: 'min(100%, 1000px)',
      height: '90vh',
      contentStyle: { height: '100%' },
      data: {
        durableProcessStatus: durableProcess$,
      },
    });

    this.loadingReport = true;
    this.durableProcessesService.currentPage = 1;
    this.durableProcessObject =
      await this.durableProcessesService.getDurableProcess(
        this.durableProcessObject.durableProcessId
      );

    await this.durableProcessesService.loadDurableProcessResultsAndErrors(
      this.durableProcessObject
    );

    durableProcess$.next(this.durableProcessObject);

    this.loadingReport = false;
  }

  public get processedEntries(): number {
    return this.durableProcessStatusObject?.customStatus?.ProcessedEntries;
  }

  public get totalEntries(): number {
    return this.durableProcessStatusObject?.customStatus?.TotalEntries;
  }

  public get name(): string {
    return this.durableProcessObject?.name;
  }

  public get stageName(): string {
    return this.durableProcessStatusObject?.customStatus?.StageName;
  }

  public get currentStage(): number {
    return this.durableProcessStatusObject?.customStatus?.CurrentStage;
  }

  public get totalStages(): number {
    return this.durableProcessStatusObject?.customStatus?.TotalStages;
  }

  public async tryCancelProcess() {
    if (this.durableProcessObject.completed) {
      return;
    }

    this.confirmationService.confirm({
      header: await this.translate('attention'),
      message: await this.translate('doYouReallyWantToCancelThisProcess'),
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDialogCancelProcess',
      accept: async () => {
        this.cancelProcess();
      },
      reject: () => {},
    });
  }

  private updateCurrentProcess() {
    const processFromList = this.durableProcessesService.processes.value.find(
      (p) => p?.durableProcessId === this.durableProcessObject?.durableProcessId
    );

    if (processFromList) {
      this.durableProcessObject = JSON.parse(JSON.stringify(processFromList));
    }
  }

  private async checkProcessStatus(statusQueryUri: string): Promise<void> {
    try {
      this.durableProcessStatusObject =
        await this.durableProcessesService.checkProcessProgress(statusQueryUri);
    } catch (error) {
      console.error(`Error code (${error.code}): ${error.message}`);
    } finally {
      this.cd.detectChanges();
    }

    if (this.durableProcessStatusObject?.completed !== true) {
      this.poolingInterval = setTimeout(() => {
        this.checkProcessStatus(statusQueryUri); // recursive call
      }, this.poolingIntervalTime);
    } else if (this.durableProcessStatusObject?.runtimeStatus === 'Completed') {
      this.updateCurrentProcess();

      this.durableProcessesService.launchToastEvents(
        CompusoftAngularUtilsService.capitalize(
          this.translateService.instant(this.durableProcessStatusObject?.name)
        ),
        'completed',
        null,
        this.durableProcessStatusObject.instanceId
      );
      this.durableProcessesService.onDurableProcessCompleteActions(
        this.durableProcessStatusObject
      );
    }

    this.cd.detectChanges();
  }

  private async cancelProcess() {
    this.cancelingProcess = true;
    await this.durableProcessesService.cancelProcess(this.durableProcessObject);
  }

  private stopCheckProcessStatusPooling(): void {
    clearTimeout(this.poolingInterval);
  }

  private checkIfProcessCompleted() {
    if (!this.durableProcessObject) {
      return;
    }

    if (this.durableProcessObject.completed) {
      /* eslint-disable @typescript-eslint/naming-convention*/
      this.durableProcessStatusObject = {
        instanceId: this.durableProcessObject.durableProcessId,
        name: this.durableProcessObject.name,
        input: this.durableProcessObject.input,
        output: this.durableProcessObject.output,
        runtimeStatus: this.durableProcessObject.status ?? 'Completed',
        createdTime: this.durableProcessObject.startedAt,
        lastUpdatedTime: this.durableProcessObject.completedAt,
        customStatus: {
          LastItem: null,
          ProcessedEntries: 1,
          TotalEntries: 1,
          Progress: 100,
          StageName: null,
        },
        completed: true,
      };
      /* eslint-enable @typescript-eslint/naming-convention*/

      this.allowedSucessReportButton =
        DurableProcessReportAllowed.isDurableProcessReportAllowed(
          this.durableProcessStatusObject?.name
        );
    } else {
      this.startCheckProcessStatusPooling();
    }
  }

  private async startCheckProcessStatusPooling(): Promise<void> {
    this.checkProcessStatus(
      this.durableProcessObject?.controlUrls?.statusQueryGetUri
    );
  }

  private async translate(value: string) {
    return await this.translateService.get(value).toPromise();
  }
}
