<div
  #multiupload
  class="documents-multi-upload"
  id="multiupload"
  [class.hidden]="
    menuHidden ||
    (!documentsMultipleUploadService.documentsMultipleUploadSuccess.length &&
      !documentsMultipleUploadService.documentsMultipleUploadFailed.length &&
      !documentsMultipleUpload.length)
  "
  data-test-id="componentDocumentsMultiUpload"
>
  <div class="details">
    <div class="details-uploading">
      <div class="spinner" *ngIf="documentsMultipleUpload.length > 0">
        <div class="dots">
          <cy-icon
            [icon]="'cy_C_S_arrows_2_arrows_arrows_upload'"
            [size]="'S'"
            class="uplodad_icon"
          ></cy-icon>
        </div>
      </div>
      <div>
        <div class="text" *ngIf="documentsMultipleUpload.length > 0">
          {{ documentsMultipleUpload[0]?.file?.name }}
        </div>
        <div class="text" *ngIf="documentsMultipleUpload.length === 0">
          <span
            class="upload-feedback"
            *ngIf="
              documentsMultipleUploadService.documentsMultipleUploadSuccess
                .length > 0
            "
          >
            <cy-icon
              [icon]="'cy_C_S_validation_global_validation_checkmark'"
              [size]="'S'"
              class="success-light"
            ></cy-icon>
            ({{
              documentsMultipleUploadService.documentsMultipleUploadSuccess
                .length
            }})
          </span>
          <span
            class="upload-feedback"
            *ngIf="
              documentsMultipleUploadService.documentsMultipleUploadFailed
                .length > 0
            "
          >
            <i
              [icon]="'cy_C_S_validation_global_validation_error'"
              [size]="'S'"
              class="failed-light"
            ></i>
            ({{
              documentsMultipleUploadService.documentsMultipleUploadFailed
                .length
            }})
          </span>
        </div>
      </div>
      <div class="acctions-menu">
        <i
          [class.cs-S-ArrowD]="menuOpen"
          [class.cs-S-ArrowU]="!menuOpen"
          class="action-icons"
          (click)="toggleMenu()"
          data-test-id="buttonToggleMenu"
        ></i>
        <cy-icon
          [icon]="'cy_C_S_validation_global_validation_cross'"
          [size]="'S'"
          class="action-icons"
          (click)="clearData()"
          data-test-id="buttonClearData"
        ></cy-icon>
      </div>
    </div>
  </div>
  <div class="menu" [class.menu-open]="menuOpen">
    <div *ngFor="let document of documentsMultipleUpload; let i = index">
      <div class="sphere-container">
        <div class="dots" *ngIf="i !== 0">
          <div class="dot dot_1"></div>
          <div class="dot dot_2"></div>
          <div class="dot dot_3"></div>
        </div>
        <div class="sphere" *ngIf="i === 0">
          <div class="dots">
            <cy-icon
              [icon]="'cy_C_S_arrows_2_arrows_arrows_upload'"
              [size]="'S'"
              class="uplodad_icon"
            ></cy-icon>
          </div>
        </div>
      </div>
      <div class="text">
        {{ document?.file?.name }}
      </div>
    </div>
    <div
      *ngFor="
        let documentsSuccess of documentsMultipleUploadService.documentsMultipleUploadSuccess
      "
    >
      <div class="sphere-container">
        <div>
          <cy-icon
            [icon]="'cy_C_S_validation_global_validation_checkmark'"
            [size]="'S'"
            class="success"
          ></cy-icon>
        </div>
      </div>
      <div class="text">
        {{ documentsSuccess?.file?.name }}
      </div>
      <div
        class="menu-action-button"
        (click)="removeSuccessDocument(documentsSuccess)"
        data-test-id="buttonRemoveDocument"
      >
        <cy-icon
          [icon]="'cy_C_S_validation_global_validation_cross'"
          [size]="'S'"
        ></cy-icon>
      </div>
    </div>
    <div
      *ngFor="
        let documentsFailed of documentsMultipleUploadService.documentsMultipleUploadFailed
      "
    >
      <div class="sphere-container">
        <cy-icon
          [icon]="'cy_C_S_validation_global_validation_error'"
          [size]="'S'"
          class="failed"
          [title]="documentsFailed?.message"
        ></cy-icon>
      </div>
      <div class="text">
        {{ documentsFailed?.file?.name }}
      </div>
      <div
        class="menu-action-button"
        (click)="retryDocument(documentsFailed)"
        data-test-id="buttonRetryDocument"
      >
        <cy-icon
          [icon]="
            'cy_C_S_sync_and_refresh_arrows_sync_and_refresh_control_sync'
          "
          [size]="'S'"
          class="retry-upload"
          title="retry"
        ></cy-icon>
      </div>
      <div
        class="menu-action-button"
        (click)="removeFailedDocument(documentsFailed)"
        data-test-id="buttonRemoveFailedDocument"
      >
        <cy-icon
          [icon]="'cy_C_S_validation_global_validation_cross'"
          [size]="'S'"
        ></cy-icon>
      </div>
    </div>
  </div>
</div>
