import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from '../../environments/environment.service';
import {
  CompanyService,
  ShopService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { FeedbackModel } from '../models/feedback.model';
@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    private enviromentService: EnvironmentService,
    private http: HttpClient,
    private companyService: CompanyService,
    private shopService: ShopService
  ) {}

  public async sendFeedbackEmail(body: FeedbackModel): Promise<any> {
    const companyGuid = this.companyService.getCurrentCompanyGuid();
    const shopGuid = this.shopService.getCurrentShopGuid();
    const params = {
      companyGuid,
      shopGuid,
    };

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return await this.http
      .post(
        `${this.enviromentService.shopsApiBaseUrl}/SendFeedbackEmail`,
        body,
        { params, headers }
      )
      .toPromise();
  }
}
