import { Injectable } from '@angular/core';
import { environment } from './environment';
import { IEnvironment } from './ienvironment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironment {
  public get name() {
    return environment.name;
  }

  public get production() {
    return environment.production;
  }

  public get authenticationBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].authenticationBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .authenticationBaseUrl;
    } else {
      return environment.authenticationBaseUrl;
    }
  }

  public get authenticationClientID() {
    return environment.authenticationClientID;
  }

  public get authenticationResponseType() {
    return environment.authenticationResponseType;
  }

  public get authenticationScope() {
    return environment.authenticationScope;
  }

  public get uiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].uiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname].uiBaseUrl;
    } else {
      return environment.uiBaseUrl;
    }
  }

  public get globalApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].globalApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname].globalApiBaseUrl;
    } else {
      return environment.globalApiBaseUrl;
    }
  }

  public get customerPortalApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].customerPortalApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .customerPortalApiBaseUrl;
    } else {
      return environment.customerPortalApiBaseUrl;
    }
  }

  public get flexUIBFFApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].flexUIBFFApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .flexUIBFFApiBaseUrl;
    } else {
      return environment.flexUIBFFApiBaseUrl;
    }
  }

  public get shopsApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].shopsApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname].shopsApiBaseUrl;
    } else {
      return environment.shopsApiBaseUrl;
    }
  }

  public get projectsApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].projectsApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .projectsApiBaseUrl;
    } else {
      return environment.projectsApiBaseUrl;
    }
  }

  public get quotesApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].quotesApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname].quotesApiBaseUrl;
    } else {
      return environment.quotesApiBaseUrl;
    }
  }

  public get contactsApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].contactsApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .contactsApiBaseUrl;
    } else {
      return environment.contactsApiBaseUrl;
    }
  }

  public get documentsApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].documentsApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .documentsApiBaseUrl;
    } else {
      return environment.documentsApiBaseUrl;
    }
  }

  public get userManagementApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].userManagementApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .userManagementApiBaseUrl;
    } else {
      return environment.userManagementApiBaseUrl;
    }
  }

  public get componentBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].componentBaseUrl
    ) {
      return environment.hostnames[document.location.hostname].componentBaseUrl;
    } else {
      return environment.componentBaseUrl;
    }
  }

  public get identityServerApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].identityServerApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .identityServerApiBaseUrl;
    } else {
      return environment.identityServerApiBaseUrl;
    }
  }

  public get textsApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].textsApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname].textsApiBaseUrl;
    } else {
      return environment.textsApiBaseUrl;
    }
  }

  public get namelibApiUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].namelibApiUrl
    ) {
      return environment.hostnames[document.location.hostname].namelibApiUrl;
    } else {
      return environment.namelibApiUrl;
    }
  }

  public get standardTextsApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].standardTextsApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .standardTextsApiBaseUrl;
    } else {
      return environment.standardTextsApiBaseUrl;
    }
  }

  public get featureFlagsApiUrl() {
    return environment.featureFlagsApiUrl;
  }

  public get importExportApiBaseUrl() {
    if (
      environment.hostnames &&
      environment.hostnames[document.location.hostname] &&
      environment.hostnames[document.location.hostname].importExportApiBaseUrl
    ) {
      return environment.hostnames[document.location.hostname]
        .importExportApiBaseUrl;
    } else {
      return environment.importExportApiBaseUrl;
    }
  }

  public get clientApplicationIdentifier() {
    return environment.clientApplicationIdentifier;
  }

  public get featureFlagProjectIdentifier() {
    return environment.featureFlagProjectIdentifier;
  }

  public get projectEnvironmentIdentifier() {
    return environment.projectEnvironmentIdentifier;
  }

  public get textLibrary() {
    return environment.textLibrary;
  }

  public get standardTextLibrary() {
    return environment.standardTextLibrary;
  }

  public get componentID() {
    return environment.componentID;
  }

  public get apis() {
    return environment.apis;
  }

  public get languagesBlacklist() {
    return environment.languagesBlacklist;
  }

  public get hostnames() {
    return environment.hostnames;
  }

  public get environmentURLS(): IEnvironment {
    return {
      name: this.name,
      production: this.production,
      authenticationBaseUrl: this.authenticationBaseUrl,
      authenticationClientID: this.authenticationClientID,
      authenticationResponseType: this.authenticationResponseType,
      authenticationScope: this.authenticationScope,
      uiBaseUrl: this.uiBaseUrl,
      globalApiBaseUrl: this.globalApiBaseUrl,
      customerPortalApiBaseUrl: this.customerPortalApiBaseUrl,
      shopsApiBaseUrl: this.shopsApiBaseUrl,
      projectsApiBaseUrl: this.projectsApiBaseUrl,
      quotesApiBaseUrl: this.quotesApiBaseUrl,
      contactsApiBaseUrl: this.contactsApiBaseUrl,
      documentsApiBaseUrl: this.documentsApiBaseUrl,
      userManagementApiBaseUrl: this.userManagementApiBaseUrl,
      componentBaseUrl: this.componentBaseUrl,
      identityServerApiBaseUrl: this.identityServerApiBaseUrl,
      textsApiBaseUrl: this.textsApiBaseUrl,
      namelibApiUrl: this.namelibApiUrl,
      standardTextsApiBaseUrl: this.standardTextsApiBaseUrl,
      featureFlagsApiUrl: this.featureFlagsApiUrl,
      importExportApiBaseUrl: this.importExportApiBaseUrl,
      clientApplicationIdentifier: this.clientApplicationIdentifier,
      featureFlagProjectIdentifier: this.featureFlagProjectIdentifier,
      projectEnvironmentIdentifier: this.projectEnvironmentIdentifier,
      textLibrary: this.textLibrary,
      standardTextLibrary: this.standardTextLibrary,
      componentID: this.componentID,
      apis: this.apis,
      languagesBlacklist: this.languagesBlacklist,
      hostnames: this.hostnames,
      flexUIBFFApiBaseUrl: this.flexUIBFFApiBaseUrl,
    };
  }

  constructor() {}
}
