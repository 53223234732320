<cs-offline-dialog *ngIf="noInternetConnectionFF"></cs-offline-dialog>
<router-outlet *ngIf="!loading"></router-outlet>
<div class="cs-loading" *ngIf="loading">
  <cs-progress-circular></cs-progress-circular>
</div>
<p-toast position="bottom-right">
  <ng-template let-message pTemplate="message">
    <cy-toast-template [message]="message"></cy-toast-template>
  </ng-template>
</p-toast>
