import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  name: 'dev',
  production: true,
  authenticationBaseUrl: 'https://dev-login.cyncly.com',
  authenticationClientID: 'CompusoftContainerCF',
  authenticationResponseType: 'id_token token',
  authenticationScope: 'openid profile email CloudContainer.USER',
  uiBaseUrl: 'https://dev.flex.cloud',
  globalApiBaseUrl: 'https://dev.flex.cloud',
  customerPortalApiBaseUrl: 'https://dev.flex.cloud/api/customerportal',
  flexUIBFFApiBaseUrl: 'https://dev.flex.cloud/api/flexuibff',
  shopsApiBaseUrl: 'https://dev.flex.cloud/api/shops',
  projectsApiBaseUrl: 'https://dev.flex.cloud/api/projects',
  quotesApiBaseUrl: 'https://dev.flex.cloud/api/quote',
  documentsApiBaseUrl: 'https://dev.flex.cloud/api/documents',
  contactsApiBaseUrl: 'https://dev.flex.cloud/api/contacts',
  userManagementApiBaseUrl: 'https://dev-login.cyncly.com/api/usermanagement',
  componentBaseUrl: './components/',
  identityServerApiBaseUrl: 'https://dev-login.cyncly.com',
  textsApiBaseUrl:
    'https://dev.flex.cloud/api/resources/namelib/getdictionary?&asObject=1',
  namelibApiUrl: 'https://dev.flex.cloud/api/resources/namelib',
  standardTextsApiBaseUrl:
    'https://dev.flex.cloud/api/resources/standardtexts/getcategory',
  featureFlagsApiUrl:
    'https://live-flex-eu.compusoftgroup.com/api/featureflags/ListFeatureFlagsStatus', // Same for all environments
  importExportApiBaseUrl: 'https://dev.flex.cloud/api/importexport',
  clientApplicationIdentifier: 'FlexUI',
  featureFlagProjectIdentifier: 'Flex',
  projectEnvironmentIdentifier: 'dev',
  textLibrary: '900',
  standardTextLibrary: '900',
  componentID: 'container',
  apis: {
    projects: '/api/projects',
    contacts: '/api/contacts',
    documents: '/api/documents',
    reports: '/api/reports-devexpress',
  },
  languagesBlacklist: [],
  hostnames: {},
  launchDarklyClientSideID: '6601739d4843c80fa4a512e5',
};
