<div class="durable-process-menu" data-test-id="componentDurableProcessMenu">
  <div
    #durableProcessMenuButton
    [class.hidden]="totalDurableProcesses === 0"
    class="durable-process-menu-button"
    [title]="'backgroundProcesses' | translate"
    (click)="durableProcessesService.toggleDurableFunctionsList()"
    data-test-id="buttonToggleDurableProcessMenu"
  >
    <div class="total-durable-processes">
      {{ totalDurableProcesses }}
    </div>
    <cs-durable-processes-loader
      [processesCompleted]="areAllProcessesCompleted"
    ></cs-durable-processes-loader>
  </div>

  <div
    #durableProcessMenuContainer
    class="durable-process-menu-container"
    [class.opened]="durableProcessesService.isDurableFunctionsListShowing"
  >
    <cs-durable-process-list></cs-durable-process-list>
  </div>
</div>
