import { DurableProcessesService } from './../../../services/durable-processes.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, Input } from '@angular/core';
import { DurableProcessOutput } from 'src/app/durable-process/models/durableProcess.model';
import { TranslateUtilsService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';

@Component({
  selector: 'cs-durable-process-status-report-import-contacts-list',
  templateUrl:
    './durable-process-status-report-import-contacts-list.component.html',
  styleUrls: [
    './durable-process-status-report-import-contacts-list.component.css',
  ],
})
export class DurableProcessStatusReportImportContactsListComponent {
  @Input() public set durableProcessStatusResult(
    durableProcessStatusResult: DurableProcessOutput
  ) {
    if (!durableProcessStatusResult) {
      return;
    }
    this.generateImportedContactsList(durableProcessStatusResult);
    this.onRowSelect(this.importedContacts?.[0]);
  }

  public importedContacts = [];
  public selectedContact;

  constructor(
    private ref: DynamicDialogRef,
    private durableProcessService: DurableProcessesService,
    private translateUtilsService: TranslateUtilsService
  ) {}

  public onRowSelect(event) {
    if (!event) {
      return;
    }

    this.selectedContact = event;
  }

  public navigateToContact(url: string) {
    if (!url) {
      return;
    }
    window.location.href = `#/contacts/${url}`;
    this.ref.close();
  }

  public goToPage(event: any) {
    this.durableProcessService.goToPage(event.page + 1);
  }

  private generateImportedContactsList(
    durableProcessStatusResult: DurableProcessOutput
  ) {
    this.importedContacts = durableProcessStatusResult.ImportedContacts.concat(
      durableProcessStatusResult.ImportedWithWarningContacts
    );

    if (!this.importedContacts) {
      return;
    }

    this.importedContacts.forEach(async (contact) => {
      if (contact?.Warnings?.length) {
        for (let i = 0; i < contact.Warnings.length; i++) {
          contact.Warnings[i] = await this.translateUtilsService.translate(
            contact.Warnings[i]
          );
        }
      }

      if (contact?.Errors?.length) {
        for (let i = 0; i < contact.Errors.length; i++) {
          contact.Errors[i] = await this.translateUtilsService.translate(
            contact.Errors[i]
          );
        }
      }

      if (contact?.Documents?.length) {
        for (const document of contact.Documents) {
          if (document?.Errors?.length) {
            for (let i = 0; i < document.Errors.length; i++) {
              document.Errors[i] = await this.translateUtilsService.translate(
                document.Errors[i]
              );
            }
          }

          if (document?.Warnings?.length) {
            for (let i = 0; i < document.Warnings.length; i++) {
              document.Warnings[i] = await this.translateUtilsService.translate(
                document.Warnings[i]
              );
            }
          }
        }
      }
    });
  }
}
