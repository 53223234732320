<div
  *ngIf="durableProcess?.output; else loadingReport"
  class="report-container"
  data-test-id="componentDurableProcessStatusReportImport"
>
  <div class="summary">
    <!-- <div
      class="summary-item success"
      *ngIf="durableProcess?.output?.ImportedContacts?.length"
    >
      <span class="summary-item-value">{{
        durableProcess?.output?.ImportedContacts?.length
      }}</span>
      <span class="summary-item-label">{{
        'contactsImportedCorrectly' | translate
      }}</span>
    </div>
    <div
      class="summary-item warning"
      *ngIf="durableProcess?.output?.ImportedWithWarningContacts?.length"
    >
      <span class="summary-item-value">{{
        durableProcess?.output?.ImportedWithWarningContacts?.length
      }}</span>
      <span class="summary-item-label">{{
        'contactsImportedWithWarning' | translate
      }}</span>
    </div> -->
    <div
      class="summary-item"
      *ngIf="amountOfContactsToImport"
    >
      <span class="summary-item-value">{{
        amountOfContactsToImport
      }}</span>
      <span class="summary-item-label">
        {{ 'totalProcessedItems' | translate }}</span
      >
    </div>
    <div class="summary-item error" *ngIf="errorsList?.length">
      <span class="summary-item-value">{{ errorsList?.length }}</span>
      <span class="summary-item-label">{{ 'errors' | translate }}</span>
    </div>
  </div>

  <nav>
    <div class="tab-container" *ngIf="hasImportedContacts">
      <span
        class="tab"
        [class.active]="tabActive === 'contacts'"
        (click)="changeTab('contacts')"
        data-test-id="tabContacts"
        >{{ 'Contacts' | translate }}</span
      >
    </div>
    <div
      class="tab-container"
      *ngIf="errorsList?.length"
      (click)="changeTab('errors')"
      data-test-id="tabErrors"
    >
      <span class="tab" [class.active]="tabActive === 'errors'">{{
        'errors' | translate
      }}</span>
      <span class="errors-tab-count">({{ errorsList.length }})</span>
    </div>
  </nav>

  <div class="import-contacts-report">
    <div class="content">
      <section
        class="list-container"
        *ngIf="tabActive === 'contacts'"
        [@inOutAnimation]
      >
        <cs-durable-process-status-report-import-contacts-list
          [durableProcessStatusResult]="durableProcess?.output"
        ></cs-durable-process-status-report-import-contacts-list>
      </section>
      <section
        class="error-container"
        *ngIf="tabActive === 'errors'"
        [@outInAnimation]
      >
        <cs-durable-process-status-report-error
          [errors]="errorsList"
        ></cs-durable-process-status-report-error>
      </section>
    </div>

    <footer>
      <!-- <button
      *ngIf="tabActive === 'errors' && errorsList?.length"
      pButton
      class="ui-button-danger"
      (click)="onClickRetryFailedContacts()"
    >
      {{ 'retryFailedContacts' | translate }}
    </button> -->
      <button
        pButton
        (click)="onClickClose()"
        [label]="'close' | translate | titlecase"
        data-test-id="buttonClose"
      ></button>
    </footer>
  </div>
</div>
<ng-template #loadingReport>
  <div class="cs-loading absolute-center">
    <cs-progress-circular></cs-progress-circular>
  </div>
</ng-template>
