<header>
  <a
    data-test-id="buttonShowLanguageSelector"
    class="language-button"
    (click)="showLanguageSelector()"
  >
    <cy-icon
      [icon]="'cy_C_S_global_2_technology_global_global'"
      [size]="'S'"
    ></cy-icon>
    <span>{{ selectedLanguageName }}</span>
  </a>
</header>
<div class="content" data-test-id="componentPasswordRecovery">
  <div class="register">
    <div class="logo">
      <img src="assets/logos/logo.svg" alt="Cyncly" />
    </div>
    <div *ngIf="!loading && !registrationFinished">
      <div class="heading">
        <h2>{{ 'createAccount' | translate }}</h2>
        <h4>{{ headerText }}</h4>
      </div>
      <form [formGroup]="form" class="form">
        <div class="form">
          <div>
            <label class="input-element">{{ 'password' | translate }}</label>
            <input
              #password
              pInputText
              cy-password
              data-test-id="password"
              formControlName="password"
              class="input-element"
              type="password"
              maxlength="100"
              [placeholder]="'enterAPassword' | translate"
              (keyup)="checkPasswordLetters(password.value)"
              (focus)="focusOnPassword = true"
              (blur)="focusOnPassword = false"
            />
          </div>
          <div>
            <label class="input-element">{{
              'confirmPassword' | translate
            }}</label>
            <input
              pInputText
              cy-password
              data-test-id="confirmPassword"
              formControlName="confirmPassword"
              class="input-element"
              type="password"
              maxlength="100"
              [placeholder]="'confirmYourPassword' | translate"
              (keyup)="checkPasswords()"
            />
          </div>
        </div>
        <div class="messages-container">
          <p-message
            *ngIf="focusOnPassword && !passwordIsValid"
            severity="info"
            [text]="'cloud_password_requirements' | translate"
          >
          </p-message>
          <p-message
            *ngIf="
              passwordsDoNotMatch &&
              form.controls['password'].valid &&
              form.controls['confirmPassword'].dirty
            "
            severity="error"
            [text]="'passwordNotMatch' | translate"
          >
          </p-message>
        </div>
      </form>
      <div class="centered buttons-container">
        <button
          pButton
          data-test-id="buttonRegister"
          class="p-button-accented min-width-200"
          [label]="'setPassword' | translate"
          [disabled]="!form.valid || passwordsDoNotMatch"
          (click)="registerNewUser()"
        ></button>
      </div>
    </div>
    <div *ngIf="registrationFinished">
      <div class="heading">
        <h2>{{ 'userRegisteredCorrectly' | translate }}</h2>
      </div>
      <cs-loader-with-text
        text="{{ 'redirecting' | translate }}..."
      ></cs-loader-with-text>
    </div>
    <ng-template [ngIf]="loading">
      <cs-loader-with-text></cs-loader-with-text>
    </ng-template>
  </div>
  <cs-footer></cs-footer>
</div>
<p-dialog
  header="{{ 'chooseYourLanguage' | translate }}"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '350px', minWidth: '200px' }"
  [contentStyle]="{ overflow: 'visible' }"
  [(visible)]="languageSelectorVisible"
>
  <p-dropdown
    tzeDropdownFix
    [options]="languagesMenu"
    [filter]="languagesMenu | showFilterSearch"
    [(ngModel)]="selectedLanguage"
    (onChange)="onChangeLanguage($event)"
  >
  </p-dropdown>
</p-dialog>
