import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CloudComponentService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';

@Component({
  selector: 'cs-public-container',
  templateUrl: './public-container.component.html',
  styleUrls: ['./public-container.component.css'],
})
export class PublicContainerComponent {
  constructor(
    public cloudComponentService: CloudComponentService,
    public route: ActivatedRoute
  ) {}
}
