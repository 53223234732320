<div
  class="durable-process-result-list"
  *ngIf="importedContacts?.length"
  data-test-id="componentDurableProcessStatusReportImportContactsList"
>
  <div class="contact-list">
    <div class="items">
      <div
        class="contact"
        [class.selected]="
          selectedContact?.ContactID?.contactGuid ===
          contact?.ContactID?.contactGuid
        "
        *ngFor="let contact of importedContacts"
        (click)="onRowSelect(contact)"
        data-test-id="buttonContact"
      >
        <cy-icon
          [icon]="'cy_C_S_validation_global_validation_warning'"
          [size]="'S'"
          class="contact-icon-status"
          [title]="
            ('warnings' | translate) + ': ' + contact?.Warnings?.join(',')
          "
          *ngIf="contact?.Warnings?.length"
        ></cy-icon>
        <span class="contact-name">
          {{ contact?.ContactID?.contactName }} ({{
            contact?.ContactID?.contactNumber
          }})
        </span>
      </div>
    </div>
    <div
      class="paginator"
      *ngIf="
        durableProcessService?.totalNumberOfRecords >
        durableProcessService?.pageSize
      "
      [class.paginator-disabled]="
        durableProcessService.loadingDurableProcessOutput$ | async
      "
    >
      <p-paginator
        [rows]="durableProcessService.pageSize"
        [totalRecords]="durableProcessService.totalNumberOfRecords"
        (onPageChange)="goToPage($event)"
      ></p-paginator>
    </div>
  </div>

  <div class="contact-details">
    <div class="contact-details-header">
      <div class="contact-details-header-title">
        {{ 'detailView' | translate }}:
      </div>
      <div *ngIf="selectedContact?.ContactID">
        {{ 'contact' | translate }}:
        <div
          class="contact-name"
          [class.contact-link]="selectedContact?.ContactID?.contactGuid"
          (click)="navigateToContact(selectedContact?.ContactID?.contactGuid)"
          data-test-id="buttonContactDetailsInfo"
        >
          {{ selectedContact.ContactID.contactName }}
          <span *ngIf="selectedContact?.ContactID?.contactNumber">
            ({{ selectedContact.ContactID.contactNumber }})
          </span>
        </div>

        <div>
          {{ 'externalRef' | translate }}:
          {{ selectedContact.ContactID.externalReference }}
        </div>
      </div>
      <div>
        <b>{{ 'status' | translate }}:</b>
        <span
          *ngIf="
            selectedContact?.Warnings?.length === 0 &&
            selectedContact?.Errors?.length === 0
          "
          class="contact-details-success success"
        >
          {{ 'contactImportSuccess' | translate }}
        </span>
        <div *ngIf="selectedContact?.Warnings?.length">
          <div
            class="contact-details-warnings"
            *ngFor="let warning of selectedContact?.Warnings"
          >
            {{ warning }}
          </div>
        </div>

        <div *ngIf="selectedContact?.Errors?.length">
          <div
            class="contact-details-errors"
            *ngFor="let error of selectedContact?.Errors"
          >
            {{ error }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="contact-details-body"
      *ngIf="selectedContact?.Documents?.length"
    >
      <div class="contact-details-body-title">
        {{ 'Documents' | translate }}:
      </div>
      <div
        class="contact-document"
        *ngFor="let documentData of selectedContact?.Documents"
      >
        <cy-icon
          [icon]="'cy_C_S_validation_global_validation_checkmark'"
          [size]="'S'"
          class="success"
          *ngIf="
            documentData?.Errors?.length === 0 &&
            documentData?.Warnings?.length === 0
          "
        ></cy-icon>
        {{ documentData?.Document?.name }}
        <div class="document-errors" *ngIf="documentData?.Errors?.length">
          {{ documentData?.Errors?.join(',') }}
        </div>
        <div class="document-warnings" *ngIf="documentData?.Warnings?.length">
          {{ documentData?.Warnings?.join(',') }}
        </div>
      </div>
    </div>
  </div>
</div>
