import { Component, OnInit } from '@angular/core';
import { ExternalService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';

@Component({
  selector: 'cs-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent implements OnInit {
  public isHostedEnvironment: boolean;

  constructor(private externalService: ExternalService) {}

  async ngOnInit() {
    this.isHostedEnvironment = await this.externalService.isHostedEnvironment();
  }

  public goBack() {
    window.history.back();
  }
}
