<div
  #durableProcessList
  class="durable-process-list"
  data-test-id="componentDurableProcessList"
>
  <div
    class="durable-process-list-header"
    *ngIf="completedProcesses?.length > 0"
  >
    <div
      class="clear-all-completed-process element-link"
      (click)="tryClearAllCompletedProcesses()"
      data-test-id="buttonClearAllCompletedProcesses"
    >
      {{ 'clearAllCompletedProcesses' | translate | csCapitalize }}
    </div>
  </div>
  <div class="durable-process-list-content">
    <div
      *ngFor="let process of durableProcessListData"
      [class.hidden]="!process.visible"
    >
      <cs-durable-process-status-item [durableProcess]="process">
      </cs-durable-process-status-item>
    </div>
  </div>
</div>
