<div
  class="durable-process-status-error-container"
  data-test-id="componentDurableProcessStatusReport"
>
  <div class="error-item" *ngFor="let error of errors">
    <cy-icon
      [icon]="'cy_C_S_validation_global_validation_error'"
      [size]="'S'"
    ></cy-icon>
    <span>{{ error | translate }}</span>
  </div>
</div>
