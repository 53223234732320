import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from './../../environments/environment.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompusoftThemesService } from '@compusoftgroup/compusoft-themes';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { ContainerLanguageService } from '../services/container-language.service';
import { LocalUserPreferencesService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';
@Component({
  selector: 'cs-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css'],
})
export class PasswordRecoveryComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public loadingTheme = true;
  public currentTheme = 'default';
  public languagesMenu: SelectItem[] = [];
  public selectedLanguage = '';
  public selectedLanguageName = '';
  public languageSelectorVisible = false;
  public changePasswordTextLib: any;
  public defaultRedirectUrl: string;
  public defaultEmail = '';

  private themeSubscription: Subscription = null;

  constructor(
    public translateService: TranslateService,
    private themesService: CompusoftThemesService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    public languageService: ContainerLanguageService,
    public localUserPreferencesService: LocalUserPreferencesService,
    public environmentService: EnvironmentService
  ) {
    this.defaultRedirectUrl = this.environmentService.uiBaseUrl;
  }

  async ngOnInit() {
    this.readQueryParameters();
    this.loadLanguagesMenu();
    this.initThemeChangeListener();
    await this.translate('anything'); // this line is required for the system to load the translations used in the next line //TODO research how to improve this
    this.changePasswordTextLib =
      this.translateService.translations[this.translateService.currentLang];
  }

  ngAfterViewInit(): void {
    this.currentTheme = this.themesService.getActiveThemeName();
  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }

  public showLanguageSelector() {
    this.languageSelectorVisible = true;
  }

  public async onChangeLanguage(event) {
    if (event.value) {
      await this.languageService.setLanguage(event.value);
      this.selectedLanguageName =
        this.languageService.languagesNames[
          this.selectedLanguage.toLowerCase()
        ];
      this.languageService.removeUrlLanguageParamAndReload();
    }
    setTimeout(() => {
      location.reload();
    }, 10);
  }

  private readQueryParameters() {
    this.defaultEmail = this.route.snapshot.queryParamMap.get('address');
  }

  private loadLanguagesMenu() {
    this.languagesMenu = [];

    const languages = this.languageService.translateService.getLangs();

    for (const lang of languages) {
      this.languagesMenu.push({
        label: this.languageService.languagesNames[lang.toLowerCase()],
        value: lang,
      });
    }

    this.selectedLanguage = this.languageService.translateService.currentLang;
    this.selectedLanguageName =
      this.languageService.languagesNames[this.selectedLanguage.toLowerCase()];
  }

  private initThemeChangeListener() {
    const activeTheme = this.themesService.getActiveThemeName();

    if (activeTheme) {
      this.loadingTheme = false;
    }
    this.themeSubscription = this.themesService
      .getThemeChangeObservable()
      .subscribe(() => {
        this.currentTheme = this.themesService.getActiveThemeName();
        this.cd.detectChanges();
        this.loadingTheme = false;
      });

    setTimeout(() => {
      if (!this.loadingTheme) {
        return;
      }

      this.themesService.setTheme('winner-design');
      this.currentTheme = this.themesService.getActiveThemeName();
      this.cd.detectChanges();
      this.loadingTheme = false;
    }, 3000);
  }

  private async translate(value: string) {
    return await this.translateService.get(value).toPromise();
  }

  private hideLanguageSelector() {
    this.languageSelectorVisible = false;
  }
}
