<header>
  <div
    id="header-settings"
    class="user-menu-container"
    data-test-id="multiLineMenu"
  >
    <cs-multi-line-menu
      [icon]="icon.user"
      [textColor]="'dark'"
      [styleButton]="{ 'background-color': 'transparent' }"
      [styleMenu]="{ 'min-width': '250px' }"
      class="line-height-18"
      [positionRight]="true"
      [model]="userMenuItems"
      [label]="labelName"
    ></cs-multi-line-menu>
  </div>
</header>
<div *ngIf="!loadingTheme" class="content">
  <div class="chooser-container" *ngIf="!loading">
    <div class="logo">
      <img src="../../assets/images/logo.svg" class="logo" />
    </div>
    <cs-shop-selector
      *ngIf="!ffFEA92653"
      [initialCompanyGuid]="companyGuid"
      [initialShopGuid]="shopGuid"
      [ignoreMissingCompany]="ignoreMissingCompany"
      [featureFlags]="companyChooserFeatureFlags"
    >
    </cs-shop-selector>

    <cs-cy-shop-selector
      *ngIf="ffFEA92653"
      [initialCompanyGuid]="companyGuid"
      [initialShopGuid]="shopGuid"
      [ignoreMissingCompany]="ignoreMissingCompany"
      [featureFlags]="companyChooserFeatureFlags"
      (companyChanged)="onCompanyChanged()"
    >
    </cs-cy-shop-selector>
  </div>

  <ng-template [ngIf]="loading">
    <cs-loader-with-text></cs-loader-with-text>
  </ng-template>

  <cs-footer></cs-footer>
</div>

<div *ngIf="loadingTheme" class="text-center vertical-center">
  <cs-loader-with-text></cs-loader-with-text>
</div>

<p-dialog
  header="{{ 'chooseYourLanguage' | translate }}"
  [(visible)]="languageSelectorVisible"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '350px', minWidth: '200px' }"
  [contentStyle]="{ overflow: 'visible' }"
  data-test-id="dialogLanguageSelector"
>
  <p-dropdown
    tzeDropdownFix
    [options]="languagesMenu"
    [(ngModel)]="selectedLanguage"
    (onChange)="onChangeLanguage($event)"
    [filter]="true"
  >
  </p-dropdown>
</p-dialog>

<p-confirmDialog
  #signoutConfirmDialog
  key="signoutConfirmDialog"
  [style]="{ width: '50vw', 'min-width': '250px' }"
>
  <p-footer>
    <div class="dialog-buttons modal-footer-buttons">
      <p-button
        [label]="'cancel' | translate"
        (click)="signoutConfirmDialog.reject()"
        data-test-id="buttonCancel"
      ></p-button>
      <p-button
        styleClass="p-button-accented"
        [label]="'signOut' | translate"
        (click)="signoutConfirmDialog.accept()"
        data-test-id="buttonSignOut"
      ></p-button>
    </div>
  </p-footer>
</p-confirmDialog>
