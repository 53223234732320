export class SearchResultElement {
  public name: string;
  public guid: string;
  public type: string;
  public query: string;
  public componentID: string;

  constructor(name: string, guid: string, componentID?: string) {
    this.name = name;
    this.guid = guid;
    this.componentID = componentID;
  }

  public getName(): string {
    return this.name;
  }

  public getGuid(): string {
    return this.guid;
  }
}
