<div
  class="progress-card"
  *ngIf="
    durableProcessStatusObject && durableProcessObject;
    else loadingProcessStatusData
  "
  data-test-id="componentDurableProcessStatusItem"
>
  <div class="progress-card-header">
    <div class="progress-card-title">
      {{ name || 'durableProcess' | translate | csCapitalize }}
    </div>
    <div
      class="progress-card-status"
      *ngIf="
        durableProcessStatusObject?.runtimeStatus === 'Running' &&
        processedEntries !== undefined &&
        totalEntries !== undefined &&
        totalEntries !== 0
      "
    >
      {{ processedEntries / totalEntries | percent }}
    </div>

    <div
      class="progress-card-status"
      *ngIf="durableProcessStatusObject?.runtimeStatus === 'Pending'"
    >
      {{ 'startingProcess' | translate }}...
    </div>

    <div
      class="progress-card-status hide-process-button"
      [class.hidden]="hidingProcess"
      *ngIf="durableProcessObject?.completed"
      [title]="'hideProcess' | translate"
      (click)="tryHideDurableProcess()"
      data-test-id="buttonHideDurableProcess"
    >
      <cy-icon
        [icon]="'cy_C_S_validation_global_validation_cross'"
        [size]="'S'"
      ></cy-icon>
    </div>
    <div class="progress-card-status hide-process-button" *ngIf="hidingProcess">
      <cs-progress-circular [size]="15"></cs-progress-circular>
    </div>
  </div>
  <div
    class="progress-card-content"
    *ngIf="
      durableProcessStatusObject &&
      durableProcessStatusObject?.runtimeStatus !== 'Pending'
    "
  >
    <div *ngIf="currentStage && totalStages" class="stages">
      ({{ currentStage }} / {{ totalStages }}) {{ stageName | translate }}
    </div>

    <div
      class="progress-bar"
      *ngIf="durableProcessStatusObject?.runtimeStatus === 'Running'"
    >
      <div
        class="progress"
        *ngIf="processedEntries !== undefined && totalEntries !== undefined"
        [style]="'width:' + (processedEntries / totalEntries) * 100 + '%'"
      ></div>
    </div>

    <div class="completed-container" *ngIf="durableProcessObject?.completed">
      <span *ngIf="durableProcessObject.status" class="smaller-grey-text"
        >{{
          durableProcessObject.status | lowercase | translate | csCapitalize
        }}
      </span>
      <span *ngIf="!durableProcessObject.status" class="smaller-grey-text"
        >{{ 'completed' | translate | csCapitalize }}
      </span>
      <span *ngIf="durableProcessObject.completedAt" class="smaller-grey-text">
        -
        {{ durableProcessObject.completedAt | date: 'MMM d HH:mm' }}</span
      >
      <span
        class="element-link right"
        [title]="'viewReport' | translate"
        *ngIf="
          durableProcessObject.status !== 'Cancelled' &&
          !loadingReport &&
          allowedSucessReportButton
        "
        (click)="viewReport()"
        data-test-id="buttonViewReport"
      >
        {{ 'viewReport' | translate }}
      </span>
      <span *ngIf="loadingReport" class="smaller-grey-text right">
        {{ 'loading' | translate }}
      </span>
    </div>

    <div
      class="completed-container"
      *ngIf="durableProcessObject?.completed === false"
    >
      <span
        class="element-link right alert"
        *ngIf="
          durableProcessStatusObject?.runtimeStatus === 'Running' &&
          !cancelingProcess
        "
        (click)="tryCancelProcess()"
        data-test-id="buttonCancelProcess"
      >
        {{ 'cancel' | translate }}
      </span>

      <span class="right alert" *ngIf="cancelingProcess">
        {{ 'canceling' | translate | csCapitalize }}...
      </span>
    </div>
  </div>
</div>

<ng-template #loadingProcessStatusData>
  <div class="progress-card skeleton-loader">
    <div class="progress-card-header">
      <div class="progress-card-title skeleton-loader">
        <div class="stages skeleton"></div>
      </div>
      <div class="progress-card-status skeleton"></div>
    </div>
    <div class="progress-card-content">
      <div class="progress-bar skeleton"></div>
    </div>
  </div>
</ng-template>
