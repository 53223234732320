import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalParametersService {
  private queryParameters = {
    theme: null,
    hosted: null,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    domain_hint: null,
    companyGuid: null,
    client_id: null,
  };

  constructor() {}

  public loadGlobalParametersFromQueryParameters() {
    try {
      const urlParams = new URLSearchParams(window.location.search);

      const keys = Object.keys(this.queryParameters);

      keys.forEach((key) => {
        this.queryParameters[key] = urlParams.get(key);
      });

      console.warn(JSON.stringify(this.queryParameters));
    } catch (error) {
      console.error('Error reading query parameters', error);
    }
  }

  public getParameter(key: string) {
    return this.queryParameters[key];
  }
}
