import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CloudComponent,
  CloudComponentService,
  CompanyService,
  UserService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { MenuItem } from 'primeng/api';
import { CsMenuItem } from '../models/cs-menu-item.model';
import { MenuCustomerPortal } from '../models/menu-customerPortal.model';
import * as frontendComponents from '../../assets/frontend-components.json';

@Injectable({
  providedIn: 'root',
})
export class NavigationMenuService {
  public mainMenu: CsMenuItem[] = [];
  public adminMenu: CsMenuItem[] = [];
  public userSettingsMenu: CsMenuItem[] = [];
  public customerPortalMenu: CsMenuItem[] = [];
  public isLoadingHeader = true;
  public menuSections: MenuCustomerPortal = {
    adminArea: [],
    userSettings: [],
    customerPortal: [],
  };

  public showLeftMenu = false;

  private csoPortalPagesMap = {
    licenses: 3875,
    'product-downloads': 3876,
    'user-management': 3879,
    'catalog-selection': 55409,
    //"cloud-admin": 3880
  };

  private adminMenuSectionsComponents = {};

  constructor(
    public cloudComponentService: CloudComponentService,
    public userService: UserService,
    public translateService: TranslateService,
    private companyService: CompanyService
  ) {
    this.loadAdminMenuSections();
  }

  public async loadMenus(loadMainMenu = true) {
    if (this.companyService.isCloudPlatformEnabled()) {
      if (loadMainMenu) {
        await this.loadMainMenu();
      }
      await this.loadAdminMenu();
      await this.loadUserSettingsMenu();
    } else {
      await this.loadCustomerPortalMenu();
    }

    this.loadMenuSections();
  }

  public activateMenuItem(componentId: string, urlFragment: string = null) {
    if (!componentId) {
      return;
    }

    this.disableAllSubmenusInAllLeftMenus();

    if (this.activateAdminMenuItem(this.adminMenu, componentId, urlFragment)) {
      return;
    }

    this.activateMenuItemOnMenu(
      this.userSettingsMenu,
      componentId,
      urlFragment
    );
  }

  public activateCustomerPortalMenuItem(
    componentId: string,
    urlFragment: string = null
  ) {
    this.disableAllSubmenusInAllLeftMenus();
    const elementsURL = urlFragment.split('/');
    if (elementsURL.length >= 2) {
      if (
        elementsURL[2] === 'account-settings' ||
        elementsURL[2] === 'user-profile'
      ) {
        componentId = elementsURL[2];
      }
    }
    this.activateMenuItemOnMenu(
      this.customerPortalMenu,
      componentId,
      urlFragment
    );
  }

  public navigateToAdmin() {
    if (this.menuSections?.adminArea?.length > 0) {
      if (this.menuSections.adminArea[0].command) {
        this.menuSections.adminArea[0].command();
      } else {
        location.href = '/#/' + this.menuSections.adminArea[0].id;
      }
    }
  }

  private loadAdminMenuSections() {
    /* eslint-disable  @typescript-eslint/dot-notation*/
    this.adminMenuSectionsComponents['default-settings'] = 'userSettings';

    this.adminMenuSectionsComponents['user-management'] = 'adminArea';
    this.adminMenuSectionsComponents['shop-manager'] = 'adminArea';
    this.adminMenuSectionsComponents['admin/company/shops'] = 'adminArea';
    this.adminMenuSectionsComponents['admin/company/users'] = 'adminArea';
    this.adminMenuSectionsComponents['admin/company/customsettings'] =
      'adminArea';
    this.adminMenuSectionsComponents['admin/company/locks'] = 'adminArea';
    this.adminMenuSectionsComponents['admin/company/services'] = 'adminArea';
    this.adminMenuSectionsComponents['event-broker-configurator'] = 'adminArea';
    this.adminMenuSectionsComponents['log'] = 'adminArea';
    this.adminMenuSectionsComponents['admin/company/customsettings'] =
      'adminArea';
    this.adminMenuSectionsComponents['admin/company/numberrangerules'] =
      'adminArea';
    this.adminMenuSectionsComponents['admin/company/groups'] = 'adminArea';

    if (this.companyService.isCSOFFICE()) {
      this.adminMenuSectionsComponents['account-settings'] = 'customerPortal';
      this.adminMenuSectionsComponents['catalog-selection'] = 'customerPortal';
      this.adminMenuSectionsComponents['customer-area'] = 'customerPortal';
      this.adminMenuSectionsComponents['product-downloads'] = 'customerPortal';

      this.adminMenuSectionsComponents['licenses'] = 'adminArea';
      this.adminMenuSectionsComponents['admin/licenses/user'] = 'adminArea';

      this.adminMenuSectionsComponents['user-profile'] = 'userSettings';
      this.adminMenuSectionsComponents['user-settings'] = 'userSettings';
    }
    /* eslint-enable  @typescript-eslint/dot-notation*/
  }

  private async loadMainMenu() {
    const components = this.cloudComponentService.getMainComponents();
    const tempMenu: CsMenuItem[] = [];
    for (const key of Object.keys(components)) {
      if (key) {
        const component = components[key] as CloudComponent;

        if (key === 'dashboard' && component) {
          //trick to bring dashboard to the first place, in case all sortID are the same
          if (component.sortID) {
            component.sortID--;
          } else {
            component.sortID = -1;
          }
        }

        tempMenu.push({
          id: key,
          label: await this.translateService.get(component.label).toPromise(),
          url: '#/' + component.routeID,
          sortId: component.sortID,
          icon: this.getIconForComponent(component.componentID),
          forAdmin: component.type === 'admin',
        });
      }
    }

    tempMenu.sort((a: CsMenuItem, b: CsMenuItem) => {
      return a.sortId - b.sortId;
    });

    this.mainMenu = tempMenu;
  }

  private async loadAdminMenu() {
    this.adminMenu = await this.loadAdminMenuFromComponents(
      this.cloudComponentService.getAdminComponents()
    );

    this.adminMenu.forEach(
      (menu) => (menu.section = this.adminMenuSectionsComponents[menu.id])
    );
  }

  private async loadUserSettingsMenu() {
    this.userSettingsMenu = await this.loadMenuFromComponents(
      this.cloudComponentService.getSettingsComponents()
    );

    const ffFEA47215NewCustomerPortalMenu =
      this.cloudComponentService.checkFeature('FEA-47215');

    if (!ffFEA47215NewCustomerPortalMenu) {
      const customerAreaMenuIndex = this.userSettingsMenu.findIndex(
        (m) => m.id === 'customer-area'
      );

      if (customerAreaMenuIndex > -1) {
        this.userSettingsMenu.splice(customerAreaMenuIndex, 1);
      }
    }

    this.userSettingsMenu.forEach(
      (menu) => (menu.section = this.adminMenuSectionsComponents[menu.id])
    );
  }

  private async loadCustomerPortalMenu() {
    const customerPortalMenu = await this.loadMenuFromComponents(
      await this.getCustomerPortalComponents()
    );
    this.customerPortalMenu =
      await this.filterCustmerPortalMenuByUserPermissions(customerPortalMenu);

    this.customerPortalMenu.forEach(
      (menu) => (menu.section = this.adminMenuSectionsComponents[menu.id])
    );
  }

  private async filterCustmerPortalMenuByUserPermissions(menu: CsMenuItem[]) {
    const currentUser = await this.userService.getCurrentUser();
    if (!currentUser || !currentUser.userAlias) {
      console.error(
        'current user not defined! on filterCustmerPortalMenuByUserPermissions'
      );
      menu = menu.filter((item) => item.id === 'customer-area');
      return menu;
    }

    let userInfo = null;

    try {
      userInfo = await this.userService.getCsoUserInfo();
    } catch (error) {
      console.error(error);
    }

    if (!userInfo) {
      console.error(
        'csoUserInfo not defined! on filterCustmerPortalMenuByUserPermissions'
      );
      menu = menu.filter((item) => item.id === 'customer-area');
      return menu;
    }

    const csoUserPages = (userInfo.portalPages as any[]).map(
      (page) => page.pageId as number
    );

    const filteredMenu = [];

    for (const menuItem of menu) {
      if (
        menuItem.id === 'customer-area' ||
        menuItem.id === 'product-downloads' ||
        menuItem.id === 'catalog-selection' ||
        menuItem.id === 'account-settings' ||
        menuItem.id === 'user-profile' ||
        csoUserPages.includes(this.csoPortalPagesMap[menuItem.id])
      ) {
        filteredMenu.push(menuItem);
      }
    }

    return filteredMenu;
  }

  private async getCustomerPortalComponents() {
    await this.cloudComponentService.loadCustomerPortalComponents();
    const cpComponents = this.cloudComponentService.data.allComponents.filter(
      (c) => c.type === 'customer-portal'
    );

    const customerPortalComponents = {};

    cpComponents.forEach((component) => {
      customerPortalComponents[component.componentID] = component;
    });
    return customerPortalComponents;
  }

  private async loadMenuFromComponents(
    components: Record<string, unknown>
  ): Promise<CsMenuItem[]> {
    const tempMenu: CsMenuItem[] = [];

    for (const key of Object.keys(components)) {
      const component = components[key] as CloudComponent;

      let items = null;
      if (component.submenus && component.submenus.length > 0) {
        items = [];
        component.submenus.forEach(async (submenu: MenuItem) => {
          items.push({
            id: submenu.url,
            label: await this.translateService.get(submenu.label).toPromise(),
            icon: this.getIconForComponent(submenu.url),
            title: await this.translateService.get(submenu.label).toPromise(),
            command: () => {
              this.navigateTo('/#/' + submenu.url);
            },
          });
        });
      }

      tempMenu.push({
        id: key,
        label: await this.translateService.get(component.label).toPromise(),
        url: null,
        sortId: component.sortID,
        icon: this.getIconForComponent(component.componentID),
        parentMenuComponentID: component.parentMenuComponentID,
        title: await this.translateService.get(component.label).toPromise(),
        items,
        forAdmin: component.type === 'admin',
        section: component.section,
        command: () => {
          this.navigateTo('/#/' + component.routeID);
        },
      });
    }

    tempMenu.sort((a: CsMenuItem, b: CsMenuItem) => {
      return a.sortId - b.sortId;
    });

    this.loadSubmenus(tempMenu);

    return tempMenu;
  }

  private loadSubmenus(menu: CsMenuItem[]) {
    if (!menu) {
      return;
    }

    const elementsToRemove: string[] = [];

    const keys = menu.map((m) => m.id);

    menu.forEach((m) => {
      if (keys.includes(m.parentMenuComponentID)) {
        const parent = menu.find((i) => i.id === m.parentMenuComponentID);
        if (parent) {
          if (!parent.items) {
            parent.items = [];
          }
          (parent.items as any[]).push(m); //TODO check this trick
          elementsToRemove.push(m.id);
        }
      }
    });

    elementsToRemove.forEach((e) => {
      const index = menu.findIndex((m: CsMenuItem) => {
        return m.id === e;
      });

      if (index > -1) {
        menu.splice(index, 1);
      }
    });
  }

  private async loadAdminMenuFromComponents(
    components: Record<string, unknown>
  ): Promise<CsMenuItem[]> {
    const tempMenu: CsMenuItem[] = [];

    for (const key of Object.keys(components)) {
      const component = components[key] as CloudComponent;

      tempMenu.push({
        id: key,
        label: await this.translateService.get(component.label).toPromise(),
        url: null,
        sortId: component.sortID,
        icon: this.getIconForComponent(component.componentID),
        parentMenuComponentID: component.parentMenuComponentID,
        title: await this.translateService.get(component.label).toPromise(),
        forAdmin: component.type === 'admin',
        section: component.section,
        command: () => {
          this.navigateTo('/#/' + component.routeID);
        },
      });

      let i = 1;
      if (component.submenus && component.submenus.length > 0) {
        component.submenus.forEach(async (submenu: MenuItem) => {
          tempMenu.push({
            id: submenu.url,
            label: await this.translateService.get(submenu.label).toPromise(),
            icon: this.getIconForComponent(submenu.url),
            sortId: component.sortID + i * 0.1,
            title: await this.translateService.get(submenu.label).toPromise(),
            section: 'adminArea',
            command: () => {
              this.navigateTo('/#/' + submenu.url);
            },
          });
          i++;
        });
      }
    }

    tempMenu.sort((a: CsMenuItem, b: CsMenuItem) => {
      return a.sortId - b.sortId;
    });

    return tempMenu;
  }

  private navigateTo(url: string) {
    document.location.href = url;
  }

  private activateMenuItemOnMenu(
    menu: CsMenuItem[],
    componentId: string,
    urlFragment: string = null
  ): boolean {
    if (!menu) {
      return false;
    }

    const item = menu.find((i) => i.id === componentId);

    if (item) {
      menu.forEach((i) =>
        i.id !== componentId ? (i.expanded = false) : (i.expanded = true)
      );

      if (item.items && item.items.length > 0 && urlFragment) {
        if (urlFragment.startsWith('#/') && urlFragment.length > 2) {
          urlFragment = urlFragment.substr(2);
        }

        let foundSubmenu = false;
        (item.items as MenuItem[]).forEach((submenu) => {
          if (foundSubmenu) {
            return;
          }

          if (urlFragment.startsWith((submenu as MenuItem).id)) {
            (item.items as MenuItem[]).forEach((it) =>
              it.id !== (submenu as MenuItem).id
                ? (it.styleClass = '')
                : (it.styleClass = 'active')
            );
            foundSubmenu = true;
            return;
          }
        });
      }
      return true;
    }

    return false;
  }

  private activateAdminMenuItem(
    menu: CsMenuItem[],
    componentId: string,
    urlFragment: string = null
  ): boolean {
    if (!menu || !urlFragment) {
      return false;
    }

    if (urlFragment.startsWith('#/') && urlFragment.length > 2) {
      urlFragment = urlFragment.substr(2);
    }

    let item = menu.find((i) => urlFragment.startsWith(i.id));

    if (!item) {
      item = menu.find((i) => i.id === componentId);
    }

    if (item) {
      menu.forEach((i) =>
        i.id !== item.id ? (i.expanded = false) : (i.expanded = true)
      );

      return true;
    }

    return false;
  }

  private disableAllSubmenusInAllLeftMenus() {
    this.disableAllSubmenus(this.menuSections.adminArea);
    this.disableAllSubmenus(this.menuSections.customerPortal);
    this.disableAllSubmenus(this.menuSections.userSettings);
  }

  private disableAllSubmenus(menu: CsMenuItem[] | MenuItem[]) {
    if (!menu) {
      return;
    }

    menu.forEach((item) => {
      item.styleClass = '';
      item.expanded = false;
      this.disableAllSubmenus(item.items as MenuItem[]);
    });
  }

  private loadMenuSections(): void {
    const allComponents = [
      ...this.customerPortalMenu,
      ...this.userSettingsMenu,
      ...this.adminMenu,
    ];

    allComponents.sort((a, b) => a.sortId - b.sortId);

    this.menuSections.adminArea = allComponents.filter(
      (component) => component.section === 'adminArea'
    );
    this.menuSections.userSettings = allComponents.filter(
      (component) => component.section === 'userSettings'
    );
    this.menuSections.customerPortal = allComponents.filter(
      (component) => component.section === 'customerPortal'
    );
  }

  private getIconForComponent(componentId: string): string {
    if (
      frontendComponents &&
      frontendComponents[componentId] &&
      frontendComponents[componentId].icon
    ) {
      return frontendComponents[componentId].icon;
    }
    return '';
  }
}
