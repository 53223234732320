<main>
  <section
    class="page-not-found-container"
    [class.hosted-environment]="isHostedEnvironment"
  >
    <h1 class="text-center">404</h1>
    <h2 class="text-center">{{ 'pageNotFound' | translate }}</h2>
    <p class="text-center">
      {{ 'pageNotExistOrNotPermissions' | translate }}
    </p>
    <p class="text-center">
      {{ 'ifPathCorrectContactAdministrator' | translate }}
    </p>
    <div class="buttons-container">
      <button
        pButton
        class="p-button-accented"
        data-test-id="buttonGoBack"
        (click)="goBack()"
        [label]="'goBack' | translate"
      ></button>
    </div>
  </section>
</main>
