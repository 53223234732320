import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompusoftThemesService } from '@compusoftgroup/compusoft-themes';
import { CSToastService } from '@compusoftgroup/cs-common-components';
import {
  TranslateUtilsService,
  ValidatorService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { MessageService, SelectItem } from 'primeng/api';
import { EnvironmentService } from '../../environments/environment.service';
import { UserRegistrationData } from '../models/user-registration-data.class';
import { User } from '../models/user.model';
import { ContainerLanguageService } from '../services/container-language.service';
import { UserRegistrationService } from '../services/user-registration.service';

@Component({
  selector: 'cs-self-registration',
  templateUrl: './self-registration.component.html',
  styleUrls: ['./self-registration.component.css'],
  providers: [Validators, MessageService],
})
export class SelfRegistrationComponent implements OnInit, AfterViewInit {
  public loading = true;
  public form: UntypedFormGroup;
  public currentTheme = 'default';
  public languageSelectorVisible = false;
  public selectedLanguage = '';
  public selectedLanguageName = '';
  public languagesMenu: SelectItem[] = [];
  public passwordIsValid = false;
  public focusOnPassword = false;
  public passwordsDoNotMatch = false;
  public registrationFinished = false;
  public user: User = null;
  public headerText = '';

  private hashedCustomerId = '';
  private licenseNumber = '0';
  private successMessage = 'userRegisteredCorrectly';
  private errorMessage = 'unexpectedError';
  private userCreatedWithWarningMessage =
    '8596738e-0a0c-abeb-b355-541c425e295a';
  private redirectUrl: string = null;
  private redirectionDelay = 3000;
  private signUpToken: string = null;

  private get emailControl() {
    return this.form.get('email');
  }

  private get passwordControl() {
    return this.form.get('password');
  }

  private get confirmPasswordControl() {
    return this.form.get('confirmPassword');
  }

  constructor(
    public translateService: TranslateService,
    public formBuilder: UntypedFormBuilder,
    private themesService: CompusoftThemesService,
    public languageService: ContainerLanguageService,
    public userRegistrationService: UserRegistrationService,
    private route: ActivatedRoute,
    private translateUtilsService: TranslateUtilsService,
    public toastService: CSToastService,
    public environmentService: EnvironmentService,
    public validator: ValidatorService,
    private translateParser: TranslateParser
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loadLanguagesMenu();
    await this.readQueryParameters();
    this.loadForm();
    await this.checkIfEmailIsValid();
    await this.translateTexts();
    this.loading = false;
  }

  public ngAfterViewInit(): void {
    this.currentTheme = this.themesService.getActiveThemeName();
  }

  public showLanguageSelector() {
    this.languageSelectorVisible = true;
  }

  public async registerNewUser() {
    if (!this.hashedCustomerId || this.hashedCustomerId.trim() === '') {
      console.error('hashedCustomerId is not defined!!!');
      this.toastService.error('CustomerID is not defined!');
      return;
    }

    this.loading = true;

    try {
      const userData = new UserRegistrationData(
        this.emailControl.value,
        this.user?.firstName || '',
        this.user?.lastName || '',
        this.passwordControl.value,
        this.hashedCustomerId,
        this.licenseNumber
      );

      const response = await this.userRegistrationService.registerNewUser(
        userData,
        this.signUpToken
      );

      if (response && response.message && response.message !== 'OK') {
        this.toastService.info(this.userCreatedWithWarningMessage);
        this.redirectionDelay = 8000;
      }

      this.registrationFinished = true;

      setTimeout(() => {
        location.href = this.getRedirectUrl();
      }, this.redirectionDelay);
    } catch (err) {
      if (err.humanReadableError) {
        throw err;
      } else {
        this.toastService.error(this.errorMessage);
      }
    } finally {
      this.loading = false;
    }
  }

  public checkPasswords() {
    if (this.passwordControl.valid) {
      this.confirmPasswordControl.enable();
    } else {
      this.confirmPasswordControl.disable();
      this.confirmPasswordControl.setValue('');
    }

    this.passwordsDoNotMatch =
      this.passwordControl.value !== this.confirmPasswordControl.value;
  }

  public checkPasswordLetters(password: string) {
    if (
      password.match('.*[A-Z].*') &&
      password.length > 8 &&
      password.match('.*[a-z].*') &&
      password.match('.*[0-9].*')
    ) {
      this.passwordIsValid = true;
    } else {
      this.passwordIsValid = false;
    }
    this.checkPasswords();
  }

  public async onChangeLanguage(event) {
    if (event.value) {
      await this.languageService.setLanguage(event.value);
      this.selectedLanguageName =
        this.languageService.languagesNames[
          this.selectedLanguage.toLowerCase()
        ];
    }
    setTimeout(() => {
      this.hideLanguageSelector();
    }, 300);
  }

  private loadLanguagesMenu() {
    this.languagesMenu = [];

    const languages = this.languageService.translateService.getLangs();

    for (const lang of languages) {
      this.languagesMenu.push({
        label: this.languageService.languagesNames[lang.toLowerCase()],
        value: lang,
      });
    }

    this.selectedLanguage = this.languageService.translateService.currentLang;
    this.selectedLanguageName =
      this.languageService.languagesNames[this.selectedLanguage.toLowerCase()];
  }

  private hideLanguageSelector() {
    this.languageSelectorVisible = false;
  }

  private loadForm() {
    this.form = this.formBuilder.group({
      email: [
        {
          value: this.user ? this.user.emailAddress : '',
          disabled: this.user != null,
        },
        [Validators.required, this.validator.emailValidator()],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            new RegExp('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')
          ),
        ],
      ],
      confirmPassword: ['', Validators.required],
    });
  }

  private async readQueryParameters() {
    this.hashedCustomerId =
      this.route.snapshot.queryParamMap.get('hashedCustomerID');
    this.licenseNumber = this.route.snapshot.queryParamMap.get('dongleNumber');
    this.redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
    this.signUpToken = this.route.snapshot.queryParamMap.get('signupToken');
    const testEmail = this.route.snapshot.queryParamMap.get('email');

    if (!this.hashedCustomerId || this.hashedCustomerId.trim() === '') {
      console.error('hashedCustomerId is not defined!!!');
      this.toastService.error('CustomerID is not defined!');
    }

    if (!this.redirectUrl || this.redirectUrl.trim() === '') {
      this.redirectUrl = this.environmentService.uiBaseUrl;
    }

    if (!this.redirectUrl.includes('?')) {
      this.redirectUrl += '?';
    }

    if (this.signUpToken) {
      this.user = await this.userRegistrationService.getUser(this.signUpToken);
    } else if (testEmail) {
      this.user = {
        emailAddress: testEmail,
        firstName: 'TestName',
        lastName: 'TestLastName',
      } as User;
    }
  }

  private async translateTexts() {
    this.successMessage = await this.translateUtilsService.translate(
      this.successMessage
    );
    this.errorMessage = await this.translateUtilsService.translate(
      this.errorMessage
    );
    this.userCreatedWithWarningMessage =
      await this.translateUtilsService.translate(
        this.userCreatedWithWarningMessage
      );

    this.headerText = this.translateParser.interpolate(
      await this.translateUtilsService.translate('setYourPasswordForEmail'),
      { email: this.user ? this.user.emailAddress : '' }
    );
  }

  private getRedirectUrl() {
    return this.redirectUrl + '&login_hint=' + this.emailControl.value;
  }

  private async checkIfEmailIsValid() {
    if (this.emailControl.invalid) {
      this.toastService.error(
        await this.translateUtilsService.translate('invalidEmail')
      );
    }
  }
}
