import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompusoftThemesModule } from '@compusoftgroup/compusoft-themes';
import {
  CSLoaderModule,
  CSMultiLineMenuModule,
  CSPanelMenuModule,
  CsProgressCircularModule,
  CyInputSuggestionsModule,
  CyToastTemplate,
  PasswordDirective,
} from '@compusoftgroup/cs-common-components';
import { CsCloudAuthenticationModule } from '@compusoftgroup/ngx-compusoft-cloud-authentication';
import {
  ComponentConfigurationService,
  CompusoftCloudCommonLibraryModule,
  CompusoftCloudTranslateService,
  GlobalErrorHandler,
  PasswordRecoveryStepsModule,
  ShopSelectorModule,
  SYSTEM_LANGUAGE_STANDARD_TEXT_CATEGORY_GUID,
  ShowFilterSearchPipe,
  CyShopSelectorModule,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import {
  CompusoftCloudDocumentsLibraryModule,
  CSPreviewModule,
  SafePipe,
} from '@compusoftgroup/ngx-compusoft-cloud-documents-library';
import { CompusoftCloudZendeskUtilitiesModule } from '@compusoftgroup/ngx-compusoft-cloud-zendesk-utilities';
import { CompusoftAngularUtilsModule } from 'compusoft-angular-utils';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ToastModule } from 'primeng/toast';
import { EnvironmentService } from '../environments/environment.service';
import { AboutFlexComponent } from './about-flex/about-flex.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthRedirectorComponent } from './auth-redirector/auth-redirector.component';
import { BackofficeRedirectorComponent } from './backoffice-redirector/backoffice-redirector.component';
import { CompanyChooserComponent } from './company-chooser/company-chooser.component';
import { ContainerComponent } from './container/container.component';
import { CookiesDetailedMessageComponent } from './cookies-message/cookies-detailed-message/cookies-detailed-message.component';
import { CookiesMessageComponent } from './cookies-message/cookies-message.component';
import { CustomerAreaComponent } from './customer-area/customer-area.component';
import { DocumentsMultiUploadComponent } from './documents-multi-upload/documents-multi-upload.component';
import { DurableProcessListComponent } from './durable-process/components/durable-process-list/durable-process-list.component';
import { CsDurableProcessesLoaderComponent } from './durable-process/components/durable-process-menu/cs-durable-processes-loader/cs-durable-processes-loader.component';
import { DurableProcessMenuComponent } from './durable-process/components/durable-process-menu/durable-process-menu.component';
import { DurableProcessStatusItemComponent } from './durable-process/components/durable-process-status-item/durable-process-status-item.component';
import { DurableProcessStatusReportErrorComponent } from './durable-process/components/durable-process-status-report-error/durable-process-status-report-error.component';
import { DurableProcessStatusReportImportContactsListComponent } from './durable-process/components/durable-process-status-report-import/durable-process-status-report-import-contacts-list/durable-process-status-report-import-contacts-list.component';
import { DurableProcessStatusReportImportComponent } from './durable-process/components/durable-process-status-report-import/durable-process-status-report-import.component';
import { FooterComponent } from './footer/footer.component';
import { IdpLoginComponent } from './idp-login/idp-login.component';
import { InAppSupportComponent } from './in-app-support/in-app-support.component';
import { LanguageChooserComponent } from './language-chooser/language-chooser.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { TypeComponentFromURLPipe } from './pipes/type-component-from-url.pipe';
import { PublicContainerComponent } from './public-container/public-container.component';
import { SelfRegistrationComponent } from './self-registration/self-registration.component';
import { ContainerLanguageService } from './services/container-language.service';
import { TermsConditionsRedirectorComponent } from './terms-conditions-redirector/terms-conditions-redirector.component';
import { OfflineDialogComponent } from './offline-dialog/offline-dialog.component';
import { ServiceKeyComponent } from './service-key/service-key.component';
import {
  CyIconComponent,
  CyIconProvider,
  cy_C_L_guide_communicate_guide_help,
  cy_C_L_core_commands_tools_core_commands_settings,
  cy_C_L_report_reporting_report_report,
  cy_C_L_archive_management_archive_document_box,
  cy_C_L_folder_management_folder_folder_design,
  cy_C_L_client_card_people_client_card_client,
  cy_C_L_template_reporting_template_dashboard_chart,
  cy_G_S_guide_communicate_guide_training,
  cy_C_S_global_2_technology_global_global,
  cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy,
  cy_C_S_menu_controls_menu_menu_file,
  cy_C_S_validation_global_validation_cross,
  cy_C_S_arrows_2_arrows_arrows_upload,
  cy_C_S_validation_global_validation_checkmark,
  cy_C_S_validation_global_validation_error,
  cy_C_S_sync_and_refresh_arrows_sync_and_refresh_control_sync,
  cy_C_S_validation_global_validation_warning,
  cy_C_L_arrows_2_arrows_arrows_back,
} from '@cyncly/ngx-cy-icons';
import { BackArrowComponent } from './back-arrow/back-arrow.component';
import { SpeedtestComponent } from './speedtest/speedtest.component';

const initApp = (http: HttpClient) => {
  return () => {
    return http
      .get<any>((window as any).standardTextsApiBaseUrl, {
        params: {
          category: SYSTEM_LANGUAGE_STANDARD_TEXT_CATEGORY_GUID,
          language: '0',
        },
      })
      .toPromise()
      .then((texts) => {
        (window as any).languages = texts.texts.map((l) => ({
          key: l.key,
          guid: l.textGuid,
        }));
      });
  };
};

const icons = {
  cy_C_L_core_commands_tools_core_commands_settings,
  cy_C_L_guide_communicate_guide_help,
  cy_C_L_report_reporting_report_report,
  cy_C_L_archive_management_archive_document_box,
  cy_C_L_folder_management_folder_folder_design,
  cy_C_L_client_card_people_client_card_client,
  cy_C_L_template_reporting_template_dashboard_chart,
  cy_G_S_guide_communicate_guide_training,
  cy_C_S_global_2_technology_global_global,
  cy_C_S_cut_copy_paste_tools_cut_copy_paste_copy,
  cy_C_S_menu_controls_menu_menu_file,
  cy_C_S_validation_global_validation_cross,
  cy_C_S_arrows_2_arrows_arrows_upload,
  cy_C_S_validation_global_validation_checkmark,
  cy_C_S_validation_global_validation_error,
  cy_C_S_sync_and_refresh_arrows_sync_and_refresh_control_sync,
  cy_C_S_validation_global_validation_warning,
  cy_C_L_arrows_2_arrows_arrows_back,
};

@NgModule({
  declarations: [
    AppComponent,
    ContainerComponent,
    FooterComponent,
    CompanyChooserComponent,
    LanguageChooserComponent,
    PublicContainerComponent,
    TypeComponentFromURLPipe,
    CustomerAreaComponent,
    SelfRegistrationComponent,
    PasswordRecoveryComponent,
    BackofficeRedirectorComponent,
    CookiesMessageComponent,
    CookiesDetailedMessageComponent,
    AboutFlexComponent,
    DocumentsMultiUploadComponent,
    TermsConditionsRedirectorComponent,
    InAppSupportComponent,
    DurableProcessStatusItemComponent,
    DurableProcessListComponent,
    DurableProcessMenuComponent,
    DurableProcessStatusReportImportComponent,
    DurableProcessStatusReportErrorComponent,
    DurableProcessStatusReportImportContactsListComponent,
    CsDurableProcessesLoaderComponent,
    IdpLoginComponent,
    PageNotFoundComponent,
    AuthRedirectorComponent,
    ServiceKeyComponent,
    BackArrowComponent,
    SpeedtestComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    SidebarModule,
    MenuModule,
    SlideMenuModule,
    PanelMenuModule,
    DialogModule,
    ConfirmDialogModule,
    DropdownModule,
    ToastModule,
    CsProgressCircularModule,
    CSPanelMenuModule,
    HttpClientModule,
    CompusoftCloudTranslateService.getTranslateModuleForRoot(),
    CompusoftCloudZendeskUtilitiesModule,
    AppRoutingModule,
    AutoCompleteModule,
    CompusoftCloudCommonLibraryModule,
    CsCloudAuthenticationModule,
    ButtonModule,
    CSMultiLineMenuModule,
    InputTextModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
    PasswordRecoveryStepsModule,
    ShopSelectorModule,
    CyShopSelectorModule,
    CSPreviewModule,
    CompusoftCloudDocumentsLibraryModule,
    AccordionModule,
    TableModule,
    CompusoftThemesModule,
    DynamicDialogModule,
    CompusoftAngularUtilsModule,
    PaginatorModule,
    CyIconComponent,
    CyInputSuggestionsModule,
    ShowFilterSearchPipe,
    OfflineDialogComponent,
    CSLoaderModule,
    CyToastTemplate,
    PasswordDirective,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [HttpClient],
      multi: true,
    },
    {
      provide: CyIconProvider,
      useFactory: () => new CyIconProvider(icons),
    },
    MessageService,
    MessagesModule,
    ComponentConfigurationService,
    ContainerLanguageService,
    ConfirmationService,
    DialogService,
    SafePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    public componentConfigurationService: ComponentConfigurationService,
    public environmentService: EnvironmentService
  ) {
    componentConfigurationService.configure(
      environmentService.componentID,
      environmentService.textLibrary
    );
    (window as any).textsApiBaseUrl = environmentService.textsApiBaseUrl;
    (window as any).standardTextLibrary =
      environmentService.standardTextLibrary;
    (window as any).standardTextsApiBaseUrl =
      environmentService.standardTextsApiBaseUrl;
  }
}
