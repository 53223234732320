import { NavigationMenuService } from './services/navigation-menu.service';
import { GlobalParametersService } from './services/global-parameters.service';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { CompusoftThemesService } from '@compusoftgroup/compusoft-themes';
import { ContainerLanguageService } from './services/container-language.service';
import { EnvironmentService } from 'src/environments/environment.service';
import {
  LocalUserPreferencesService,
  CloudComponentService,
  ErrorService,
  UserService,
  ExternalService,
  CompanyService,
  StateService,
  InfolyticsUxService,
} from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import {
  CsCloudAuthenticationService,
  CsCloudSilentRefreshService,
} from '@compusoftgroup/ngx-compusoft-cloud-authentication';
import { CSToastService } from '@compusoftgroup/cs-common-components';
import { Router } from '@angular/router';
import { ShopService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';
import {
  DocumentService,
  HttpService,
  DocumentApiEndpointsService,
  DocumentsMultipleUploadService,
} from '@compusoftgroup/ngx-compusoft-cloud-documents-library';
import { HttpParams } from '@angular/common/http';
import * as componentVersions from '../components-versions.json';
import packageJson from '../../package.json';
import {
  DATA_INSIGHT_FLEX_PRODUCT_ID,
  DATA_INSIGHT_FLEX_PRODUCT_NAME,
} from './constants/data-insight.constants';
import { ConnectionStatusService } from './services/connection-status.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public loading = true;
  public noInternetConnectionFF = false;

  constructor(
    public containerLanguageService: ContainerLanguageService,
    public externalService: ExternalService,
    public themesService: CompusoftThemesService,
    public localUserPreferencesService: LocalUserPreferencesService,
    public cloudComponentService: CloudComponentService,
    private authenticationService: CsCloudAuthenticationService,
    private csToastService: CSToastService,
    public shopService: ShopService,
    public userService: UserService,
    public errorService: ErrorService,
    public router: Router,
    public globalParametersService: GlobalParametersService,
    public environmentService: EnvironmentService,
    public documentApiEndpointsService: DocumentApiEndpointsService,
    public documentService: DocumentService,
    public cd: ChangeDetectorRef,
    public httpService: HttpService,
    public companyService: CompanyService,
    public navigationMenuService: NavigationMenuService,
    public stateService: StateService,
    private infolyticsUxService: InfolyticsUxService,
    private silentRefreshService: CsCloudSilentRefreshService,
    public connectionStatusService: ConnectionStatusService
  ) {
    this.userService.setEnvironment(this.environmentService.environmentURLS);
    this.shopService.shopsApiBaseUrl = environmentService.shopsApiBaseUrl;
    this.companyService.shopsApiBaseUrl = environmentService.shopsApiBaseUrl;
    this.infolyticsUxService.productId = DATA_INSIGHT_FLEX_PRODUCT_ID;
    this.infolyticsUxService.productName = DATA_INSIGHT_FLEX_PRODUCT_NAME;
    this.infolyticsUxService.flexUIBFFApiBaseUrl =
      this.environmentService.flexUIBFFApiBaseUrl;
    this.registerGlobalServices();
    this.cloudComponentService.setEnvironment(this.environmentService);
    cloudComponentService.setComponentsBaseUrl(
      environmentService.componentBaseUrl
    );
    console.warn(`Version: ${packageJson.version}`);
    this.authenticationService.debug = true;

    this.authenticationService.setAuthenticationParameters({
      uiBaseUrl: environmentService.uiBaseUrl,
      authenticationBaseUrl: environmentService.authenticationBaseUrl,
      authenticationClientID: environmentService.authenticationClientID,
      authenticationResponseType: environmentService.authenticationResponseType,
      authenticationScope: environmentService.authenticationScope,
      useIDPLogin: true,
    });
    this.authenticationService.setExternalService(this.externalService);
    this.silentRefreshService.initSilentRefresh();
    this.removeServiceWorkers();
    this.documentApiEndpointsService.config(
      this.environmentService.documentsApiBaseUrl,
      this.environmentService.contactsApiBaseUrl,
      this.environmentService.projectsApiBaseUrl
    );
    this.httpService.setDefaultHttpParams(
      new HttpParams()
        .set('companyGuid', this.companyService.getCurrentCompanyGuid())
        .set('shopGuid', this.shopService.getCurrentShopGuid())
    );
    (window as any).documentMultipleUploadService =
      new DocumentsMultipleUploadService(this.documentService, this.cd);
  }

  @HostListener('window:keydown.Alt.k', ['$event'])
  public onKeyDownAltK(e): void {
    if (this.environmentService.name !== 'prod') {
      e.preventDefault();
      this.containerLanguageService.toggleShowTranslationKeys();
    }
  }

  async ngOnInit() {
    this.globalParametersService.loadGlobalParametersFromQueryParameters();
    this.setAuthenticationParameters();
    await this.containerLanguageService.initLanguages();
    this.addGlobalEventListeners();
    this.errorService.setTranslateService(
      this.containerLanguageService.translateService
    );
    await this.loadLoginFeatureFlags();
    await this.setTheme();
    this.navigationMenuService.isLoadingHeader = false;
    this.loadComponentVersions();
    this.loading = false;
  }

  private setAuthenticationParameters() {
    try {
      const authParams =
        this.authenticationService.getAuthenticationParameters();
      let newAuthParametersDetected = false;

      if (
        this.globalParametersService.getParameter('domain_hint') &&
        authParams
      ) {
        authParams.authenticationDomainHint =
          this.globalParametersService.getParameter('domain_hint');

        newAuthParametersDetected = true;
        console.warn(
          'domain_hint detected from URL: ' +
            this.globalParametersService.getParameter('domain_hint')
        );
      }

      if (
        this.globalParametersService.getParameter('client_id') &&
        authParams
      ) {
        authParams.authenticationClientID =
          this.globalParametersService.getParameter('client_id');
        if (localStorage) {
          localStorage.setItem('client_id', authParams.authenticationClientID);
        }
        newAuthParametersDetected = true;
        console.warn(
          'client_id detected from URL: ' + authParams.authenticationClientID
        );
      } else if (localStorage && localStorage.getItem('client_id')) {
        authParams.authenticationClientID = localStorage.getItem('client_id');
        newAuthParametersDetected = true;
        console.warn(
          'client_id detected from localStorage: ' +
            authParams.authenticationClientID
        );
      }

      if (newAuthParametersDetected) {
        this.authenticationService.setAuthenticationParameters(authParams);
      }
    } catch (error) {
      console.error('Error in SetDomainHint', error);
    }
  }

  private async setTheme() {
    try {
      const parameterTheme = this.globalParametersService.getParameter('theme');
      if (parameterTheme) {
        this.themesService.setTheme(parameterTheme);
      } else {
        const theme = await this.externalService.getTheme();
        this.themesService.setTheme(theme);
      }
    } catch (error) {
      console.error('Error setting theme', error);
      this.themesService.setTheme('default');
    }
  }

  private addGlobalEventListeners() {
    try {
      window.addEventListener('compusoft-cloud:unauthorized', () => {
        console.warn('compusoft-cloud:unauthorized event was triggered');
        this.externalService.unauthorizedAccessSigningUserOut();
        this.authenticationService.saveRedirectUrl(this.router.url);

        this.authenticationService.redirectToLogin(this.router.url);
      });
      window.addEventListener(
        'compusoft-cloud:error',
        async (e: CustomEvent) => {
          console.error(e);
          if (e.detail && e.detail.message) {
            this.csToastService.error(
              await this.translate('error'),
              e.detail.message
            );
          } else {
            this.csToastService.error(
              await this.translate('error'),
              'Unknown error occured'
            );
          }
        }
      );
      window.addEventListener(
        'compusoft-cloud:sucess',
        async (e: CustomEvent) => {
          if (e.detail && e.detail.message) {
            this.csToastService.success(
              await this.translate('Success'),
              e.detail.message
            );
          } else {
            this.csToastService.success(await this.translate('Success'), '');
          }
        }
      );
      window.addEventListener('compusoft-cloud:alert', (e: CustomEvent) => {
        console.error(e);
        if (e.detail && e.detail.message) {
          this.csToastService.error('', e.detail.message);
        } else {
          this.csToastService.success('');
        }
      });
    } catch (error) {
      console.error('Error in addGlobalEventListeners', error);
    }
  }

  private registerGlobalServices() {
    (window as any).shopService = this.shopService; //TODO, move these global services to the common library
    (window as any).containerLanguageService = this.containerLanguageService;
    (window as any).infolyticsUxService = this.infolyticsUxService;
  }

  private async translate(value: string) {
    return await this.containerLanguageService.translateService
      .get(value)
      .toPromise();
  }

  private removeServiceWorkers() {
    if (!navigator || !navigator.serviceWorker) {
      return;
    }

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }

  private loadComponentVersions(): void {
    try {
      if (componentVersions && Object.keys(componentVersions).length > 0) {
        const versions = (componentVersions as any).default;
        if (versions && Object.keys(versions).length > 0) {
          this.stateService.setItem(
            'current_components_versions',
            JSON.stringify(versions)
          );
        } else {
          console.error(
            'Default property not found in component-versions file.'
          );
        }
      } else {
        console.error('component-versions file not found.');
      }
    } catch (error) {
      console.error(
        'An error ocurred while setting current_components_versions: ',
        error
      );
    }
  }

  private async loadLoginFeatureFlags() {
    const featureFlags = await this.cloudComponentService.fetchFeatures(
      null,
      null,
      true
    );
    if (!featureFlags) {
      return;
    }
    const ff83100useCodeAuthFlow = featureFlags.find(
      (ff) => ff.identifier === 'FEA-83100'
    );

    if (ff83100useCodeAuthFlow?.enabled) {
      //use Code Flow
      const authParams =
        this.authenticationService.getAuthenticationParameters();
      if (!authParams) {
        return;
      }
      authParams.authenticationResponseType = 'code';

      if (!authParams.authenticationScope?.includes('offline_access')) {
        authParams.authenticationScope += ' offline_access';
      }
      this.authenticationService.setAuthenticationParameters(authParams);
    }

    const ffUs92189 = featureFlags.find((ff) => ff.identifier === 'US-92189');

    if (ffUs92189?.enabled && localStorage) {
      //this FF will be read in the authentication service to send the refresh token to the gateway
      localStorage.setItem('feature_flag_US-92189', '1');
    } else if (localStorage) {
      localStorage.removeItem('feature_flag_US-92189');
    }

    const ff88402 = featureFlags.find((ff) => ff.identifier === 'FEA-88402');
    if (ff88402?.enabled) {
      this.noInternetConnectionFF = true;
    }
  }
}
