import { ConfirmationService } from 'primeng/api';
import { DurableProcessesService } from './../../services/durable-processes.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateUtilsService } from '@compusoftgroup/ngx-compusoft-cloud-common-library';

@Component({
  selector: 'cs-durable-process-list',
  templateUrl: './durable-process-list.component.html',
  styleUrls: ['./durable-process-list.component.css'],
})
export class DurableProcessListComponent implements OnInit, OnDestroy {
  public durableProcessListData = [];

  constructor(
    private durableProcessesService: DurableProcessesService,
    private confirmationService: ConfirmationService,
    private translateUtilsService: TranslateUtilsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.durableProcessesService.initDurableProcessesMonitor();
    this.durableProcessesService.processes.subscribe((data) => {
      if (!data) {
        return;
      }
      this.durableProcessListData = data;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.durableProcessesService.stopPooling();
  }

  public async tryClearAllCompletedProcesses() {
    this.confirmationService.confirm({
      header: await this.translateUtilsService.translate('attention'),
      message: await this.translateUtilsService.translate(
        'doYouReallyWantToHideAllCompletedProcesses'
      ),
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDialogCancelProcess',
      accept: async () => {
        this.clearAllCompletedProcesses();
      },
      reject: () => {},
    });
  }

  private clearAllCompletedProcesses() {
    this.durableProcessesService.clearAllCompletedProcesses();
  }

  public get processes() {
    return this.durableProcessesService.processes;
  }

  public get completedProcesses() {
    return this.durableProcessesService.processes.value.filter(
      (p) => p.completed
    );
  }
}
