import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionStatusService {
  public internetStatusSubject = new BehaviorSubject<boolean>(true);

  constructor() {
    fromEvent(window, 'online').subscribe(() => {
      this.updateInternetStatus(true);
    });

    fromEvent(window, 'offline').subscribe(() => {
      this.updateInternetStatus(false);
    });
  }

  private updateInternetStatus(isOnline: boolean): void {
    this.internetStatusSubject.next(isOnline);
  }
}
